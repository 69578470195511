import React, { useState, useEffect, useRef } from 'react';
import { 
    Box, Typography, Paper, TextField, Button, 
    List, ListItem, ListItemText, CircularProgress,
    IconButton, Menu, MenuItem
} from '@mui/material';
import { getToken } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { keyframes } from '@mui/system';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

function ChatHistoryNavbar({ chatHistory, onThreadSelect, currentThreadId }) {
    const nonEmptyThreads = chatHistory.filter(thread => thread.messageCount > 0);

    return (
        <Box sx={{ 
            maxHeight: '200px',
            overflowY: 'auto', 
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            borderRadius: '8px',
            p: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        }}>
            {nonEmptyThreads.length > 0 ? (
                <List sx={{ 
                    width: '100%', 
                    bgcolor: 'transparent',
                    py: 0,
                    px: 0,
                    '& .MuiListItem-root': {
                        borderRadius: '8px',
                        mb: 1,
                        transition: 'background-color 0.2s ease',
                    }
                }}>
                    {nonEmptyThreads.map((thread) => (
                        <ListItem 
                            key={thread.threadId}
                            button 
                            onClick={() => onThreadSelect(thread.threadId)}
                            selected={thread.threadId === currentThreadId}
                            sx={{
                                '&.Mui-selected': {
                                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                    borderLeft: '3px solid',
                                    borderColor: 'primary.main',
                                    '&:hover': {
                                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                    }
                                },
                                width: '100%',
                                py: 1.5,
                                pl: 2,
                                pr: 2,
                                backgroundColor: 'background.paper',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                }
                            }}
                        >
                            <ListItemText 
                                primary={
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ fontWeight: thread.threadId === currentThreadId ? 600 : 400 }}>
                                            {new Date(thread.createdAt).toLocaleDateString(undefined, { 
                                                month: 'short', 
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </Typography>
                                        <Typography variant="caption" sx={{ 
                                            color: 'text.secondary', 
                                            bgcolor: 'action.hover',
                                            px: 1,
                                            py: 0.2,
                                            borderRadius: '12px',
                                            fontSize: '0.65rem'
                                        }}>
                                            {thread.messageCount}
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <Typography 
                                        variant="body2" 
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            color: 'text.secondary',
                                            mt: 0.5,
                                            fontSize: '0.8rem'
                                        }}
                                    >
                                        {thread.lastMessage}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    height: '100%',
                    p: 2,
                    textAlign: 'center'
                }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        No chat history yet
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Start a new conversation to create chat history
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

function AIChat({ 
    courseId, 
    courseName, 
    currentVideo, 
    videos,
    isOracleCourse,
    courseDescription,
    courseType,
    courseThumbnail,
    courseDocuments,
    isMobile,
    isOpen,
    onClose
}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [lmsThreadId, setLMSThreadId] = useState(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [showChatHistory, setShowChatHistory] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const messagesEndRef = useRef(null);
    const token = getToken();
    const [stopRecording, setStopRecording] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [audioContext, setAudioContext] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [isTTSEnabled, setIsTTSEnabled] = useState(false);
    const [audioQueue, setAudioQueue] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio());
    const audioChunks = useRef({});
    const [ttsVoice, setTTSVoice] = useState('alloy');
    const [voiceMenuAnchor, setVoiceMenuAnchor] = useState(null);
    const [isAudioProcessing, setIsAudioProcessing] = useState(false);
    
    // OpenAI TTS voices
    const ttsVoices = [
        { id: 'alloy', name: 'Alloy' },
        { id: 'echo', name: 'Echo' },
        { id: 'fable', name: 'Fable' },
        { id: 'onyx', name: 'Onyx' },
        { id: 'nova', name: 'Nova' },
        { id: 'shimmer', name: 'Shimmer' },
        { id: 'coral', name: 'Coral' },
        { id: 'sage', name: 'Sage' },
        { id: 'ash', name: 'Ash' },
        { id: 'ballad', name: 'Ballad' }
    ];
    
    // Handle voice menu
    const handleVoiceMenuOpen = (event) => {
        setVoiceMenuAnchor(event.currentTarget);
    };
    
    const handleVoiceMenuClose = () => {
        setVoiceMenuAnchor(null);
    };
    
    const handleVoiceSelect = (voiceId) => {
        setTTSVoice(voiceId);
        handleVoiceMenuClose();
    };

    useEffect(() => {
        if (!courseId) return;
        loadThreadAndMessages();
        fetchChatHistory();
    }, [courseId]);

    const loadThreadAndMessages = async () => {
        try {
            const threadId = await createThread(courseName);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchMessages(threadId);
            }
        } catch (error) {
            console.error('Error loading thread and messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const createThread = async (course_name) => {
        try {
            const response = await fetch(`${BASE_URL}/api/createLMSthread`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ course_name })
            });
            
            if (!response.ok) throw new Error('Failed to create thread');
            const data = await response.json();
            return data.threadId;
        } catch (error) {
            console.error('Error creating thread:', error);
            return null;
        }
    };

    const fetchMessages = async (threadId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSmessages/${threadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            
            if (!response.ok) throw new Error('Failed to fetch messages');
            const data = await response.json();
            setMessages(data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const fetchChatHistory = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSChatHistory?course_name=${courseName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) throw new Error('Failed to fetch chat history');
            const data = await response.json();
            setChatHistory(data.chatHistory);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const handleThreadSelect = async (threadId) => {
        // Stop any current audio playback
        stopAudioPlayback(true);
        
        // Reset audio processing state
        setIsAudioProcessing(false);
        
        setLMSThreadId(threadId);
        setIsLoadingMessages(true);
        try {
            await fetchMessages(threadId);
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const playNextAudio = () => {
        if (audioQueue.length === 0) return;
        
        setIsPlaying(true);
        const currentAudioItem = audioQueue[0];
        const currentAudio = typeof currentAudioItem === 'string' 
            ? currentAudioItem 
            : currentAudioItem.url;
        
        console.log(`Playing audio segment ${audioQueue[0].index + 1}/${audioQueue[0].total}`);
        
        audioRef.current.src = currentAudio;
        audioRef.current.play().catch(e => {
            console.error('Error playing audio:', e);
            setIsPlaying(false);
            setAudioQueue(prev => prev.slice(1));
        });
    };

    const stopAudioPlayback = (shouldClearQueue = true) => {
        console.log('Stopping audio playback');
        
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.src = '';
        }
        
        if (shouldClearQueue) {
            audioQueue.forEach(item => {
                const url = typeof item === 'string' ? item : item?.url;
                if (url) URL.revokeObjectURL(url);
            });
            
            setAudioQueue([]);
        }
        
        setIsPlaying(false);
        
        Object.keys(audioChunks.current).forEach(key => {
            delete audioChunks.current[key];
        });
    };

    const playAudio = (base64Audio, metadata = {}) => {
        if (isPlaying) {
            stopAudioPlayback(false);
        }
        
        // Clear audio processing state when audio starts playing
        setIsAudioProcessing(false);
        
        // Remove audio processing indicator from all messages
        setMessages(prev => prev.map(msg => 
            msg.isAudioProcessing ? { ...msg, isAudioProcessing: false } : msg
        ));
        
        if (metadata.isChunk) {
            handleStreamingAudioChunk(base64Audio, metadata);
        } else {
            const audioBlob = new Blob(
                [Uint8Array.from(atob(base64Audio), c => c.charCodeAt(0))], 
                { type: 'audio/mp3' }
            );
            const audioUrl = URL.createObjectURL(audioBlob);
            
            setAudioQueue(prev => [...prev, { 
                url: audioUrl, 
                ...metadata 
            }]);
        }
    };

    useEffect(() => {
        if (audioQueue.length > 0 && !isPlaying) {
            playNextAudio();
        }
    }, [audioQueue, isPlaying]);

    useEffect(() => {
        const handleEnded = () => {
            if (audioQueue.length > 0) {
                const currentItem = audioQueue[0];
                const url = typeof currentItem === 'string' ? currentItem : currentItem?.url;
                if (url) {
                    URL.revokeObjectURL(url);
                }
                
                setAudioQueue(prev => prev.slice(1));
                setIsPlaying(false);
            } else {
                setIsPlaying(false);
            }
        };

        audioRef.current.addEventListener('ended', handleEnded);
        
        return () => {
            audioRef.current.removeEventListener('ended', handleEnded);
        };
    }, [audioQueue]);

    const toggleTTS = () => {
        const newTTSState = !isTTSEnabled;
        setIsTTSEnabled(newTTSState);
        
        // If turning off TTS, stop any current playback
        if (!newTTSState) {
            stopAudioPlayback(true);
        }
    };

    const sendMessage = async () => {
        if (!inputMessage.trim() || !lmsThreadId) return;

        // Stop any current audio playback
        stopAudioPlayback(true);

        const newMessage = { role: 'user', content: inputMessage };
        setMessages(prev => [...prev, newMessage]);
        setInputMessage('');
        setIsLoading(true);

        const placeholderId = `placeholder-${Date.now()}`;
        let isStreaming = false;
        
        try {
            setMessages(prev => [...prev, { 
                role: 'assistant', 
                content: '', 
                id: placeholderId,
                isLoading: true
            }]);

            const response = await fetch(`${BASE_URL}/api/sendLMSchatmessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    threadId: lmsThreadId,
                    message: inputMessage,
                    course_name: courseName,
                    course_id: courseId,
                    video_id: currentVideo?.id,
                    enableTTS: isTTSEnabled,
                    ttsVoice: ttsVoice
                }),
            });

            const reader = response.body.getReader();
            let accumulatedContent = '';
            let incompleteChunk = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = incompleteChunk + new TextDecoder().decode(value);
                incompleteChunk = '';
                
                const eventDelimiter = '\n\n';
                let chunkParts = chunk.split(eventDelimiter);
                
                if (!chunk.endsWith(eventDelimiter) && chunkParts.length > 0) {
                    incompleteChunk = chunkParts.pop() || '';
                }

                for (const part of chunkParts) {
                    if (part.trim() === '') continue;
                    
                    const dataMatch = part.match(/^data: (.+)$/m);
                    if (!dataMatch) continue;
                    
                    const dataString = dataMatch[1];
                    
                    try {
                        const data = JSON.parse(dataString);
                        
                        switch (data.type) {
                            case 'chunk':
                                if (!isStreaming) {
                                    isStreaming = true;
                                    setIsLoading(false);
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, content: '', isLoading: false }
                                            : msg
                                    ));
                                }
                                accumulatedContent += data.content;
                                setMessages(prev => prev.map(msg => 
                                    msg.id === placeholderId 
                                        ? { ...msg, content: accumulatedContent }
                                        : msg
                                ));
                                break;

                            case 'audio_processing':
                                setIsAudioProcessing(data.processing);
                                if (data.processing) {
                                    // Update the assistant message to show audio processing
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, isAudioProcessing: true }
                                            : msg
                                    ));
                                } else {
                                    // Audio processing is complete
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, isAudioProcessing: false }
                                            : msg
                                    ));
                                }
                                break;

                            case 'audio':
                                if (isTTSEnabled && data.audio) {
                                    playAudio(data.audio, {
                                        index: data.index !== undefined ? data.index : 0,
                                        total: data.total || 1
                                    });
                                }
                                break;

                            case 'error':
                                console.error('Stream error:', data.error);
                                throw new Error(data.error);

                            case 'end':
                                const finalContent = data.content || accumulatedContent;
                                setMessages(prev => prev.map(msg => 
                                    msg.id === placeholderId 
                                        ? { role: 'assistant', content: finalContent }
                                        : msg
                                ));
                                messagesEndRef.current?.scrollIntoView({ block: 'end' });
                                await fetchChatHistory();
                                return;

                            case 'tool_start':
                            case 'tool_input':
                            case 'tool_output':
                                break;
                        }
                    } catch (e) {
                        console.error('Error parsing SSE data:', e, '\nData causing error:\n', dataString.substring(0, 200) + '...');
                    }
                }
            }

            await fetchChatHistory();
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => prev.filter(msg => msg.id !== placeholderId));
            alert(`An error occurred: ${error.message}. Please try again.`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage?.role === 'user') {
            messagesEndRef.current?.scrollIntoView({ block: 'end' });
        }
    }, [messages]);

    const formatBoldText = (text) => {
        const parts = text.split(/(\*\*.*?\*\*)/g);
        return parts.map((part, i) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return (
                    <Typography
                        key={i}
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {part.slice(2, -2)}
                    </Typography>
                );
            }
            return <span key={i}>{part}</span>;
        });
    };

    const handleTimestampClick = (timestamp, videoId) => {
        const video = videos?.find(v => v.id === parseInt(videoId));
        if (video) {
            const parts = timestamp.split(':');
            let seconds = 0;
            
            if (parts.length === 3) {
                seconds = parseInt(parts[0]) * 3600 + 
                         parseInt(parts[1]) * 60 + 
                         parseFloat(parts[2]);
            } else if (parts.length === 2) {
                seconds = parseInt(parts[0]) * 60 + 
                         parseFloat(parts[1]);
            } else {
                seconds = parseFloat(parts[0]);
            }
            
            navigate('/video-player', {
                state: {
                    video,
                    courseId,
                    courseName,
                    videos,
                    startTime: seconds,
                    preserveChat: true,
                    courseDescription,
                    courseType,
                    courseThumbnail,
                    courseDocuments,
                    isOracleCourse,
                    chatState: {
                        messages,
                        lmsThreadId,
                        chatHistory
                    }
                }
            });
        }
    };

    const formatTimestamps = (text) => {
        const courseDocRegex = /\[([^\]]+?) - Course Document\]/g;
        let processedText = text.replace(courseDocRegex, (match, filename) => {
            return `[${filename}](${BASE_URL}/uploads/course-docs/${filename})`;
        });

        const fileReferenceRegex = /\[([^\]]+?) - Video (\d+)\]/g;
        let lastFileRef = null;
        
        processedText = processedText.replace(fileReferenceRegex, (match, filename, videoId) => {
            const currentFileRef = `${filename}-${videoId}`;
            
            if (currentFileRef === lastFileRef) {
                return '';
            }
            
            lastFileRef = currentFileRef;
            
            const targetVideo = videos?.find(v => v.id === parseInt(videoId));
            if (targetVideo?.supportingDocuments) {
                const document = targetVideo.supportingDocuments.find(doc => 
                    doc.name.includes(filename) || doc.path.includes(filename)
                );
                if (document) {
                    return `[${document.name}](${BASE_URL}/${document.path})`;
                }
            }
            return match;
        });

        processedText = processedText.replace(/\s*\.\s*(?=\n|$)/g, '');
        const timestampRegex = /(?:\(|\[|【)(\d{1,2}:\d{2}(?::\d{2})?(?:\.\d{1,3})?)(?: - )?([^)\]】]+)?(?:\)|\]|】)/g;
        const parts = processedText.split(timestampRegex);
        
        return parts.map((part, i) => {
            if (i % 3 === 1) {
                const timestamp = part;
                const videoTitle = parts[i + 1];
                
                const video = videoTitle ? 
                    videos?.find(v => v.title.toLowerCase().includes(videoTitle.toLowerCase())) : 
                    currentVideo;

                return (
                    <Button
                        key={i}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleTimestampClick(timestamp, video?.id || currentVideo?.id);
                        }}
                        sx={{
                            color: 'primary.main',
                            textTransform: 'none',
                            p: '0 4px',
                            minWidth: 'auto',
                            fontWeight: 'inherit',
                            fontSize: 'inherit',
                            '&:hover': {
                                backgroundColor: 'action.hover'
                            }
                        }}
                    >
                        {videoTitle ? 
                            `(${timestamp} - ${videoTitle})` :
                            `(${timestamp})`
                        }
                    </Button>
                );
            } else if (i % 3 === 2) {
                return null;
            }
            
            const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
            const linkParts = part.split(linkRegex);
            
            return linkParts.map((linkPart, j) => {
                if (j % 3 === 1) {
                    return (
                        <Button
                            key={`link-${j}`}
                            component="a"
                            href={linkParts[j + 1]}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: 'primary.main',
                                textTransform: 'none',
                                p: '0 4px',
                                minWidth: 'auto',
                                fontWeight: 'inherit',
                                fontSize: 'inherit',
                                '&:hover': {
                                    backgroundColor: 'action.hover'
                                }
                            }}
                        >
                            {linkPart}
                        </Button>
                    );
                } else if (j % 3 === 2) {
                    return null;
                }
                return <span key={`text-${j}`}>{formatBoldText(linkPart)}</span>;
            });
        });
    };

    const formatContent = (content) => {
        return content.split('\n').map((paragraph, i) => {
            const numberMatch = paragraph.match(/^(\d+\.\s*)(.*)/);
            
            if (numberMatch) {
                return (
                    <Box key={i} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <Typography component="span" sx={{ color: 'primary.main', fontWeight: 'bold', flexShrink: 0 }}>
                            {numberMatch[1]}
                        </Typography>
                        <Typography component="span" sx={{ wordBreak: 'break-word' }}>
                            {formatTimestamps(numberMatch[2])}
                        </Typography>
                    </Box>
                );
            }
            
            return (
                <Typography 
                    key={i} 
                    variant="body1" 
                    paragraph={true}
                    sx={{ 
                        mb: 1,
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word'
                    }}
                >
                    {formatTimestamps(paragraph)}
                </Typography>
            );
        });
    };

    const renderMessage = (msg, index) => {
        return (
            <Box 
                key={index} 
                sx={{ 
                    mb: 2, 
                    p: 2.5, 
                    backgroundColor: msg.role === 'user' 
                        ? theme.palette.action.selected
                        : theme.palette.background.paper,
                    borderRadius: 2,
                    maxWidth: '85%',
                    width: 'fit-content',
                    alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                }}
            >
                <Typography 
                    variant="subtitle2" 
                    sx={{ 
                        mb: 0.5,
                        color: msg.role === 'user' ? 'primary.main' : 'secondary.main',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    {msg.role === 'user' ? 'You' : 'SAGE'}
                    {isTTSEnabled && msg.role === 'assistant' && msg.isAudioProcessing && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AudiotrackIcon sx={{ fontSize: 18, color: 'primary.main', mr: 0.5 }} />
                            <CircularProgress size={16} sx={{ color: 'primary.main' }} />
                        </Box>
                    )}
                </Typography>
                {msg.isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size={20} />
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Thinking...
                        </Typography>
                    </Box>
                ) : (
                    formatContent(msg.content)
                )}
            </Box>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.scrollTo = function() {};
        sendMessage();
    };

    const startNewChat = async () => {
        // Stop any current audio playback
        stopAudioPlayback(true);
        
        // Reset audio processing state
        setIsAudioProcessing(false);
        
        setIsLoadingMessages(true);
        setMessages([]);
        try {
            const threadId = await createThread(courseName);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchChatHistory();
            }
        } catch (error) {
            console.error('Error starting new chat:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const startVoiceRecognition = async () => {
        try {
            if (stopRecording) {
                stopRecording();
                setStopRecording(null);
            }

            if (audioStream) {
                audioStream.getTracks().forEach(track => {
                    track.stop();
                    console.log('Stopping existing track:', track.kind);
                });
                setAudioStream(null);
            }

            setIsProcessing(false);
            setAudioContext(null);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setAudioStream(stream);
            setIsRecording(true);

            const wsUrl = `${BASE_URL.replace('http', 'ws')}/ws`;
            const socket = new WebSocket(wsUrl);
            
            const newAudioContext = new AudioContext({
                sampleRate: 44100
            });
            setAudioContext(newAudioContext);
            
            const source = newAudioContext.createMediaStreamSource(stream);
            const processor = newAudioContext.createScriptProcessor(4096, 1, 1);

            source.connect(processor);
            processor.connect(newAudioContext.destination);

            let isSocketReady = false;

            socket.onopen = () => {
                console.log('WebSocket connection established');
                socket.send(JSON.stringify({
                    type: 'identify',
                    userId: getToken()
                }));
            };

            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('Received WebSocket message:', data);
                
                if (data.type === 'connected') {
                    socket.send(JSON.stringify({ type: 'start_speech' }));
                    isSocketReady = true;
                } else if (data.type === 'transcript') {
                    console.log('Received transcript:', data.transcript);
                    setIsProcessing(true);
                    if (data.transcript && data.transcript.trim()) {
                        if (data.isFinal) {
                            setInputMessage(prevMessage => prevMessage + ' ' + data.transcript.trim());
                            setIsProcessing(false);
                        }
                    }
                } else if (data.type === 'speech_error') {
                    console.error('Speech recognition error:', data.error);
                    setIsProcessing(false);
                    cleanup();
                } else if (data.type === 'speech_end') {
                    console.log('Speech recognition ended');
                    setIsProcessing(false);
                }
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                cleanup();
            };

            processor.onaudioprocess = (e) => {
                if (isSocketReady && socket.readyState === WebSocket.OPEN) {
                    const inputData = e.inputBuffer.getChannelData(0);
                    const pcmData = new Int16Array(inputData.length);
                    for (let i = 0; i < inputData.length; i++) {
                        const s = Math.max(-1, Math.min(1, inputData[i]));
                        pcmData[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
                    }
                    socket.send(pcmData.buffer);
                }
            };

            const cleanup = async () => {
                console.log('Cleaning up recording resources...');
                
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ type: 'stop_speech' }));
                    socket.close();
                }
                
                if (audioStream) {
                    const tracks = audioStream.getTracks();
                    tracks.forEach(track => {
                        if (track.readyState === 'live') {
                            track.stop();
                            console.log('Audio track stopped:', track.kind);
                        }
                    });
                    setAudioStream(null);
                }
                
                if (source && processor) {
                    try {
                        source.disconnect();
                        processor.disconnect();
                        console.log('Audio nodes disconnected');
                    } catch (err) {
                        console.error('Error disconnecting audio nodes:', err);
                    }
                }
                
                if (newAudioContext) {
                    try {
                        if (newAudioContext.state !== 'closed') {
                            await newAudioContext.close();
                            console.log('AudioContext closed');
                        }
                    } catch (err) {
                        console.error('Error closing AudioContext:', err);
                    }
                }
                
                setAudioContext(null);
                setIsRecording(false);
                setIsProcessing(false);
                setStopRecording(null);

                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then(tempStream => {
                        tempStream.getTracks().forEach(track => track.stop());
                    })
                    .catch(() => {});
            };

            setStopRecording(() => cleanup);

        } catch (error) {
            console.error('Error starting voice recognition:', error);
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
            }
            setIsRecording(false);
            setIsProcessing(false);
            setAudioContext(null);
            setAudioStream(null);
            setStopRecording(null);
            alert('Error accessing microphone. Please check your permissions and try again.');
        }
    };

    useEffect(() => {
        return () => {
            if (audioStream) {
                audioStream.getTracks().forEach(track => {
                    if (track.readyState === 'live') {
                        track.stop();
                        console.log('Stopping track on unmount:', track.kind);
                    }
                });
            }
            if (stopRecording) {
                stopRecording();
                setStopRecording(null);
            }
        };
    }, [stopRecording, audioStream]);

    // Function to handle streaming audio chunks
    const handleStreamingAudioChunk = (base64Audio, metadata) => {
        const { index, total, isLastChunk } = metadata;
        const chunkId = `chunk-${index}`;
        
        // Convert base64 to array buffer
        const binaryString = atob(base64Audio);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        
        // Store the chunk
        if (!audioChunks.current[chunkId]) {
            audioChunks.current[chunkId] = {
                chunks: [],
                complete: false,
                index,
                total
            };
        }
        
        audioChunks.current[chunkId].chunks.push(bytes);
        
        // If this is the last chunk, mark it as complete
        if (isLastChunk) {
            audioChunks.current[chunkId].complete = true;
        }
        
        // Start playback immediately if not already playing
        if (!isPlaying) {
            startStreamingPlayback(chunkId);
        }
    };

    // Function to start playback of streaming audio
    const startStreamingPlayback = (chunkId) => {
        if (!audioChunks.current[chunkId]) return;
        
        const { chunks } = audioChunks.current[chunkId];
        if (chunks.length === 0) return;
        
        // Stop any current playback
        if (isPlaying) {
            audioRef.current.pause();
        }
        
        setIsPlaying(true);
        
        // Combine chunks we have so far
        const combinedChunks = new Blob(chunks, { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(combinedChunks);
        
        // Play what we have
        audioRef.current.src = audioUrl;
        audioRef.current.play().catch(e => {
            console.error('Error playing streaming audio:', e);
            setIsPlaying(false);
            
            // Clean up
            URL.revokeObjectURL(audioUrl);
            delete audioChunks.current[chunkId];
        });
        
        console.log(`Started playing streaming audio segment ${chunkId}`);
    };

    // Add back the cleanup effect for component unmount
    useEffect(() => {
        return () => {
            // Cleanup audio on component unmount
            if (audioRef.current) {
                audioRef.current.pause();
                if (audioRef.current.src) {
                    URL.revokeObjectURL(audioRef.current.src);
                }
            }
            
            // Cleanup all URLs in the queue
            audioQueue.forEach(item => {
                const url = typeof item === 'string' ? item : item?.url;
                if (url) URL.revokeObjectURL(url);
            });
        };
    }, [audioQueue]);

    return (
        <Paper sx={{ 
            height: isMobile ? '100dvh' : '85vh',
            width: isMobile ? '100%' : '400px',
            minWidth: isMobile ? '100%' : '400px',
            display: 'flex', 
            flexDirection: 'column',
            overflow: 'hidden',
            p: 2,
            position: 'relative',
            isolation: 'isolate',
            borderRadius: isMobile ? 0 : 1,
            ...(isMobile && {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1200,
            })
        }}>
            <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                mb: 2,
                width: '100%',
                alignItems: 'center'
            }}>
                <Button 
                    onClick={() => setShowChatHistory(!showChatHistory)}
                    variant="outlined"
                    sx={{ 
                        flex: 1,
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                        color: '#FFFFFF',
                        '&:hover': {
                            borderColor: 'primary.main',
                            backgroundColor: 'rgba(255, 255, 255, 0.04)',
                        },
                        textTransform: 'none',
                        padding: '8px 16px',
                        minHeight: '40px',
                    }}
                >
                    {showChatHistory ? 'Hide Chat History' : 'Show Chat History'}
                </Button>
                <IconButton 
                    onClick={startNewChat}
                    sx={{ 
                        borderRadius: '8px',
                        backgroundColor: 'rgba(255, 255, 255, 0.04)',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        },
                        width: '40px',
                        height: '40px',
                    }}
                >
                    <AddIcon />
                </IconButton>
                <IconButton
                    onClick={toggleTTS}
                    sx={{ 
                        borderRadius: '8px',
                        backgroundColor: isTTSEnabled ? 'primary.main' : 'rgba(255, 255, 255, 0.04)',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: isTTSEnabled ? 'primary.dark' : 'rgba(255, 255, 255, 0.08)',
                        },
                        width: '40px',
                        height: '40px',
                        position: 'relative'
                    }}
                >
                    {isTTSEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
                    {isPlaying && isTTSEnabled && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: 'success.main',
                                bottom: '2px',
                                right: '2px',
                                animation: 'pulse 1.5s infinite',
                                '@keyframes pulse': {
                                    '0%': { opacity: 0.6, transform: 'scale(1)' },
                                    '50%': { opacity: 1, transform: 'scale(1.2)' },
                                    '100%': { opacity: 0.6, transform: 'scale(1)' }
                                }
                            }}
                        />
                    )}
                </IconButton>
                {isTTSEnabled && (
                    <IconButton
                        onClick={handleVoiceMenuOpen}
                        sx={{ 
                            borderRadius: '8px',
                            backgroundColor: 'rgba(255, 255, 255, 0.04)',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                            },
                            width: '40px',
                            height: '40px',
                        }}
                    >
                        <SettingsVoiceIcon />
                    </IconButton>
                )}
                <Menu
                    anchorEl={voiceMenuAnchor}
                    open={Boolean(voiceMenuAnchor)}
                    onClose={handleVoiceMenuClose}
                    PaperProps={{
                        sx: {
                            maxHeight: 300,
                            width: '200px'
                        }
                    }}
                >
                    {ttsVoices.map((voice) => (
                        <MenuItem 
                            key={voice.id} 
                            onClick={() => handleVoiceSelect(voice.id)}
                            selected={ttsVoice === voice.id}
                        >
                            {voice.name}
                        </MenuItem>
                    ))}
                </Menu>
                {isMobile && (
                    <IconButton 
                        onClick={onClose}
                        sx={{ 
                            color: 'text.primary',
                            ml: 1
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                height: isMobile ? 'calc(100dvh - 72px)' : 'calc(100% - 56px)',
                overflow: 'hidden'
            }}>
                {showChatHistory && (
                    <Box sx={{ 
                        height: '200px',
                        mb: 2,
                        backgroundColor: 'background.paper',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    }}>
                        <ChatHistoryNavbar 
                            chatHistory={chatHistory}
                            onThreadSelect={handleThreadSelect}
                            currentThreadId={lmsThreadId}
                        />
                    </Box>
                )}

                <Box sx={{ 
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: showChatHistory ? 
                        (isMobile ? 'calc(100dvh - 288px)' : 'calc(100% - 216px)') : 
                        '100%',
                    overflow: 'hidden',
                }}>
                    <Box sx={{ 
                        flexGrow: 1, 
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mb: isMobile ? 8 : 3,
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        scrollBehavior: 'smooth',
                        overscrollBehavior: 'contain',
                        p: 2,
                    }}>
                        {isLoadingMessages ? (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CircularProgress />
                            </Box>
                        ) : messages.length === 0 && !showChatHistory ? (
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                gap: 2,
                                color: 'text.secondary',
                                textAlign: 'center',
                                p: 3
                            }}>
                                <ChatIcon sx={{ fontSize: 40, opacity: 0.7 }} />
                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                    Start a Conversation
                                </Typography>
                                <Typography variant="body2" sx={{ maxWidth: '280px' }}>
                                    Ask questions about the course content, request clarification, or discuss specific topics.
                                </Typography>
                            </Box>
                        ) : (
                            messages.map((msg, index) => renderMessage(msg, index))
                        )}
                        <div ref={messagesEndRef} style={{ height: 0 }} />
                    </Box>

                    <Box 
                        component="form" 
                        onSubmit={handleSubmit}
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            p: 1,
                            borderRadius: 2,
                            backgroundColor: 'background.paper',
                            minHeight: '56px',
                            position: 'sticky',
                            bottom: 0,
                            zIndex: 1,
                            ...(isMobile && {
                                position: 'fixed',
                                left: '16px',
                                right: '16px',
                                bottom: '16px',
                                width: 'calc(100% - 32px)',
                                marginBottom: 0,
                                borderBottomLeftRadius: '8px',
                                borderBottomRightRadius: '8px',
                                transform: 'translateZ(0)',
                                WebkitTransform: 'translateZ(0)',
                                touchAction: 'manipulation'
                            })
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            placeholder={isProcessing ? "Processing speech..." : "Type your message..."}
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            inputProps={{
                                style: { fontSize: '16px' },
                                autoComplete: 'off',
                                autoCorrect: 'off',
                                autoCapitalize: 'off',
                                spellCheck: 'false'
                            }}
                            sx={{
                                '& .MuiInput-root': {
                                    padding: '8px 12px',
                                    '&:before, &:after': {
                                        display: 'none',
                                    }
                                },
                                ...(isProcessing && {
                                    '& .MuiInput-input': {
                                        color: 'text.secondary',
                                        fontStyle: 'italic'
                                    }
                                })
                            }}
                        />

                        {isRecording ? (
                            <IconButton 
                                onClick={() => stopRecording()}
                                sx={{
                                    backgroundColor: 'error.main',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'error.dark'
                                    },
                                    width: 40,
                                    height: 40,
                                    animation: isProcessing ? 'pulse 1.5s infinite' : 'none',
                                    '@keyframes pulse': {
                                        '0%': {
                                            boxShadow: '0 0 0 0 rgba(255, 0, 0, 0.4)'
                                        },
                                        '70%': {
                                            boxShadow: '0 0 0 10px rgba(255, 0, 0, 0)'
                                        },
                                        '100%': {
                                            boxShadow: '0 0 0 0 rgba(255, 0, 0, 0)'
                                        }
                                    }
                                }}
                            >
                                <StopIcon />
                            </IconButton>
                        ) : (
                            <IconButton 
                                onClick={startVoiceRecognition}
                                disabled={isLoading}
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'primary.dark'
                                    },
                                    width: 40,
                                    height: 40,
                                }}
                            >
                                <MicIcon />
                            </IconButton>
                        )}

                        <IconButton 
                            type="submit"
                            disabled={isLoading || !inputMessage.trim()}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'action.disabledBackground',
                                    color: 'action.disabled',
                                },
                                width: 40,
                                height: 40,
                            }}
                        >
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}

export default AIChat; 