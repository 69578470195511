import React, { useState, useRef, useEffect } from 'react';
import { 
    Box, Typography, Paper, TextField, 
    IconButton, CircularProgress, Button
} from '@mui/material';
import { BASE_URL } from '../Constants';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import MicIcon from '@mui/icons-material/Mic';

function EmbeddedChat({ courseId, clientId, courseName, apiKey, assistantId, onError, clientCustomizations }) {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lmsThreadId, setLMSThreadId] = useState(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [prefilledButtons, setPrefilledButtons] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const messagesEndRef = useRef(null);
    const [logoError, setLogoError] = useState(false);

    useEffect(() => {
        // Initialize chat immediately
        if (!initialized && !isLoadingMessages && !lmsThreadId) {
            initializeChat();
        }
        
        // Set prefilled buttons if available
        if (clientCustomizations?.prefilled_buttons) {
            setPrefilledButtons(clientCustomizations.prefilled_buttons);
        }
    }, [clientCustomizations]);

    // Initialize chat only when needed (first user interaction)
    const initializeChat = async () => {
        // Don't initialize more than once
        if (initialized || isLoadingMessages || lmsThreadId) return;
        
        if (!courseId || !clientId || !apiKey) {
            if (onError) onError('Missing required parameters or authentication token');
            return;
        }
        
        setIsLoadingMessages(true);
        setInitialized(true);
        
        try {
            const threadId = await createThread(courseId, clientId);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchMessages(threadId);
            } else {
                if (onError) onError('Failed to create chat thread. Authentication may have failed.');
            }
        } catch (error) {
            console.error('Error initializing chat:', error);
            if (onError) onError('Error initializing chat. Please try again later.');
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const createThread = async (course_id, client_id) => {
        try {
            console.log('Creating thread for course ID:', course_id);
            const response = await fetch(`${BASE_URL}/api/public/createLMSthread`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': apiKey
                },
                body: JSON.stringify({ 
                    course_id,
                    client_id,
                    course_name: courseName || undefined
                })
            });
            
            if (!response.ok) {
                try {
                    const errorData = await response.json();
                    console.error('Thread creation failed:', errorData.error);
                    if (onError) onError(errorData.error);
                } catch (parseError) {
                    console.error('Failed to parse error response:', parseError);
                    if (onError) onError('Failed to create chat thread');
                }
                return null;
            }
            
            const data = await response.json();
            return data.threadId;
        } catch (error) {
            console.error('Error creating thread:', error);
            if (onError) onError('Network error while creating chat thread');
            return null;
        }
    };

    const fetchMessages = async (threadId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/public/getLMSmessages/${threadId}`, {
                headers: {
                    'X-API-Key': apiKey
                }
            });
            
            if (!response.ok) throw new Error('Failed to fetch messages');
            const data = await response.json();
            setMessages(data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const sendMessage = async (messageText = inputMessage) => {
        if (!messageText.trim() || !lmsThreadId) return;

        // Add user message immediately
        const newMessage = { role: 'user', content: messageText };
        setMessages(prev => [...prev, newMessage]);
        setInputMessage('');
        
        // Add assistant placeholder immediately with a more engaging loading state
        const placeholderId = `placeholder-${Date.now()}`;
        setMessages(prev => [...prev, { 
            role: 'assistant', 
            content: '', 
            id: placeholderId,
            isLoading: true,
            thinkingDots: 0 // Add a counter for animated thinking dots
        }]);
        
        // Start thinking animation
        const thinkingInterval = setInterval(() => {
            setMessages(prev => 
                prev.map(msg => 
                    msg.id === placeholderId 
                    ? { ...msg, thinkingDots: (msg.thinkingDots + 1) % 4 } 
                    : msg
                )
            );
        }, 500);
        
        // Scroll to bottom immediately
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 10);
        
        setIsLoading(true);
        let isStreaming = false;
        
        try {
            // Use AbortController to handle timeouts
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout
            
            const response = await fetch(`${BASE_URL}/api/public/sendLMSchatmessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': apiKey
                },
                body: JSON.stringify({
                    threadId: lmsThreadId,
                    message: messageText,
                    course_id: courseId,
                    client_id: clientId,
                    course_name: courseName || undefined,
                    assistant_id: assistantId // Send the assistant ID directly
                }),
                signal: controller.signal
            });
            
            clearTimeout(timeoutId);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            let accumulatedContent = '';
            let decoder = new TextDecoder();
            let buffer = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                // Decode and add to buffer
                buffer += decoder.decode(value, { stream: true });
                
                // Process complete lines
                const lines = buffer.split('\n');
                buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer
                
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.slice(6));

                            switch (data.type) {
                                case 'connected':
                                    // Connection established, update UI if needed
                                    break;
                                    
                                case 'thinking':
                                    // Server has acknowledged and is processing
                                    // Update the UI to show a more specific thinking state
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, content: 'Preparing response...', thinkingState: 'active' }
                                            : msg
                                    ));
                                    break;
                                    
                                case 'chunk':
                                    if (!isStreaming) {
                                        isStreaming = true;
                                        setIsLoading(false);
                                    }
                                    accumulatedContent += data.content;
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, content: accumulatedContent, isLoading: false, thinkingState: null }
                                            : msg
                                    ));
                                    break;

                                case 'error':
                                    console.error('Stream error:', data.error);
                                    if (onError) onError(data.error || 'An error occurred with the chat service');
                                    // Don't throw, just return to end the function gracefully
                                    setMessages(prev => prev.filter(msg => msg.id !== placeholderId));
                                    return;

                                case 'end':
                                    const finalContent = data.content || accumulatedContent;
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { role: 'assistant', content: finalContent }
                                            : msg
                                    ));
                                    return;
                            }
                        } catch (e) {
                            console.error('Error parsing SSE data:', e);
                            // Continue processing other lines instead of failing completely
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            // Keep the user message but remove the assistant placeholder
            setMessages(prev => prev.filter(msg => msg.id !== placeholderId));
            
            // Provide more specific error messages based on the error type
            let errorMessage = 'Error communicating with the chat service. Please try again.';
            
            if (error.name === 'AbortError') {
                errorMessage = 'Request timed out. The server took too long to respond.';
            } else if (error.message.includes('NetworkError') || error.message.includes('Failed to fetch')) {
                errorMessage = 'Network error. Please check your internet connection and try again.';
            } else if (error.message.includes('processing response')) {
                errorMessage = 'The server encountered an error processing your request. Please try again.';
            }
            
            if (onError) {
                onError(errorMessage);
            }
        } finally {
            clearInterval(thinkingInterval);
            setIsLoading(false);
        }
    };

    const handlePrefilledButtonClick = (buttonText, buttonId) => {
        // Remove initialization check since it's already done
        sendMessage(buttonText);
    };

    useEffect(() => {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage?.role === 'user') {
            messagesEndRef.current?.scrollIntoView({ block: 'end' });
        }
    }, [messages]);

    const formatBoldText = (text) => {
        const parts = text.split(/(\*\*.*?\*\*)/g);
        return parts.map((part, i) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return (
                    <Typography
                        key={i}
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {part.slice(2, -2)}
                    </Typography>
                );
            }
            return <span key={i}>{part}</span>;
        });
    };

    const formatContent = (content) => {
        // Remove any citation formats with 【】 brackets
        try {
            if (typeof content === 'string') {
                content = content.replace(/【[^】]*】/g, '');
            }
        } catch (error) {
            console.error('Error removing citations:', error);
            // Continue with original content if regex fails
        }
        
        return content.split('\n').map((paragraph, i) => {
            const numberMatch = paragraph.match(/^(\d+\.\s*)(.*)/);
            
            if (numberMatch) {
                return (
                    <Box key={i} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <Typography component="span" sx={{ color: 'primary.main', fontWeight: 'bold', flexShrink: 0 }}>
                            {numberMatch[1]}
                        </Typography>
                        <Typography component="span" sx={{ wordBreak: 'break-word' }}>
                            {formatBoldText(numberMatch[2])}
                        </Typography>
                    </Box>
                );
            }
            
            return (
                <Typography 
                    key={i} 
                    variant="body1" 
                    paragraph={true}
                    sx={{ 
                        mb: 1,
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word'
                    }}
                >
                    {formatBoldText(paragraph)}
                </Typography>
            );
        });
    };

    const renderMessage = (msg, index) => {
        return (
            <Box 
                key={index} 
                sx={{ 
                    mb: 2, 
                    p: 2, 
                    backgroundColor: msg.role === 'user' 
                        ? 'action.selected'
                        : 'background.paper',
                    borderRadius: 2,
                    maxWidth: '85%',
                    width: 'fit-content',
                    alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                    position: 'relative',
                    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                }}
            >
                {/* AI Message Header with Logo */}
                {msg.role === 'assistant' && (
                    <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1,
                        pb: 1,
                        borderBottom: '1px solid',
                        borderColor: 'rgba(255, 255, 255, 0.1)'
                    }}>
                        {clientCustomizations?.logo_url && !logoError ? (
                            <img 
                                src={clientCustomizations.logo_url} 
                                alt="Assistant"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    objectFit: 'contain'
                                }}
                            />
                        ) : (
                            <ChatIcon sx={{ 
                                fontSize: 20,
                                color: theme => theme.palette.primary.dark
                            }} />
                        )}
                        <Typography 
                            variant="subtitle2" 
                            sx={{ 
                                color: theme => theme.palette.primary.dark,
                                fontSize: '0.75rem',
                                fontWeight: 500
                            }}
                        >
                            {courseName} Assistant
                        </Typography>
                    </Box>
                )}

                {/* User Message Header */}
                {msg.role === 'user' && (
                    <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 1,
                        mb: 1,
                        pb: 1,
                        borderBottom: '1px solid',
                        borderColor: 'rgba(255, 255, 255, 0.1)'
                    }}>
                        <Typography 
                            variant="subtitle2" 
                            sx={{ 
                                color: 'text.secondary',
                                fontSize: '0.75rem',
                                fontWeight: 500
                            }}
                        >
                            You
                        </Typography>
                    </Box>
                )}

                {/* Message Content */}
                {msg.isLoading ? (
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        color: '#fff'
                    }}>
                        <CircularProgress 
                            size={16} 
                            sx={{ color: '#fff' }}
                        />
                        <Typography variant="body2" sx={{ 
                            color: '#fff'
                        }}>
                            Thinking{msg.thinkingDots !== undefined ? '.'.repeat(msg.thinkingDots) : '...'}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ color: 'text.primary' }}>
                        {formatContent(msg.content)}
                    </Box>
                )}
            </Box>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Initialize chat if not already initialized
        if (!initialized) {
            initializeChat().then(() => {
                // Only send message after initialization is complete
                if (inputMessage.trim()) {
                    sendMessage();
                }
            });
        } else {
            sendMessage();
        }
    };

    const startVoiceRecognition = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert("Speech recognition is not supported in your browser.");
            return;
        }
        const recognition = new SpeechRecognition();
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = 'en-US';

        recognition.onstart = () => {
            setIsRecording(true);
        };

        recognition.onresult = (event) => {
            if (event.results.length > 0) {
                const transcript = event.results[0][0].transcript;
                setInputMessage((prev) => prev ? `${prev.trim()} ${transcript}` : transcript);
            }
            setIsRecording(false);
            recognition.stop();
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error", event);
            setIsRecording(false);
            recognition.stop();
        };

        recognition.onend = () => {
            setIsRecording(false);
        };

        recognition.start();
    };

    useEffect(() => {
        // Intercept link clicks to prevent navigation outside the iframe
        const handleLinkClicks = (event) => {
            // Find if the click was on a link
            let target = event.target;
            while (target && target.tagName !== 'A') {
                target = target.parentElement;
            }
            
            // If it was a link, check if it's external
            if (target && target.tagName === 'A') {
                const href = target.getAttribute('href');
                
                // If it's an external link or navigates to another page in your app
                if (href && !href.startsWith('#')) {
                    event.preventDefault();
                    
                    // Either open in a new tab/window
                    window.open(href, '_blank');
                    
                    // Or just prevent navigation entirely
                    // Do nothing after preventDefault
                }
            }
        };
        
        document.addEventListener('click', handleLinkClicks);
        
        return () => {
            document.removeEventListener('click', handleLinkClicks);
        };
    }, []);

    return (
        <Paper sx={{ 
            height: '100%',
            width: '100%',
            display: 'flex', 
            flexDirection: 'column',
            overflow: 'hidden',
            position: 'relative',
            isolation: 'isolate',
            borderRadius: 0,
            bgcolor: 'background.default',
        }}>
            {/* Modern header with logo and name */}
            <Box sx={{ 
                p: 1.5,
                borderBottom: '1px solid',
                borderColor: 'rgba(255, 255, 255, 0.1)',
                bgcolor: '#1b1b1b',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}>
                {clientCustomizations?.logo_url && !logoError ? (
                    <>
                        <img 
                            src={clientCustomizations.logo_url} 
                            alt="Chat logo"
                            onError={() => setLogoError(true)}
                            style={{
                                maxWidth: '120px',
                                maxHeight: '28px',
                                objectFit: 'contain'
                            }}
                        />
                        <Box 
                            component="span" 
                            sx={{ 
                                width: '1px', 
                                height: '20px', 
                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                mx: 1
                            }} 
                        />
                    </>
                ) : null}
                <Typography 
                    variant="subtitle1" 
                    sx={{ 
                        color: 'text.primary',
                        fontWeight: 500,
                        fontSize: '0.9rem'
                    }}
                >
                    {courseName}
                </Typography>
            </Box>

            {/* Messages container */}
            <Box sx={{ 
                flexGrow: 1, 
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
                bgcolor: '#141414', // Slightly darker background for messages
            }}>
                <Box sx={{ 
                    flexGrow: 1, 
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    p: 2,
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: 'rgba(255, 255, 255, 0.2)',
                    },
                }}>
                    {isLoadingMessages ? (
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <CircularProgress />
                        </Box>
                    ) : messages.length === 0 ? (
                        <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            gap: 2,
                            color: 'text.secondary',
                            textAlign: 'center',
                            p: 3
                        }}>
                            <ChatIcon sx={{ fontSize: 40, opacity: 0.7 }} />
                            <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                Start a Conversation
                            </Typography>
                            
                            {/* Prefilled buttons - only show here when no messages */}
                            {prefilledButtons.length > 0 && (
                                <Box sx={{ 
                                    display: 'flex', 
                                    flexWrap: 'wrap', 
                                    gap: 1, 
                                    justifyContent: 'center',
                                    mt: 2,
                                    maxWidth: '100%'
                                }}>
                                    {prefilledButtons.map((button) => (
                                        <Button
                                            key={button.id}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handlePrefilledButtonClick(button.text, button.id)}
                                            sx={{
                                                borderRadius: '18px',
                                                textTransform: 'none',
                                                px: 2,
                                                py: 0.5,
                                                fontSize: '0.9rem',
                                                borderColor: 'primary.light',
                                                color: 'primary.main',
                                                '&:hover': {
                                                    backgroundColor: 'primary.lighter',
                                                    borderColor: 'primary.main',
                                                }
                                            }}
                                        >
                                            {button.text}
                                        </Button>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    ) : (
                        messages.map((msg, index) => renderMessage(msg, index))
                    )}
                    <div ref={messagesEndRef} style={{ height: 0 }} />
                </Box>

                {/* Modern input area with integrated button */}
                <Box 
                    component="form" 
                    onSubmit={handleSubmit}
                    sx={{ 
                        p: 2,
                        bgcolor: '#141414', // Match chat background color
                        display: 'flex',
                        gap: 1,
                    }}
                >
                    <Box sx={{ 
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Type your message..."
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    bgcolor: '#1E1E1E',
                                    borderRadius: 3,
                                    pr: '60px', // Make room for the button
                                    '& fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.1)',
                                        borderWidth: '1px',
                                        transition: 'border-color 0.2s',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.2)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.2)',
                                    },
                                    '& input': {
                                        padding: '14px 16px',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                },
                            }}
                        />
                        <IconButton 
                            type="submit"
                            disabled={isLoading || !inputMessage.trim()}
                            sx={{
                                position: 'absolute',
                                right: '8px',
                                bgcolor: 'transparent',
                                color: isLoading || !inputMessage.trim() ? 'action.disabled' : 'primary.main',
                                '&:hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                },
                                width: '36px',
                                height: '36px',
                                transition: 'all 0.2s',
                            }}
                        >
                            {isLoading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <SendIcon sx={{ 
                                    fontSize: '20px',
                                    transform: 'rotate(-45deg)',
                                    transition: 'transform 0.2s',
                                    '&:hover': {
                                        transform: 'rotate(-45deg) scale(1.1)',
                                    }
                                }} />
                            )}
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}

export default EmbeddedChat; 