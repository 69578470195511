import React, { useState, useEffect, useRef, useContext } from 'react';
import { 
    Alert,
    Box,
    Button, 
    Container, 
    CssBaseline, 
    Paper, 
    Snackbar, 
    TextField, 
    Toolbar, 
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    OutlinedInput,
    IconButton,
    Autocomplete,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    LinearProgress,
} from '@mui/material';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId, getRoleId, getClientId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UndoIcon from '@mui/icons-material/Undo';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { NavBarContext } from '../../components/navigation/NavBarContext';

function LMSAddCourse() {
    const [courseName, setCourseName] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [editingCourseId, setEditingCourseId] = useState(null);
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState(1);
    const coursesPerPage = 3;
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const isSuperAdmin = getRoleId() === 1;
    const currentClientId = getClientId();
    const [isPublic, setIsPublic] = useState(true);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [courseImage, setCourseImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [courseType, setCourseType] = useState('normal');
    const [oracleInstructions, setOracleInstructions] = useState('');
    const [courseDocs, setCourseDocs] = useState([]);
    const [newCourseDocs, setNewCourseDocs] = useState([]);
    const [docsToKeep, setDocsToKeep] = useState([]);
    const [openVideoDialog, setOpenVideoDialog] = useState(false);
    const [newCourseId, setNewCourseId] = useState(null);
    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);
    const [tempInstructions, setTempInstructions] = useState('');
    const [uploadProgress, setUploadProgress] = useState({
        total: 0,
        processed: 0,
        currentFile: '',
        status: ''
    });
    const [isUploading, setIsUploading] = useState(false);
    const wsRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { openNavBar } = useContext(NavBarContext);

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch courses');
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setError('Failed to fetch courses');
        }
    };

    const handleEdit = (course) => {
        setEditingCourseId(course.id);
        setCourseName(course.course_name);
        setCourseDescription(course.course_description || '');
        setIsPublic(course.is_public);
        setCourseType(course.course_type === 'oracle' ? 'oracle' : 'normal');
        setOracleInstructions(course.oracle_instructions || '');
        
        // Set assigned users if course is private
        if (!course.is_public && course.assigned_users) {
            // Extract just the user_ids from the assigned_users objects
            const userIds = course.assigned_users.map(user => user.user_id);
            setSelectedUsers(userIds);
        } else {
            setSelectedUsers([]);
        }
        
        // Handle clients
        const clients = Array.isArray(course.access_clients) 
            ? course.access_clients 
            : course.access_clients 
                ? [Number(course.access_clients)] 
                : [];
        setSelectedClients(clients);

        // If course is private, fetch available users
        if (!course.is_public) {
            fetch(`${BASE_URL}/api/readadmins`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setAvailableUsers(data);
            })
            .catch(error => console.error('Error fetching admins:', error));
        }

        if (course.image_path) {
            setImagePreview(`${BASE_URL}${course.image_path}`);
        } else {
            setImagePreview(null);
        }

        if (course.course_type === 'oracle') {
            // Fetch course documents
            fetch(`${BASE_URL}/api/courses/${course.id}/documents`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setCourseDocs(data);
                setDocsToKeep(data.map(doc => ({ id: doc.id, keep: true })));
            })
            .catch(error => console.error('Error fetching course documents:', error));
        }
    };

    const handleDelete = async (courseId) => {
        if (!window.confirm('Are you sure you want to delete this course?')) return;

        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete course');
            
            setSuccess(true);
            setSuccessMessage('Course deleted successfully!');
            fetchCourses();
        } catch (error) {
            console.error('Error deleting course:', error);
            setError('Failed to delete course');
        }
    };

    const handleClientChange = (event) => {
        setSelectedClients(event.target.value);
    };

    const handleCourseSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess(false);

        try {
            // Add document count validation
            if (courseType === 'oracle') {
                const totalDocs = newCourseDocs.length + 
                    (docsToKeep?.filter(doc => doc.keep !== false).length || 0);
                
                if (totalDocs > 30) {
                    throw new Error('Maximum 30 documents allowed per course. Please remove some documents.');
                }
                
                if (totalDocs === 0) {
                    throw new Error('At least one course document is required for Oracle course type');
                }
            }

            // Validate Oracle course requirements
            if (courseType === 'oracle') {
                if (!oracleInstructions.trim()) {
                    throw new Error('Oracle instructions are required for Oracle course type');
                }
                
                // Check if there are either existing docs to keep or new docs being added
                const hasExistingDocs = docsToKeep.some(doc => doc.keep !== false);
                if (!hasExistingDocs && newCourseDocs.length === 0) {
                    throw new Error('At least one course document is required for Oracle course type');
                }
            }

            const formData = new FormData();
            formData.append('course_name', courseName);
            formData.append('course_description', courseDescription);
            formData.append('who_created', getUserId());
            formData.append('date_created', new Date().toISOString().split('T')[0]);
            formData.append('is_public', isPublic ? '1' : '0');
            formData.append('course_type', courseType === 'oracle' ? 'oracle' : null);
            
            // Add access control data with all roles (1, 2, 3)
            formData.append('access_control', JSON.stringify({
                roles: [1, 2, 3], // Always include all roles
                clients: selectedClients
            }));

            // Only append assigned users if the course is private
            if (selectedUsers.length > 0) {
                formData.append('assigned_users', JSON.stringify(selectedUsers));
            }

            // Append course image if exists
            if (courseImage) {
                formData.append('course_image', courseImage);
            }

            if (courseType === 'oracle') {
                formData.append('oracle_instructions', oracleInstructions);
                
                // Add existing docs to keep
                if (editingCourseId && docsToKeep.length > 0) {
                    formData.append('existingDocs', JSON.stringify(docsToKeep));
                }

                // Add new course documents
                newCourseDocs.forEach((file, index) => {
                    formData.append(`courseDoc_${index}`, file);
                });
            }

            const url = editingCourseId 
                ? `${BASE_URL}/api/courses/${editingCourseId}`
                : `${BASE_URL}/api/submitcourses`;

            const method = editingCourseId ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to submit course');
            }

            const responseData = await response.json();
            setSuccess(true);
            setSuccessMessage(editingCourseId ? 'Course updated successfully!' : 'Course added successfully!');
            
            // Reset form
            setCourseName('');
            setCourseDescription('');
            setSelectedUsers([]);
            setSelectedClients([]);
            setCourseImage(null);
            setImagePreview(null);
            
            // For new courses, show dialog for adding videos
            if (!editingCourseId) {
                setNewCourseId(responseData.courseId);
                setOpenVideoDialog(true);
            }

            // Refresh the courses list
            fetchCourses();

        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'An unexpected error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    const handleVideoDialogResponse = (addVideos) => {
        setOpenVideoDialog(false);
        if (courseType === 'oracle') {
            navigate('/');
        } else if (addVideos) {
            navigate('/lmsaddvideos', {
                state: { selectedCourseId: newCourseId }
            });
        } else {
            navigate('/');
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    useEffect(() => {
        if (isSuperAdmin) {
            fetch(`${BASE_URL}/api/readclients`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setClients(data);
                }
            })
            .catch(error => console.error('Error fetching clients:', error));
        }
    }, [isSuperAdmin]);

    useEffect(() => {
        fetch(`${BASE_URL}/api/readadmins`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            // Filter out the current user from the available users list
            const currentUserId = getUserId();
            const filteredUsers = Array.isArray(data) 
                ? data.filter(user => user.user_id !== currentUserId)
                : [];
            setAvailableUsers(filteredUsers);
        })
        .catch(error => {
            console.error('Error fetching users:', error);
            setAvailableUsers([]);
        });
    }, []);

    useEffect(() => {
        const connectWebSocket = () => {
            const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            const host = window.location.hostname;
            const wsUrl = `${protocol}//${host}/ws`; // Add /ws path to match nginx config
            
            console.log('Attempting WebSocket connection to:', wsUrl);
            
            const ws = new WebSocket(wsUrl);
            wsRef.current = ws;

            let reconnectAttempts = 0;
            const maxReconnectAttempts = 5;
            
            ws.onopen = () => {
                console.log('WebSocket connection established');
                reconnectAttempts = 0; // Reset attempts on successful connection
                const userId = getUserId();
                
                if (userId) {
                    const identifyMessage = {
                        type: 'identify',
                        userId: userId
                    };
                    console.log('Sending identify message:', identifyMessage);
                    ws.send(JSON.stringify(identifyMessage));
                } else {
                    console.error('No user ID available for WebSocket identification');
                }
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    console.log('Received WebSocket message:', data);
                    
                    if (data.type === 'connected') {
                        console.log('Connection confirmed by server:', data);
                    } else if (data.type === 'fileProgress') {
                        setUploadProgress({
                            total: data.total,
                            processed: data.processed,
                            currentFile: data.currentFile,
                            status: data.status
                        });
                        setIsUploading(true);
                    }
                } catch (error) {
                    console.error('Error processing WebSocket message:', error);
                }
            };

            ws.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
                
                if (getUserId() && reconnectAttempts < maxReconnectAttempts) {
                    const delay = Math.min(1000 * Math.pow(2, reconnectAttempts), 10000);
                    console.log(`Attempting to reconnect in ${delay/1000} seconds... (Attempt ${reconnectAttempts + 1}/${maxReconnectAttempts})`);
                    reconnectAttempts++;
                    setTimeout(connectWebSocket, delay);
                } else if (reconnectAttempts >= maxReconnectAttempts) {
                    console.log('Max reconnection attempts reached. Please refresh the page to try again.');
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        };

        // Only attempt to connect if we have a user ID
        const userId = getUserId();
        if (userId) {
            connectWebSocket();
        } else {
            console.warn('No user ID available, skipping WebSocket connection');
        }

        // Cleanup function
        return () => {
            if (wsRef.current) {
                console.log('Cleaning up WebSocket connection');
                wsRef.current.close();
            }
        };
    }, []); // Empty dependency array since we want this to run once

    const buttonText = isLoading 
        ? 'Processing...' 
        : editingCourseId 
            ? `Update ${courseType === 'oracle' ? 'Oracle' : 'Course'}`
            : `Add ${courseType === 'oracle' ? 'Oracle' : 'Course'}`;

    const formTitle = editingCourseId 
        ? `Edit ${courseType === 'oracle' ? 'Oracle' : 'Course'}`
        : `Add New ${courseType === 'oracle' ? 'Oracle' : 'Course'}`;

    const handleCancelEdit = () => {
        setEditingCourseId(null);
        setCourseName('');
        setCourseDescription('');
        setSelectedClients([]);
        setIsPublic(true);
        setSelectedUsers([]);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const indexOfLastCourse = page * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

    const renderClients = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((clientId) => (
                    <Chip 
                        key={clientId} 
                        label={clients.find(c => c.client_id === clientId)?.client_name || clientId} 
                    />
                ))}
            </Box>
        );
    };

    const renderSelectedUsers = (selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((userId) => {
                const user = availableUsers.find(u => u.user_id === userId);
                return user ? (
                    <Chip
                        key={userId}
                        label={`${user.first_name} ${user.last_name} (${user.email})`}
                        size="small"
                    />
                ) : null;
            })}
        </Box>
    );

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCourseImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleImageDelete = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}/image`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete image');

            setCourseImage(null);
            setImagePreview(null);
            setSuccess(true);
            setSuccessMessage('Course image deleted successfully!');
        } catch (error) {
            console.error('Error deleting image:', error);
            setError('Failed to delete course image');
        }
    };

    const handleCourseDocsChange = (event) => {
        const files = Array.from(event.target.files);
        const totalDocs = newCourseDocs.length + files.length;
        
        if (totalDocs > 30) {
            setError('Maximum 30 documents allowed per course. Please remove some documents before adding more.');
            return;
        }
        
        setNewCourseDocs(prev => [...prev, ...files]);
    };

    const handleCourseDocDelete = (docId) => {
        setDocsToKeep(prev => {
            const existingDoc = prev.find(doc => doc.id === docId);
            if (existingDoc) {
                return prev.map(doc => 
                    doc.id === docId ? { ...doc, keep: !doc.keep } : doc
                );
            } else {
                return [...prev, { id: docId, keep: false }];
            }
        });
    };

    const handleNewCourseDocDelete = (index) => {
        setNewCourseDocs(prev => prev.filter((_, i) => i !== index));
    };

    const handleOpenInstructionsDialog = () => {
        setTempInstructions(oracleInstructions);
        setOpenInstructionsDialog(true);
    };

    const handleCloseInstructionsDialog = () => {
        setOpenInstructionsDialog(false);
    };

    const handleSaveInstructions = () => {
        setOracleInstructions(tempInstructions);
        handleCloseInstructionsDialog();
    };

    // Add a helper component to show document count
    const DocumentCounter = ({ newDocs, existingDocs = [] }) => {
        const totalCount = newDocs.length + 
            (existingDocs.filter(doc => doc.keep !== false).length || 0);
        
        return (
            <Typography 
                variant="caption" 
                color={totalCount > 30 ? "error" : "text.secondary"}
                sx={{ display: 'block', mb: 1 }}
            >
                Documents: {totalCount}/30
            </Typography>
        );
    };

    return (
        <Box 
            sx={{
                backgroundColor: 'background.default',
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 4, mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        {formTitle}
                    </Typography>

                    <Box component="form" onSubmit={handleCourseSubmit} noValidate sx={{ mt: 1 }}>
                        {/* Course Type Selection - Moved to top */}
                        <FormControl component="fieldset" sx={{ mb: 2, width: '100%' }}>
                            <Box sx={{ 
                                display: 'flex', 
                                gap: 2,
                                '& .MuiButton-root': {
                                    flex: 1,
                                    py: 1.5
                                }
                            }}>
                                <Button
                                    variant={courseType === 'normal' ? 'contained' : 'outlined'}
                                    onClick={() => setCourseType('normal')}
                                    sx={{
                                        borderColor: courseType === 'normal' ? 'primary.main' : 'grey.500',
                                    }}
                                >
                                    Course
                                </Button>
                                <Button
                                    variant={courseType === 'oracle' ? 'contained' : 'outlined'}
                                    onClick={() => setCourseType('oracle')}
                                    sx={{
                                        borderColor: courseType === 'oracle' ? 'primary.main' : 'grey.500',
                                    }}
                                >
                                    Oracle
                                </Button>
                            </Box>
                        </FormControl>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseName"
                            label={`${courseType === 'oracle' ? 'Oracle' : 'Course'} Name`}
                            name="courseName"
                            autoFocus
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseDescription"
                            label={`${courseType === 'oracle' ? 'Oracle' : 'Course'} Description`}
                            name="courseDescription"
                            multiline
                            rows={4}
                            value={courseDescription}
                            onChange={(e) => setCourseDescription(e.target.value)}
                        />

                        {/* Client Access - Only visible to superadmins */}
                        {isSuperAdmin && (
                            <FormControl sx={{ mt: 2, width: '100%'}}>
                                <Autocomplete
                                    multiple
                                    options={clients}
                                    getOptionLabel={(option) => option.client_name}
                                    value={clients.filter(client => selectedClients.includes(client.client_id))}
                                    onChange={(event, newValue) => {
                                        setSelectedClients(newValue.map(client => client.client_id));
                                    }}
                                    isOptionEqualToValue={(option, value) => option.client_id === value.client_id}
                                    filterOptions={(options, { inputValue }) => {
                                        return options.filter(option =>
                                            option.client_name.toLowerCase().includes(inputValue.toLowerCase())
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Client Access"
                                            placeholder="Search clients..."
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.client_id}>
                                            {option.client_name}
                                        </li>
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                label={option.client_name}
                                                {...getTagProps({ index })}
                                                key={option.client_id}
                                            />
                                        ))
                                    }
                                    sx={{
                                        '& .MuiAutocomplete-tag': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                            color: 'text.primary',
                                        }
                                    }}
                                    ListboxProps={{
                                        style: {
                                            maxHeight: '250px',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor: '#6b6b6b #2b2b2b'
                                        }
                                    }}
                                    PaperProps={{
                                        sx: {
                                            bgcolor: 'background.paper',
                                            color: 'text.primary',
                                            '& .MuiAutocomplete-option': {
                                                '&:hover': {
                                                    bgcolor: 'red'
                                                },
                                                '&[aria-selected="true"]': {
                                                    bgcolor: 'red',
                                                    '&:hover': {
                                                        bgcolor: 'red'
                                                    }
                                                },
                                                '&.Mui-focused': {
                                                    bgcolor: 'red',
                                                    '&:hover': {
                                                        bgcolor: 'red'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                />
                            </FormControl>
                        )}

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>{courseType === 'oracle' ? 'Oracle Access Type' : 'Course Access Type'}</InputLabel>
                            <Select
                                value={isPublic}
                                onChange={(e) => {
                                    setIsPublic(e.target.value);
                                    if (e.target.value) {
                                        setSelectedUsers([]); // Clear selected users when switching to public
                                    }
                                }}
                                label={courseType === 'oracle' ? 'Oracle Access Type' : 'Course Access Type'}
                            >
                                <MenuItem value={true}>Public</MenuItem>
                                <MenuItem value={false}>Private</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <Autocomplete
                                multiple
                                options={availableUsers || []}
                                getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`}
                                value={availableUsers.filter(user => selectedUsers.includes(user.user_id)) || []}
                                onChange={(event, newValue) => {
                                    setSelectedUsers(newValue.map(user => user.user_id));
                                }}
                                isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                                filterOptions={(options, { inputValue }) => {
                                    return options.filter(option =>
                                        `${option.first_name} ${option.last_name} ${option.email}`
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Assign Users"
                                        placeholder="Search users..."
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.user_id}>
                                        {`${option.first_name} ${option.last_name} (${option.email})`}
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            label={`${option.first_name} ${option.last_name} (${option.email})`}
                                            {...getTagProps({ index })}
                                            key={option.user_id}
                                        />
                                    ))
                                }
                                sx={{
                                    '& .MuiAutocomplete-tag': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                        color: 'text.primary',
                                    }
                                }}
                                ListboxProps={{
                                    style: {
                                        maxHeight: '250px',
                                        scrollbarWidth: 'thin',
                                        scrollbarColor: '#6b6b6b #2b2b2b'
                                    }
                                }}
                            />
                        </FormControl>

                        {courseType === 'oracle' && (
                            <>
                                <Box sx={{ position: 'relative' }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="oracleInstructions"
                                        name="oracleInstructions"
                                        multiline
                                        rows={6}
                                        value={oracleInstructions}
                                        onChange={(e) => setOracleInstructions(e.target.value)}
                                        helperText="Instructions for the Oracle AI assistant (required)"
                                        error={false}
                                        placeholder={`Example instructions:

You are an AI assistant specialized in cooking and culinary arts. Your role is to:
1. Guide people through cooking techniques and recipes
2. Explain kitchen safety and food handling
3. Share tips for ingredient substitutions
4. Help troubleshoot common cooking problems
5. Provide meal planning and preparation advice`}
                                        sx={{ 
                                            mt: 2,
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'rgba(255, 255, 255, 0.23)'
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'rgba(255, 255, 255, 0.23)'
                                                }
                                            }
                                        }}
                                    />
                                    <IconButton
                                        onClick={handleOpenInstructionsDialog}
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 25,
                                            bgcolor: 'background.paper',
                                            boxShadow: 1,
                                            padding: '4px',
                                            '&:hover': {
                                                bgcolor: 'action.hover',
                                            },
                                        }}
                                    >
                                        <OpenInFullIcon fontSize="small" />
                                    </IconButton>
                                </Box>

                                {/* Instructions Dialog */}
                                <Dialog
                                    open={openInstructionsDialog}
                                    onClose={handleCloseInstructionsDialog}
                                    maxWidth="md"
                                    fullWidth
                                    PaperProps={{
                                        sx: {
                                            height: isMobile ? '100vh' : '80vh',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            position: 'fixed',
                                            left: isMobile ? '50%' : openNavBar ? 'calc(50% + 120px)' : 'calc(50%)',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            margin: '0 !important',
                                            width: '100%',
                                            maxWidth: isMobile ? '100%' : '900px',
                                            borderRadius: isMobile ? 0 : 1,
                                            ...(isMobile && {
                                                m: 0,
                                                borderRadius: 0,
                                                height: '100vh',
                                                maxHeight: '100vh',
                                            })
                                        }
                                    }}
                                    sx={{
                                        '& .MuiDialog-container': {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        },
                                        '& .MuiDialog-paper': {
                                            m: isMobile ? 0 : 2,
                                            maxHeight: isMobile ? '100vh' : '80vh'
                                        },
                                        zIndex: 10000
                                    }}
                                >
                                    <DialogTitle sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        pb: 1,
                                        pt: isMobile ? 2 : 1,
                                        px: isMobile ? 3 : 3,
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                                        ...(isMobile && {
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: theme.palette.background.paper,
                                            zIndex: 1,
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                        })
                                    }}>
                                        <Typography variant="h6" sx={{ 
                                            fontSize: isMobile ? '1.1rem' : '1.25rem',
                                            fontWeight: 500
                                        }}>
                                            Edit Oracle AI Instructions
                                        </Typography>
                                        <IconButton
                                            onClick={handleCloseInstructionsDialog}
                                            size="small"
                                            sx={{ 
                                                ml: 1,
                                                ...(isMobile && {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 0.12)'
                                                    }
                                                })
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent sx={{ 
                                        flex: 1,
                                        p: isMobile ? 3 : 3,
                                        overflowY: 'auto',
                                        ...(isMobile && {
                                            pt: 3,
                                            pb: 2,
                                            mt: 1
                                        })
                                    }}>
                                        <TextField
                                            autoFocus
                                            multiline
                                            fullWidth
                                            rows={isMobile ? 18 : 20}
                                            value={tempInstructions}
                                            onChange={(e) => setTempInstructions(e.target.value)}
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.background.paper,
                                                    borderRadius: isMobile ? 1 : undefined,
                                                    fontSize: isMobile ? '0.95rem' : undefined
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(255, 255, 255, 0.23)'
                                                },
                                                ...(isMobile && {
                                                    mt: 1
                                                })
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions sx={{ 
                                        p: isMobile ? 3 : 3,
                                        pt: isMobile ? 2 : 2,
                                        borderTop: '1px solid rgba(255, 255, 255, 0.12)',
                                        ...(isMobile && {
                                            position: 'sticky',
                                            bottom: 0,
                                            backgroundColor: theme.palette.background.paper,
                                            boxShadow: '0 -2px 4px rgba(0,0,0,0.1)',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        })
                                    }}>
                                        <Button 
                                            onClick={handleCloseInstructionsDialog}
                                            sx={{ 
                                                mr: 1,
                                                ...(isMobile && {
                                                    flex: 1,
                                                    py: 1.2
                                                })
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button 
                                            onClick={handleSaveInstructions}
                                            variant="contained"
                                            sx={{
                                                ...(isMobile && {
                                                    flex: 1,
                                                    py: 1.2
                                                })
                                            }}
                                        >
                                            Save Changes
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                {/* Course Documentation - Moved below Oracle Instructions */}
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {courseType === 'oracle' ? 'Oracle Documentation' : 'Course Documentation'} <span style={{ color: 'error.main' }}>*</span>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                        Add reference materials and documentation for this {courseType === 'oracle' ? 'Oracle' : 'Course'} (required).
                                    </Typography>
                                    
                                    {/* Add document counter */}
                                    <DocumentCounter 
                                        newDocs={newCourseDocs} 
                                        existingDocs={docsToKeep}
                                    />

                                    {/* Existing Documents (shown during edit) */}
                                    {courseDocs.length > 0 && (
                                        <Box sx={{ mb: 2 }}>
                                            {courseDocs.map((doc) => {
                                                const isKept = docsToKeep.find(d => d.id === doc.id)?.keep !== false;
                                                return (
                                                    <Box 
                                                        key={doc.id}
                                                        sx={{ 
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            mb: 1,
                                                            opacity: isKept ? 1 : 0.5
                                                        }}
                                                    >
                                                        <Typography variant="body2" sx={{ flex: 1 }}>
                                                            {doc.document_name}
                                                        </Typography>
                                                        <IconButton 
                                                            size="small"
                                                            onClick={() => handleCourseDocDelete(doc.id)}
                                                            color={isKept ? "error" : "primary"}
                                                        >
                                                            {isKept ? <DeleteIcon /> : <UndoIcon />}
                                                        </IconButton>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    )}

                                    {/* New Documents */}
                                    {newCourseDocs.length > 0 && (
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                New Documents:
                                            </Typography>
                                            {newCourseDocs.map((file, index) => (
                                                <Box 
                                                    key={index}
                                                    sx={{ 
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        mb: 1
                                                    }}
                                                >
                                                    <Typography variant="body2" sx={{ flex: 1 }}>
                                                        {file.name}
                                                    </Typography>
                                                    <IconButton 
                                                        size="small"
                                                        onClick={() => handleNewCourseDocDelete(index)}
                                                        color="error"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}

                                    {/* Upload Button */}
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        startIcon={<CloudUploadIcon />}
                                        fullWidth
                                        sx={{ mb: 2 }}
                                    >
                                        Add {courseType === 'oracle' ? 'Oracle' : 'Course'} Documentation
                                        <input
                                            type="file"
                                            hidden
                                            multiple
                                            accept=".pdf,.txt,.doc,.docx,.csv"
                                            onChange={handleCourseDocsChange}
                                        />
                                    </Button>
                                </Box>
                            </>
                        )}

                        {/* Course Image section - Moved below documentation */}
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="course-image-upload"
                                type="file"
                                onChange={handleImageChange}
                            />
                            <label htmlFor="course-image-upload">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                    fullWidth
                                >
                                    Upload {courseType === 'oracle' ? 'Oracle' : 'Course'} Image
                                </Button>
                            </label>
                            {imagePreview && (
                                <Box sx={{ mt: 2, position: 'relative' }}>
                                    <img
                                        src={imagePreview}
                                        alt="Course preview"
                                        style={{
                                            width: '100%',
                                            maxHeight: '200px',
                                            objectFit: 'cover',
                                            borderRadius: '4px'
                                        }}
                                    />
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                            },
                                        }}
                                        onClick={() => {
                                            if (editingCourseId) {
                                                handleImageDelete(editingCourseId);
                                            } else {
                                                setCourseImage(null);
                                                setImagePreview(null);
                                            }
                                        }}
                                    >
                                        <DeleteIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>

                        {isUploading && uploadProgress.total > 0 && (
                            <Box sx={{ width: '100%', mt: 2 }}>
                                <Typography variant="body2">
                                    Processing files: {uploadProgress.processed} / {uploadProgress.total}
                                </Typography>
                                <Typography variant="caption" display="block">
                                    Current file: {uploadProgress.currentFile}
                                </Typography>
                                <Typography variant="caption" display="block">
                                    Status: {uploadProgress.status}
                                </Typography>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={(uploadProgress.processed / uploadProgress.total) * 100}
                                    sx={{ mt: 1 }}
                                />
                            </Box>
                        )}

                        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                            >
                                {buttonText}
                            </Button>
                            
                            {editingCourseId && (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={handleCancelEdit}
                                    disabled={isLoading}
                                >
                                    Cancel Edit
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Paper>

                {/* Commenting out Existing Courses section
                <Paper sx={{ p: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Existing Courses
                    </Typography>
                    {currentCourses.map(course => (
                        <Box 
                            key={course.id} 
                            sx={{ 
                                mb: 3, 
                                p: 3, 
                                border: '1px solid #ddd', 
                                borderRadius: 2,
                                backgroundColor: (theme) => theme.palette.background.paper,
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                <Typography variant="h6">
                                    {course.course_name}
                                </Typography>
                                <Chip 
                                    label={course.is_public ? "Public" : "Private"}
                                    size="small"
                                    color={course.is_public ? "success" : "default"}
                                />
                            </Box>
                            <Typography 
                                variant="body1" 
                                color="text.secondary" 
                                sx={{ mb: 2 }}
                            >
                                {course.course_description}
                            </Typography>
                            
                            {!course.is_public && course.assigned_users?.length > 0 && (
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Assigned Users:
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        {course.assigned_users.map((user) => (
                                            <Chip
                                                key={user.user_id}
                                                label={`${user.first_name} ${user.last_name} (${user.email})`}
                                                size="small"
                                                sx={{ maxWidth: '100%' }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            )}
                            
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button 
                                    onClick={() => handleEdit(course)} 
                                    variant="outlined" 
                                    startIcon={<EditIcon />}
                                    disabled={isLoading}
                                >
                                    Edit
                                </Button>
                                <Button 
                                    onClick={() => handleDelete(course.id)} 
                                    variant="outlined" 
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    disabled={isLoading}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    ))}
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination 
                            count={Math.ceil(courses.length / coursesPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Paper>
                */}
            </Container>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={openVideoDialog}
                onClose={() => handleVideoDialogResponse(false)}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: 400,
                        bgcolor: 'background.paper',
                    }
                }}
            >
                <DialogTitle>Add {courseType === 'oracle' ? 'Oracle' : 'Course'} Videos</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Would you like to add videos to this {courseType === 'oracle' ? 'oracle' : 'course'} now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2, gap: 1 }}>
                    <Button 
                        onClick={() => handleVideoDialogResponse(false)}
                        variant="outlined"
                        color="primary"
                    >
                        Skip for Now
                    </Button>
                    <Button 
                        onClick={() => handleVideoDialogResponse(true)}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Add Videos
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default LMSAddCourse;
