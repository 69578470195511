import React, { useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography'; 
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { getUserId, getRoleId, getClientId , getFarmId, isSuperAdmin, isCompanyAdmin} from '../../auth/auth.js';
import MenuBookIcon from '@mui/icons-material/MenuBook';  // or any other icon you prefer
import { theme } from '../../theme';  // Import the custom theme
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Box from '@mui/material/Box';
import HiveIcon from '@mui/icons-material/Hive';
import FeedIcon from '@mui/icons-material/Feed';
import StorageIcon from '@mui/icons-material/Storage';
import ChatIcon from '@mui/icons-material/Chat';

export const LmsListItems = ({ navBarOpen, position, onMobileItemClick }) => {
  const [userId, setUserId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdminUser, setIsSuperAdminUser] = useState(false);
  const allowedUserIds = [1, 2, 72, 88];
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserId = async () => {
      const currentUserId = await getUserId();
      console.log('Current User ID:', currentUserId);
      setUserId(currentUserId);
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    if(isSuperAdmin(getRoleId()) || isCompanyAdmin(getRoleId())) {
      setIsAdmin(true);
    }
    // Separate check for superAdmin
    if(isSuperAdmin(getRoleId())) {
      setIsSuperAdminUser(true);
    }
  }, []);

  const isUserAllowed = allowedUserIds.some(id => 
    String(id) === String(userId)
  );

  return (
    <React.Fragment>
      {position === "top" && (
        <>
          <ListItemButton 
            component={Link} 
            to="/"
            selected={location.pathname === '/'}
            onClick={onMobileItemClick}
            sx={{
              ...commonListItemStyles,
              color: '#fff',
              mt: "15px !important",
              pl: 1.5,
              pr: 2,
              py: 1,
              height: '56px',
            }}
          >
            <ListItemIcon 
              sx={{ 
                color: 'inherit',
                minWidth: 36,
              }}
            >
              <AutoStoriesIcon />
            </ListItemIcon>
            <Box sx={{ overflow: 'hidden' }}>
              <ListItemText 
                primary="SAGE"
                secondary="Smart AI Guided Education"
                primaryTypographyProps={{
                  fontSize: '0.875rem',
                  fontWeight: location.pathname === '/' ? 600 : 400,
                  whiteSpace: 'nowrap',
                  mb: 0,
                }}
                secondaryTypographyProps={{
                  fontSize: '0.75rem',
                  color: 'rgba(255, 255, 255, 0.7)',
                  display: navBarOpen ? 'block' : 'none',
                  whiteSpace: 'nowrap',
                }}
              />
            </Box>
          </ListItemButton>

          {/* {isAdmin && (
            <ListItemButton 
              component={Link} 
              to="/chatdb"
              selected={location.pathname === '/chatdb'}
              onClick={onMobileItemClick}
              sx={{
                ...commonListItemStyles,
                color: '#fff',
                mt: "8px !important",
                pl: 1.5,
                pr: 2,
                py: 1,
                height: '56px',
              }}
            >
              <ListItemIcon 
                sx={{ 
                  color: 'inherit',
                  minWidth: 36,
                }}
              >
                <StorageIcon />
              </ListItemIcon>
              <Box sx={{ overflow: 'hidden' }}>
                <ListItemText 
                  primary="HIVE Agents"
                  secondary="AI Agent For Data Sources"
                  primaryTypographyProps={{
                    fontSize: '0.875rem',
                    fontWeight: location.pathname === '/chatdb' ? 600 : 400,
                    whiteSpace: 'nowrap',
                    mb: 0,
                  }}
                  secondaryTypographyProps={{
                    fontSize: '0.75rem',
                    color: 'rgba(255, 255, 255, 0.7)',
                    display: navBarOpen ? 'block' : 'none',
                    whiteSpace: 'nowrap',
                  }}
                />
              </Box>
            </ListItemButton>
          )} */}

          <ListItemButton 
            component={Link} 
            to="/feed"
            selected={location.pathname === '/feed'}
            onClick={onMobileItemClick}
            sx={{
              ...commonListItemStyles,
              color: '#fff',
              mt: "8px !important",
              pl: 1.5,
              pr: 2,
              py: 1,
              height: '56px',
            }}
          >
            <ListItemIcon 
              sx={{ 
                color: 'inherit',
                minWidth: 36,
              }}
            >
              <FeedIcon />
            </ListItemIcon>
            <Box sx={{ overflow: 'hidden' }}>
              <ListItemText 
                primary="Feed"
                secondary="Community Updates"
                primaryTypographyProps={{
                  fontSize: '0.875rem',
                  fontWeight: location.pathname === '/feed' ? 600 : 400,
                  whiteSpace: 'nowrap',
                  mb: 0,
                }}
                secondaryTypographyProps={{
                  fontSize: '0.75rem',
                  color: 'rgba(255, 255, 255, 0.7)',
                  display: navBarOpen ? 'block' : 'none',
                  whiteSpace: 'nowrap',
                }}
              />
            </Box>
          </ListItemButton>

          <ListItemButton 
            selected={location.pathname === '/oracle-chat-config'}
            onClick={() => {
              navigate('/oracle-chat-config');
              onMobileItemClick();
            }}
            sx={{
              ...commonListItemStyles,
              color: '#fff',
              mt: "8px !important",
              pl: 1.5,
              pr: 2,
              py: 1,
              height: '56px',
            }}
          >
            <ListItemIcon 
              sx={{ 
                color: 'inherit',
                minWidth: 36,
              }}
            >
              <ChatIcon />
            </ListItemIcon>
            <Box sx={{ overflow: 'hidden' }}>
              <ListItemText 
                primary="Oracle Chat Config"
                secondary="Configure Chat Settings"
                primaryTypographyProps={{
                  fontSize: '0.875rem',
                  fontWeight: location.pathname === '/oracle-chat-config' ? 600 : 400,
                  whiteSpace: 'nowrap',
                  mb: 0,
                }}
                secondaryTypographyProps={{
                  fontSize: '0.75rem',
                  color: 'rgba(255, 255, 255, 0.7)',
                  display: navBarOpen ? 'block' : 'none',
                  whiteSpace: 'nowrap',
                }}
              />
            </Box>
          </ListItemButton>
        </>
      )}
    </React.Fragment>
  );
};

export const manageListItems = (
  <React.Fragment>

    <ListSubheader component="div" inset>
      Manage
    </ListSubheader>

    <ListItemButton component={Link} to="/manageAdmins">
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Admins" />
    </ListItemButton>

    <ListItemButton component={Link} to="/manageStudents">
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary="Students" />
    </ListItemButton>
    
  </React.Fragment>
);

export const ManageListItems = ({ handleClick, openManage, navBarOpen, onMobileItemClick }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(isSuperAdmin(getRoleId()) || isCompanyAdmin(getRoleId())) {
      setIsAdmin(true);
    }
  }, [location]);

  if (!isAdmin) return null;

  return (
    <React.Fragment>
      <Collapse in={openManage} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton 
            component={Link} 
            to="/lmsaddcourse"
            selected={location.pathname === '/lmsaddcourse'}
            onClick={onMobileItemClick}
            sx={{ 
              pl: 2.7,
              color: 'gray',
              '&.Mui-selected': {
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.16)',
                },
              },
              ...commonListItemStyles,
            }}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              <AddIcon />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">New Course / Oracle</Typography>}
            />
          </ListItemButton>

          <ListItemButton 
            component={Link} 
            to="/courses" 
            selected={location.pathname === '/courses'}
            onClick={onMobileItemClick}
            sx={{ 
              pl: 2.7,
              color: 'gray',
              '&.Mui-selected': {
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.16)',
                },
              },
              ...commonListItemStyles,
            }}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">Manage Courses & Oracle</Typography>}
            />
          </ListItemButton>
          
          <ListItemButton 
            component={Link} 
            to="/manageadmins" 
            selected={location.pathname === '/manageadmins'}
            onClick={onMobileItemClick}
            sx={{ 
              pl: 2.7,
              color: 'gray',
              '&.Mui-selected': {
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.16)',
                },
              },
              ...commonListItemStyles,
            }}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">Users</Typography>}
            />
          </ListItemButton>

        </List>
      </Collapse>
    </React.Fragment>
  );
};

const commonListItemStyles = {
  borderRadius: '8px',
  margin: '4px 8px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
    },
  },
};
