import React, { useState } from 'react';
import { 
    Box, 
    Typography, 
    Button, 
    CircularProgress,
    Stepper,
    Step,
    StepLabel,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Alert
} from '@mui/material';
import AssistantIcon from '@mui/icons-material/Assistant';
import { getToken } from '../auth/auth.js';
import { BASE_URL } from '../Constants';

const CreateAssistant = ({ onAssistantCreated }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedDatabases, setSelectedDatabases] = useState([]);
    const [dbConfigs, setDbConfigs] = useState({});
    const [availableTables, setAvailableTables] = useState({});
    const [selectedTables, setSelectedTables] = useState({});
    const [loadingMessage, setLoadingMessage] = useState('');
    
    const token = getToken();

    const steps = ['Select Databases', 'Configure Connections', 'Select Tables', 'Create Assistant'];

    const dbFields = {
        snowflake: [
            { name: 'account', label: 'Account', required: true },
            { name: 'username', label: 'Username', required: true },
            { name: 'password', label: 'Password', required: true, type: 'password' },
            { name: 'database', label: 'Database', required: true },
            { name: 'schema', label: 'Schema', required: true },
            { name: 'warehouse', label: 'Warehouse', required: true }
        ],
        mysql: [
            { name: 'host', label: 'Host', required: true },
            { name: 'username', label: 'Username', required: true },
            { name: 'password', label: 'Password', required: true, type: 'password' },
            { name: 'database', label: 'Database', required: true }
        ],
        googlesheets: [
            { name: 'spreadsheetUrl', label: 'Google Sheets URL', required: true, 
              helperText: 'Paste the full Google Sheets URL (e.g., https://docs.google.com/spreadsheets/d/1wcNCNt1Us9vQlmWadqFVu1O6m-duF-ukkRJyd8eqKpw/edit)' }
        ]
    };

    const handleDatabaseSelection = (event) => {
        const selected = event.target.value;
        setSelectedDatabases(selected);
        // Reset configs for removed databases
        setDbConfigs(prev => {
            const newConfigs = {};
            selected.forEach(db => {
                if (prev[db]) newConfigs[db] = prev[db];
                else newConfigs[db] = {};
            });
            return newConfigs;
        });
    };

    const handleConfigChange = (dbType, field, value) => {
        // For Google Sheets, extract the spreadsheet ID from the URL
        if (dbType === 'googlesheets' && field === 'spreadsheetUrl') {
            const spreadsheetId = extractSpreadsheetId(value);
            setDbConfigs(prev => ({
                ...prev,
                [dbType]: { 
                    ...(prev[dbType] || {}), 
                    [field]: value,
                    spreadsheetId: spreadsheetId
                }
            }));
        } else {
            setDbConfigs(prev => ({
                ...prev,
                [dbType]: { ...(prev[dbType] || {}), [field]: value }
            }));
        }
    };

    // Function to extract spreadsheet ID from URL
    const extractSpreadsheetId = (url) => {
        try {
            // Extract ID from URL patterns like:
            // https://docs.google.com/spreadsheets/d/SPREADSHEET_ID/edit
            // https://docs.google.com/spreadsheets/d/SPREADSHEET_ID/edit?usp=sharing
            const regex = /\/d\/([a-zA-Z0-9-_]+)/;
            const match = url.match(regex);
            return match ? match[1] : '';
        } catch (error) {
            console.error('Error extracting spreadsheet ID:', error);
            return '';
        }
    };

    const handleTableSelection = (dbType, tableName) => {
        setSelectedTables(prev => ({
            ...prev,
            [dbType]: prev[dbType] 
                ? prev[dbType].includes(tableName)
                    ? prev[dbType].filter(t => t !== tableName)
                    : [...prev[dbType], tableName]
                : [tableName]
        }));
    };

    const testConnections = async () => {
        setIsLoading(true);
        setError('');
        
        try {
            const results = await Promise.all(
                selectedDatabases.map(async dbType => {
                    try {
                        setLoadingMessage(`Testing connection to ${dbType.toUpperCase()}...`);
                        console.log(`Testing connection for ${dbType}...`);
                        
                        const response = await fetch(`${BASE_URL}/api/assistant/test-connection`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                dbType,
                                config: dbConfigs[dbType]
                            })
                        });

                        const data = await response.json();
                        
                        if (!response.ok) {
                            throw new Error(data.details || `Failed to connect to ${dbType} database`);
                        }

                        console.log(`Successfully connected to ${dbType}, found ${data.tables ? data.tables.length : data.sheets.length} items`);
                        setLoadingMessage(`Successfully connected to ${dbType.toUpperCase()}`);
                        
                        // Handle different response formats for different database types
                        if (dbType === 'googlesheets') {
                            return { dbType, tables: data.sheets.map(sheet => sheet.title) };
                        } else {
                            return { dbType, tables: data.tables };
                        }
                    } catch (error) {
                        throw new Error(`${dbType}: ${error.message}`);
                    }
                })
            );

            const newAvailableTables = {};
            results.forEach(({ dbType, tables }) => {
                newAvailableTables[dbType] = tables;
            });
            setAvailableTables(newAvailableTables);
            setActiveStep(2);
            setLoadingMessage('');
        } catch (error) {
            console.error('Connection test failed:', error);
            setLoadingMessage('');
            
            // Show more user-friendly error message
            const errorMessage = error.message.includes('Access denied') 
                ? 'Invalid username or password'
                : error.message.includes('ECONNREFUSED')
                    ? 'Could not reach database server. Please check the host and ensure the server is running.'
                    : error.message;
                    
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const createAssistant = async () => {
        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(`${BASE_URL}/api/assistant`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    databases: selectedDatabases.map(dbType => ({
                        type: dbType,
                        config: dbConfigs[dbType],
                        selectedTables: selectedTables[dbType] || []
                    }))
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create assistant');
            }

            onAssistantCreated();
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const renderStepContent = () => {
        return (
            <>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                {activeStep === 0 && (
                    <FormControl fullWidth>
                        <InputLabel>Database Types</InputLabel>
                        <Select
                            multiple
                            value={selectedDatabases}
                            onChange={handleDatabaseSelection}
                            label="Database Types"
                        >
                            <MenuItem value="snowflake">Snowflake</MenuItem>
                            <MenuItem value="mysql">MySQL</MenuItem>
                            <MenuItem value="googlesheets">Google Sheets</MenuItem>
                        </Select>
                    </FormControl>
                )}
                {activeStep === 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        {selectedDatabases.map(dbType => (
                            <Box key={dbType} sx={{ mb: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {dbType === 'googlesheets' ? 'Google Sheets' : dbType.toUpperCase()} Configuration
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    {dbFields[dbType].map(field => (
                                        <TextField
                                            key={field.name}
                                            label={field.label}
                                            type={field.type || 'text'}
                                            required={field.required}
                                            value={dbConfigs[dbType]?.[field.name] || ''}
                                            onChange={(e) => handleConfigChange(dbType, field.name, e.target.value)}
                                            multiline={field.type === 'textarea'}
                                            rows={field.type === 'textarea' ? 4 : 1}
                                            helperText={field.helperText}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}
                {activeStep === 2 && (
                    <Box>
                        {selectedDatabases.map(dbType => (
                            <Box key={dbType} sx={{ mb: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {dbType.toUpperCase()} Tables
                                </Typography>
                                <FormGroup>
                                    {availableTables[dbType]?.map(table => (
                                        <FormControlLabel
                                            key={table}
                                            control={
                                                <Checkbox
                                                    checked={selectedTables[dbType]?.includes(table) || false}
                                                    onChange={() => handleTableSelection(dbType, table)}
                                                />
                                            }
                                            label={table}
                                        />
                                    ))}
                                </FormGroup>
                            </Box>
                        ))}
                    </Box>
                )}
            </>
        );
    };

    const handleNext = () => {
        if (activeStep === 1) {
            testConnections();
        } else if (activeStep === 2) {
            createAssistant();
        } else {
            setActiveStep(prev => prev + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
    };

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box sx={{ mt: 4, mb: 2 }}>
                {renderStepContent()}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    disabled={activeStep === 0 || isLoading}
                    onClick={handleBack}
                >
                    Back
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {loadingMessage && (
                        <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                            {loadingMessage}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={isLoading || 
                            (activeStep === 0 && selectedDatabases.length === 0) ||
                            (activeStep === 1 && selectedDatabases.every(db => Object.keys(dbConfigs[db]).length === 0)) ||
                            (activeStep === 2 && selectedDatabases.every(db => selectedTables[db]?.length === 0))
                        }
                    >
                        {isLoading ? <CircularProgress size={24} /> : 
                            activeStep === steps.length - 1 ? 'Create' : 'Next'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CreateAssistant; 