// REACT IMPORTS
import React, { useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material';

// AUTH IMPORTS
// import SignUp from './auth/SignUp';
import LogIn from './auth/LogIn';
//import UpdateFarm from './auth/UpdateFarm';
import ProtectedRoute from './auth/ProtectedRoute'; 

// NAV BAR IMPORTS
import { NavBarProvider, NavBarContext } from './components/navigation/NavBarContext';
import NavBar from './components/navigation/NavBar';

// MAIN PAGE IMPORTS
import UserProfile from './components/user/UserProfile';
// import ShipmentInput from './components/shipments/ShipmentInput';
// import ShipmentView from './components/shipments/ShipmentView';
// import ShipmentEdit from './components/shipments/ShipmentEdit';
// import ReceivedInput from './components/received/ReceivedInput';
// import ReceivedView from './components/received/ReceivedView';
// import ReceivedEdit from './components/received/ReceivedEdit';
// import CutInput from './components/cut/CutInput';
// import CutView from './components/cut/CutView';
// import CutEdit from './components/cut/CutEdit';
// import YieldInput from './components/yield/YieldInput'; 
// import YieldView from './components/yield/YieldView';
// import YieldEdit from './components/yield/YieldEdit';
// import UserProfile from './components/user/UserProfile';
// import UserEdit from './components/user/UserEdit';
// import TossedView from './components/tossed/TossedView';
// import SDWInput from './components/sdw/SDWInput';
// import SDWView from './components/sdw/SDWView';
// import LMSNewCourseForm from './components/lms/LMSAddCourse';


// MANAGE PAGE IMPORTS 
import ManageAdmins from './components/manage/ManageAdmins';
import AddUser from './components/user/AddUser';
import ManageRegions from './components/manage/ManageRegions';
import ManageFarms from './components/manage/ManageFarms';
import ManageStrains from './components/manage/ManageStrains';
import ManageSources from './components/manage/ManageSources';
import ManageStudents from './components/manage/ManageStudents';

// // ORDER IMPORTS
// import BlockOrders from './components/orders/BlockOrders';
// import OrderList from './components/orders/OrderList';
// import InoculationSchedule from './components/orders/InoculationSchedule';

// CUSTOMER IMPORTS
import CustomerDetails from './components/customers/CustomerDetails';
import NewCustomerOrder from './components/customers/NewCustomerOrder';
import CustomerOrderList from './components/customers/CustomerOrderList';
import OrdersWeekly from './components/customers/OrdersWeekly';

// FARM DASHBOARD IMPORTS
// import FarmDashboard from './components/farms/FarmDashboard';

// AI IMPORTS
// import ChatAI from './components/ai-chat/ChatAI';

//LMS IMPORTS
import LMSHome from './components/lms/LMSHome';
import LMSVideoActivityLog from './components/lms/LMSVideoActivityLog';
import LMSNewCourse from './components/lms/lmsAddVideos';
import LMSAddCourse from './components/lms/LMSAddCourse';
import CourseList from './pages/CourseList';
import VideoPlayer from './pages/VideoPlayer';
import CourseDetails from './pages/CourseDetails';
import { theme }  from './theme.js';
import LMSCourseList from './components/lms/LMSCourseList';

// Add import for PublicChat
import PublicChat from './pages/PublicChat';
// Add import for Oracle Chat Config
import OracleChatConfig from './pages/OracleChatConfig';

//PRICING IMPORTS
import Pricing from './components/Pricing';

import LMSManageVideos from './components/lms/lmsManageVideos';
import LMSAddVideos from './components/lms/lmsAddVideos';

//const mdTheme = createTheme();

import EmailLogin from './auth/EmailLogin';

//FEED IMPORTS
import Feed from './components/feed/Feed';

import ChatDB from './components/ChatDB';
// Create ChatContext
export const ChatContext = createContext();


function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const currentTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <ChatContext.Provider value={{ isChatOpen, setIsChatOpen }}>
        <Box sx={{ 
          display: 'flex', 
          backgroundColor: currentTheme.palette.background.default,
          color: currentTheme.palette.text.primary,
          minHeight: '100vh'
        }}>
          <NavBarProvider defaultOpen={true}>
            <Router>
              <NavigationWrapper />
            </Router>
          </NavBarProvider>
        </Box>
      </ChatContext.Provider>
      <ToastContainer position="top-center"/>
    </ThemeProvider>
  );
}

function NavigationWrapper() {
  const location = useLocation();
  const { toggleNavBar, openNavBar } = React.useContext(NavBarContext);
  const { isChatOpen } = useContext(ChatContext);
  const noNavRoutes = ["/login", "/signup", "/public-chat"]; 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {!noNavRoutes.includes(location.pathname) && (
        <>
          {!openNavBar && !isChatOpen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleNavBar}
              edge="start"
              sx={{
                position: isMobile ? 'absolute' : 'fixed',
                left: '20px',
                top: '20px',
                zIndex: 9999,
                backgroundColor: '#2D2D2D',
                color: '#fff',
                width: 38,
                height: 38,
                borderRadius: '8px',
                padding: '12px',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.25)',
                '& .MuiSvgIcon-root': {
                  fontSize: '24px',
                }
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <NavBarContainer />
        </>
      )}

      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          width: isMobile ? '100%' : 'auto',
          position: 'relative',
        }}
      >
        <Routes>
          <Route path="/login" element={<LogIn />} />
          {/* <Route path="/signup" element={<SignUp /> } /> */}
          {/* <Route path="/selectfarm" element={<UpdateFarm />} /> */}
          {/* <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} /> */}
          <Route path="/userprofile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
          {/* <Route path="/useredit/:id" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} /> */}
          {/* <Route path="/addshipment" element={<ProtectedRoute><ShipmentInput /></ProtectedRoute>} /> */}
          {/* <Route path="/shipmentview" element={<ProtectedRoute><ShipmentView /></ProtectedRoute>} /> */}
          {/* <Route path="/shipmentedit/:id" element={<ProtectedRoute><ShipmentEdit /></ProtectedRoute>} /> */}
          {/* <Route path="/addreceived" element={<ProtectedRoute><ReceivedInput /></ProtectedRoute>} /> */}
          {/* <Route path="/receivedview" element={<ProtectedRoute><ReceivedView /></ProtectedRoute>} /> */}
          {/* <Route path="/receivededit/:id" element={<ProtectedRoute><ReceivedEdit /></ProtectedRoute>} /> */}
          {/* <Route path="/addcut" element={<ProtectedRoute><CutInput /></ProtectedRoute>} /> */}
          {/* <Route path="/cutview" element={<ProtectedRoute><CutView /></ProtectedRoute>} /> */}
          {/* <Route path="/cutedit/:id" element={<ProtectedRoute><CutEdit /></ProtectedRoute>} /> */}
          {/* <Route path="/addyield" element={<ProtectedRoute><YieldInput /></ProtectedRoute>} /> */}
          {/* <Route path="/yieldview" element={<ProtectedRoute><YieldView /></ProtectedRoute>} /> */}
          {/* <Route path="/yieldedit/:id" element={<ProtectedRoute><YieldEdit /></ProtectedRoute>} /> */}
          <Route path="/manageadmins" element={<ProtectedRoute><ManageAdmins /></ProtectedRoute>} />
          <Route path="/adduser" element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
          <Route path="/customer/:customerId" element={<ProtectedRoute><CustomerDetails /></ProtectedRoute>} />
          <Route path="/customerorder" element={<ProtectedRoute><NewCustomerOrder /></ProtectedRoute>} />
          <Route path="/customerorderlist" element={<ProtectedRoute><CustomerOrderList /></ProtectedRoute>} />
          <Route path="/weeklyorders" element={<ProtectedRoute><OrdersWeekly /></ProtectedRoute>} />
          {/* <Route path="/olddashboard" element={<ProtectedRoute><DashboardOld /></ProtectedRoute>} /> */}
          <Route path="/" element={<ProtectedRoute><CourseList /></ProtectedRoute>} />
          <Route path="/course-details/:id" element={<ProtectedRoute><CourseDetails /></ProtectedRoute>} />
          <Route path="/lmsactivitylog" element={<ProtectedRoute><LMSVideoActivityLog /></ProtectedRoute>} />
          <Route path="/LMSAddVideos" element={<ProtectedRoute><LMSNewCourse /></ProtectedRoute>} />
          <Route path="/lmsaddcourse" element={<ProtectedRoute><LMSAddCourse /></ProtectedRoute>} />
          <Route path="/managestudents" element={<ProtectedRoute><ManageStudents /></ProtectedRoute>} />
          <Route path="/video-player" element={<ProtectedRoute><VideoPlayer /></ProtectedRoute>} />
          <Route path="/email-login" element={<EmailLogin />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/manage-videos" element={<LMSManageVideos />} />
          <Route path="/courses" element={<ProtectedRoute><LMSCourseList /></ProtectedRoute>} />
          <Route path="/feed" element={<ProtectedRoute><Feed /></ProtectedRoute>} />
          <Route path="/chatdb" element={<ProtectedRoute><ChatDB /></ProtectedRoute>} />
          <Route path="/public-chat" element={<PublicChat />} />
          <Route path="/oracle-chat-config" element={<ProtectedRoute><OracleChatConfig /></ProtectedRoute>} />
        </Routes>
      </Box>
    </>
  );
}

function NavBarContainer() {
  const { openNavBar, toggleNavBar } = React.useContext(NavBarContext);
  return <NavBar open={openNavBar} handleDrawerClose={toggleNavBar} />;
}

export default App;