import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Container, Grid, Typography, Paper, TextField, 
  Button, CircularProgress, Divider, IconButton,
  createTheme, ThemeProvider, Tabs, Tab, Alert,
  Select, MenuItem, FormControl, InputLabel, Tooltip,
  FormControlLabel, Switch, FormHelperText, Chip, AppBar, Toolbar,
  useMediaQuery, Drawer, List, ListItem, ListItemText, ListItemIcon,
  Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment
} from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { styled } from '@mui/material/styles';
import EmbeddedChat from '../components/EmbeddedChat';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { theme as defaultTheme } from '../theme.js';
import { BASE_URL } from '../Constants';
import { getToken, getClientId, verifyToken } from '../auth/auth';

// Helper function to deep merge objects (same as in PublicChat.js)
function deepMerge(target, source) {
  const output = { ...target };
  
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = deepMerge(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  
  return output;
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

// Custom styled components
const ColorPickerBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  transition: 'all 0.2s ease',
}));

const PreviewBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  height: '800px',
  maxHeight: '80vh',
  boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
}));

// Add this helper function to convert RGB to HEX
const rgbToHex = (color) => {
  // If it's already a hex color, return it
  if (color.startsWith('#')) {
    return color;
  }
  
  // If it's an rgb color
  const rgb = color.match(/\d+/g);
  if (rgb) {
    return '#' + rgb.map(x => {
      const hex = parseInt(x).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');
  }
  
  return color;
};

// Color picker component
const ColorPicker = ({ label, description, colorPath, defaultColor, currentSettings, defaultSettings, onColorChange }) => {
  // Resolve the current color value from the path and ensure it's HEX
  const getCurrentColor = () => {
    const paths = colorPath.split('.');
    let current = currentSettings;
    
    for (const path of paths) {
      if (!current[path]) return rgbToHex(defaultColor);
      current = current[path];
    }
    
    return rgbToHex(current);
  };
  
  // Check if value is different from default
  const isModified = () => {
    const currentColor = getCurrentColor();
    const paths = colorPath.split('.');
    let defaultValue = defaultSettings;
    
    for (const path of paths) {
      if (!defaultValue[path]) return true;
      defaultValue = defaultValue[path];
    }
    
    return rgbToHex(currentColor) !== rgbToHex(defaultValue);
  };
  
  const [open, setOpen] = useState(false);
  // Add new state for the input value
  const [inputValue, setInputValue] = useState(getCurrentColor().substring(1));
  
  const resetColor = () => {
    const paths = colorPath.split('.');
    let defaultValue = defaultSettings;
    
    for (const path of paths) {
      if (!defaultValue[path]) return;
      defaultValue = defaultValue[path];
    }
    
    onColorChange(colorPath, rgbToHex(defaultValue));
    setInputValue(rgbToHex(defaultValue).substring(1));
  };
  
  const handleColorChange = (color) => {
    const hexColor = rgbToHex(color);
    onColorChange(colorPath, hexColor);
    setInputValue(hexColor.substring(1));
  };

  const handleHexInputChange = (event) => {
    const value = event.target.value.toUpperCase();
    // Remove the # if user types it
    const hex = value.startsWith('#') ? value.substring(1) : value;
    
    // Allow typing by accepting partial hex values
    if (/^[0-9A-Fa-f]*$/.test(hex)) {
      setInputValue(hex);
      // Only update the actual color if we have a complete hex value
      if (hex.length === 6) {
        onColorChange(colorPath, '#' + hex);
      }
    }
  };

  const currentColor = getCurrentColor();
  
  // Update input value when currentColor changes externally
  useEffect(() => {
    setInputValue(currentColor.substring(1));
  }, [currentColor]);

  return (
    <ColorPickerBox sx={{ 
      borderLeft: isModified() ? '4px solid' : 'none', 
      borderLeftColor: 'primary.main',
      pl: isModified() ? 3 : 3,
      borderRadius: isModified() ? '0 12px 12px 0' : 2,
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {label}
            {isModified() && (
              <Box component="span" sx={{ color: 'primary.main', ml: 1, fontSize: '0.75rem' }}>
                (Modified)
              </Box>
            )}
          </Typography>
          {description && (
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ mt: 0.5, fontSize: '0.75rem' }}
            >
              {description}
            </Typography>
          )}
        </Box>
        
        <Button 
          size="small" 
          variant="text"
          onClick={resetColor}
          sx={{ 
            minWidth: 'auto', 
            textTransform: 'none',
            display: isModified() ? 'block' : 'none',
            fontSize: '0.75rem'
          }}
        >
          Reset
        </Button>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <Box 
          onClick={() => setOpen(!open)}
          sx={{ 
            width: '48px', 
            height: '48px', 
            borderRadius: '6px', 
            backgroundColor: currentColor,
            cursor: 'pointer',
            border: '1px solid rgba(0,0,0,0.1)',
            transition: 'all 0.2s',
            '&:hover': {
              transform: 'scale(1.05)',
            }
          }} 
        />
        
        <TextField
          variant="outlined"
          size="small"
          value={inputValue}
          onChange={handleHexInputChange}
          inputProps={{
            maxLength: 6
          }}
          InputProps={{
            startAdornment: <Box component="span" sx={{ mr: 1 }}>#</Box>,
            sx: { borderRadius: 2, fontSize: '0.875rem' }
          }}
          sx={{ 
            '& .MuiOutlinedInput-root': {
              cursor: 'text',
            }
          }}
        />
      </Box>
      
      {open && (
        <Box sx={{ position: 'relative', width: '100%', mb: 2 }}>
          <Box sx={{ 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center',
            mb: 2
          }}>
            <div style={{ width: '100%', maxWidth: '250px' }}>
              <HexColorPicker 
                color={currentColor} 
                onChange={handleColorChange} 
              />
            </div>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button 
              size="small" 
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={{ borderRadius: 2 }}
            >
              Close
            </Button>
          </Box>
        </Box>
      )}
    </ColorPickerBox>
  );
};

// Add this function near the top of your component
const CopyButton = ({ text, tooltip = "Copy to clipboard" }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Tooltip title={copied ? "Copied!" : tooltip}>
      <IconButton
        size="small"
        onClick={handleCopy}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: copied ? 'success.main' : 'primary.main',
          bgcolor: 'background.paper',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          '&:hover': {
            bgcolor: 'background.paper',
            opacity: 0.9
          }
        }}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

// Update the SecureCodeBlock component
const SecureCodeBlock = ({ code, tooltip, hidePattern }) => {
  const [showSensitive, setShowSensitive] = useState(false);
  const [copied, setCopied] = useState(false);

  // Function to mask sensitive data
  const maskCode = (code) => {
    return code.replace(hidePattern, (match) => '•'.repeat(match.length));
  };

  const handleCopy = async () => {
    // Always copy the full code with API key
    await navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Paper
      sx={{
        p: 2,
        bgcolor: 'background.default',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        position: 'relative'
      }}
    >
      <Box sx={{ 
        position: 'absolute', 
        top: 12, 
        right: 12, 
        display: 'flex', 
        gap: 1,
        zIndex: 1
      }}>
        <Tooltip title={showSensitive ? "Hide API Key" : "Show API Key"}>
          <IconButton
            size="small"
            onClick={() => setShowSensitive(!showSensitive)}
            sx={{
              bgcolor: 'background.paper',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              '&:hover': {
                bgcolor: 'background.paper',
                opacity: 0.9
              }
            }}
          >
            {showSensitive ? 
              <VisibilityOffIcon fontSize="small" /> : 
              <VisibilityIcon fontSize="small" />
            }
          </IconButton>
        </Tooltip>
        <Tooltip title={copied ? "Copied!" : tooltip}>
          <IconButton
            size="small"
            onClick={handleCopy}
            sx={{
              color: copied ? 'success.main' : 'primary.main',
              bgcolor: 'background.paper',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              '&:hover': {
                bgcolor: 'background.paper',
                opacity: 0.9
              }
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <pre style={{ 
        margin: 0, 
        overflow: 'auto', 
        fontSize: '0.875rem',
        lineHeight: 1.5,
        fontFamily: 'Consolas, Monaco, "Courier New", monospace',
        paddingTop: '20px', // Add padding at the top to prevent overlap with buttons
        '& code': {
          display: 'block',
          padding: '0.5rem'
        }
      }}>
        {showSensitive ? code : maskCode(code)}
      </pre>
    </Paper>
  );
};

// Updated LogoUploader with a modern, clean dark theme design
const LogoUploader = ({ logoUrl, onLogoChange }) => {
  const [uploading, setUploading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const fileInputRef = useRef(null);
  const [tempLogoUrl, setTempLogoUrl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  // Reset tempLogoUrl when logoUrl changes (when switching courses)
  useEffect(() => {
    if (tempLogoUrl) {
      URL.revokeObjectURL(tempLogoUrl);
      setTempLogoUrl(null);
    }
    setImageError(false);
  }, [logoUrl]);

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    await processFile(file);
  };

  const processFile = async (file) => {
    // Basic validation
    if (!file.type.startsWith('image/')) {
      alert('Please upload an image file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      alert('File size should be less than 5MB');
        return;
      }

    // Create a temporary local URL for preview
    const localUrl = URL.createObjectURL(file);
    setTempLogoUrl(localUrl);
    
    setUploading(true);
    setImageError(false);
    
    try {
      const formData = new FormData();
      formData.append('logo', file);

      const response = await fetch(`${BASE_URL}/api/upload-logo`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getToken()}`
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Upload failed');
      }
      
      const data = await response.json();
      onLogoChange(data.url);
    } catch (error) {
      console.error('Error uploading logo:', error);
      URL.revokeObjectURL(localUrl);
      setTempLogoUrl(null);
      alert('Failed to upload logo. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  // Handle drag and drop
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      processFile(files[0]);
    }
  };

  // Handle image load errors
  const handleImageError = () => {
    console.error("Failed to load image from URL:", logoUrl);
    setImageError(true);
  };

  // Determine which URL to display
  const displayUrl = tempLogoUrl || logoUrl;

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" sx={{ 
        mb: 3, 
        fontWeight: 600,
          display: 'flex', 
        alignItems: 'center',
        gap: 1,
        color: 'text.primary'
      }}>
        <Box 
          component="span" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            p: 0.5, 
            borderRadius: '50%',
            bgcolor: 'rgba(123, 79, 255, 0.2)'
          }}
        >
          <AddIcon fontSize="small" sx={{ color: '#7B4FFF' }} />
          </Box>
        Chat Logo
            </Typography>
            
      {displayUrl && !imageError ? (
        // Logo preview section - Dark theme
            <Paper 
          elevation={0}
              sx={{ 
            p: 0,
                overflow: 'hidden', 
                          borderRadius: 2, 
                          border: '1px solid',
            borderColor: 'rgba(255, 255, 255, 0.1)',
            backgroundColor: '#1E1E1E',
            transition: 'all 0.2s ease',
          }}
        >
          {/* Logo image preview */}
                        <Box sx={{ 
            position: 'relative',
            width: '100%',
            height: '160px',
                          display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#121212',
          }}>
            <img 
              src={displayUrl} 
              alt="Chat logo" 
              onError={handleImageError}
              style={{ 
                maxWidth: '70%',
                maxHeight: '120px',
                objectFit: 'contain',
                transition: 'transform 0.2s ease',
              }}
            />
            
            {/* Overlay with action buttons */}
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.4)',
              opacity: 0,
                            display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              transition: 'opacity 0.2s ease',
              '&:hover': {
                opacity: 1,
              }
            }}>
                          <Button 
                            variant="contained" 
                color="primary"
                onClick={() => fileInputRef.current?.click()}
                disabled={uploading}
                            startIcon={<AddIcon />}
                            sx={{ 
                  borderRadius: '24px',
                  boxShadow: '0 4px 12px rgba(123, 79, 255, 0.3)',
                  bgcolor: '#7B4FFF',
                  '&:hover': {
                    bgcolor: '#6a3de0',
                  }
                }}
              >
                Change Logo
                          </Button>
              <Button 
                variant="outlined"
                onClick={() => {
                  if (tempLogoUrl) {
                    URL.revokeObjectURL(tempLogoUrl);
                    setTempLogoUrl(null);
                  }
                  onLogoChange(null);
                }}
                startIcon={<DeleteIcon />}
                                    sx={{ 
                  borderRadius: '24px',
                  borderColor: 'rgba(255,255,255,0.3)',
                  color: 'white',
                                      '&:hover': {
                    borderColor: 'rgba(255,255,255,0.5)',
                    bgcolor: 'rgba(255,255,255,0.05)',
                  }
                }}
              >
                Remove
              </Button>
                            </Box>
                          </Box>
          
          {/* Footer with info */}
                          <Box sx={{ 
            p: 2,
            borderTop: '1px solid',
            borderColor: 'rgba(255, 255, 255, 0.1)',
                            display: 'flex', 
                            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
              {tempLogoUrl ? "Preview - Click Save Settings to apply" : "Current Logo"}
                            </Typography>
            
            {uploading && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={16} sx={{ color: '#7B4FFF' }} />
                <Typography variant="caption" sx={{ color: '#7B4FFF' }}>Processing...</Typography>
                          </Box>
                        )}
                        </Box>
                      </Paper>
      ) : (
        // Upload dropzone - Dark theme
        <Box
          component={Paper}
          elevation={0}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => !uploading && fileInputRef.current?.click()}
          sx={{ 
            p: 3,
            border: '2px dashed',
            borderColor: isDragging ? '#7B4FFF' : imageError ? '#ff4d4f' : 'rgba(255, 255, 255, 0.12)',
                            borderRadius: 2, 
            transition: 'all 0.2s ease',
            backgroundColor: '#1E1E1E',
            cursor: uploading ? 'default' : 'pointer',
            '&:hover': {
              borderColor: 'rgba(255, 255, 255, 0.25)',
              '& .upload-icon': {
                transform: 'scale(1.05)',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
              },
              '& .upload-text': {
                color: 'rgba(255, 255, 255, 0.9)',
              }
            },
            textAlign: 'center',
            minHeight: '180px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
            justifyContent: 'center',
                            gap: 2
          }}
        >
          {uploading ? (
            // Uploading state
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              <CircularProgress size={40} sx={{ color: '#7B4FFF' }} />
                          <Typography variant="body2" color="text.secondary">
                Uploading your logo...
                          </Typography>
                        </Box>
          ) : (
            // Upload prompt
            <>
                        <Box
                className="upload-icon"
                          sx={{ 
                  width: 50, 
                  height: 50, 
                  borderRadius: '50%', 
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1,
                  transition: 'all 0.2s ease',
                }}
              >
                <AddIcon fontSize="medium" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                        </Box>
              
              <Typography 
                className="upload-text"
                variant="subtitle1" 
                color={imageError ? '#ff4d4f' : 'text.primary'}
          sx={{
                  transition: 'color 0.2s ease',
                }}
              >
                {imageError ? 'Image failed to load' : 'Upload your logo'}
              </Typography>
              
              <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '80%', mb: 1 }}>
                {imageError 
                  ? 'There was a problem with the previous image. Please try uploading again.' 
                  : 'Drag and drop your image here, or click to browse your files'}
              </Typography>
              
              <Typography variant="caption" color="text.secondary">
                Recommended: PNG or SVG with transparency, max 5MB
              </Typography>
            </>
          )}
          
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
            disabled={uploading}
          />
        </Box>
      )}
    </Box>
  );
};

// Save button timeout
const SAVE_SUCCESS_TIMEOUT = 3000; // 3 seconds

function OracleChatConfig() {
  // State for current client and authentication
  const [clientId, setClientId] = useState('');
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [showDefaultTheme, setShowDefaultTheme] = useState(false);
  const [usingDefaultSettings, setUsingDefaultSettings] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [showConfigJson, setShowConfigJson] = useState(false);
  const [apiKey, setApiKey] = useState('');
  
  // Add state for courses
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [loadingCourses, setLoadingCourses] = useState(true);
  
  // State for customization options
  const [themeSettings, setThemeSettings] = useState({
    palette: {
      primary: { 
        dark: "#c23000",
        main: "#ff633c",
        light: "#ff8566"
      }
    }
  });
  
  // State for chat customizations
  const [chatCustomizations, setChatCustomizations] = useState({
    prefilled_buttons: [],
    logo_url: null
  });
  
  // State for new prefilled button being added
  const [newButtonText, setNewButtonText] = useState('');
  
  // State for the current theme object
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);
  
  // State for active tab
  const [activeTab, setActiveTab] = useState(0);
  
  // State for courseId
  const [courseId, setCourseId] = useState(null);
  
  // Reset success indicator after timeout
  useEffect(() => {
    if (saveSuccessful) {
      const timer = setTimeout(() => {
        setSaveSuccessful(false);
      }, SAVE_SUCCESS_TIMEOUT);
      
      return () => clearTimeout(timer);
    }
  }, [saveSuccessful]);

  // Effect to fetch Oracle courses on mount
  useEffect(() => {
    const fetchCourses = async () => {
      const token = getToken();
      const isAuthenticated = verifyToken();
      
      if (!isAuthenticated || !token) {
        setLoadingCourses(false);
        setError('Authentication required');
        return;
      }

      try {
        const response = await fetch(`${BASE_URL}/api/courses/oracle`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Oracle courses');
        }

        const data = await response.json();
        setCourses(data.courses);
        // Automatically select the first course if available
        if (data.courses && data.courses.length > 0) {
          setSelectedCourseId(data.courses[0].id);
        }
        setInitialLoadComplete(true);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setError('Failed to load Oracle courses');
      } finally {
        setLoadingCourses(false);
      }
    };

    fetchCourses();
  }, []);

  // Effect to fetch course settings when a course is selected
  useEffect(() => {
    if (selectedCourseId) {
      fetchCourseSettings(selectedCourseId);
    }
  }, [selectedCourseId]);

  // Update fetchCourseSettings to use the selected course
  const fetchCourseSettings = async (courseId) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${BASE_URL}/api/chat/settings/${courseId}`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch settings');
      }
      
      const data = await response.json();
      
      // Store the API key
      setApiKey(data.api_key || '');
      
      // Update theme settings if available
      if (data.theme_settings) {
        setThemeSettings(data.theme_settings);
        setUsingDefaultSettings(false);
      } else {
        // Reset to default theme settings from the default theme
        setThemeSettings({
          palette: {
            primary: { 
              dark: defaultTheme.palette.primary.dark,
              main: defaultTheme.palette.primary.main,
              light: defaultTheme.palette.primary.light
            }
          }
        });
        setUsingDefaultSettings(true);
      }
      
      // Update chat customizations if available, otherwise set default buttons
      if (data.chat_customizations) {
        // Ensure we set a complete chatCustomizations object with all expected properties
        setChatCustomizations({
          // Default empty values for anything not provided
          prefilled_buttons: [],
          logo_url: null,
          // Override with any values from the API
          ...data.chat_customizations
        });
      } else {
        // Set default prefilled buttons for better user experience and ensure logo_url is null
        setChatCustomizations({
          prefilled_buttons: [
            {
              id: 'default-1',
              text: 'How do i get started?'
            },
            {
              id: 'default-2',
              text: 'What can you do?'
            },
            {
              id: 'default-3',
              text: 'When can i start?'
            },
          ],
          logo_url: null
        });
      }
      
    } catch (error) {
      setError(error.message);
      console.error('Error fetching settings:', error);
      // On error, reset to default theme and default buttons
      setThemeSettings({
        palette: {
          primary: { 
            dark: defaultTheme.palette.primary.dark,
            main: defaultTheme.palette.primary.main,
            light: defaultTheme.palette.primary.light
          }
        }
      });
      setUsingDefaultSettings(true);
      setChatCustomizations({
        prefilled_buttons: [
          {
            id: 'default-1',
            text: 'How can I get started with this course?'
          },
          {
            id: 'default-2',
            text: 'What topics are covered in this course?'
          },
          {
            id: 'default-3',
            text: 'How do I submit assignments?'
          },
          {
            id: 'default-4',
            text: 'What are the course requirements?'
          }
        ],
        logo_url: null
      });
    } finally {
      setLoading(false);
    }
  };

  // Update saveSettings to use the selected course
  const saveSettings = async () => {
    if (!selectedCourseId) {
      setError('Please select a course first');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);
    setSaveSuccessful(false);
    
    try {
      const response = await fetch(`${BASE_URL}/api/chat/settings/${selectedCourseId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify({
          theme_settings: themeSettings,
          chat_customizations: chatCustomizations
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save settings');
      }
      
      setSuccess('Settings saved successfully');
      setSaveSuccessful(true);
    } catch (error) {
      setError(error.message);
      console.error('Error saving settings:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Add a new prefilled button
  const addPrefilledButton = () => {
    if (!newButtonText.trim()) return;
    
    setChatCustomizations(prev => ({
      ...prev,
      prefilled_buttons: [
        ...(prev.prefilled_buttons || []),
        {
          id: Date.now().toString(),
          text: newButtonText
        }
      ]
    }));
    
    setNewButtonText('');
  };
  
  // Remove a prefilled button
  const removePrefilledButton = (buttonId) => {
    setChatCustomizations(prev => ({
      ...prev,
      prefilled_buttons: prev.prefilled_buttons.filter(button => button.id !== buttonId)
    }));
  };
  
  // Update color for a specific theme element
  const updateColor = (colorPath, newColor) => {
    const paths = colorPath.split('.');
    setThemeSettings(prev => {
      const newSettings = { ...prev };
      let current = newSettings;
      
      for (let i = 0; i < paths.length - 1; i++) {
        if (!current[paths[i]]) {
          current[paths[i]] = {};
        }
        current = current[paths[i]];
      }
      
      current[paths[paths.length - 1]] = newColor;
      return newSettings;
    });

    // Update the current theme immediately
    setCurrentTheme(prevTheme => {
      const newTheme = createTheme(deepMerge(defaultTheme, {
        palette: {
          primary: {
            main: themeSettings.palette.primary.main,
            dark: themeSettings.palette.primary.dark,
            light: themeSettings.palette.primary.light
          }
        }
      }));
      return newTheme;
    });
  };

  // Effect to update currentTheme when themeSettings change
  useEffect(() => {
    const newTheme = createTheme(deepMerge(defaultTheme, themeSettings));
    setCurrentTheme(newTheme);
  }, [themeSettings]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    // Always use the default theme for the main UI
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="lg" sx={{ 
        py: { xs: 2, sm: 3, md: 5 },
        px: { xs: 2, sm: 3 },
        mt: { xs: '56px', sm: 0 }, // Add top margin for mobile to prevent navbar overlap
        position: 'relative',
        minHeight: { xs: 'calc(100vh - 56px)', sm: 'auto' }
      }}>
        <Box sx={{ 
          mb: { xs: 2, sm: 3, md: 4 }, 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: { xs: 2, sm: 0 }
        }}>
          <Typography variant="h4" sx={{ 
            fontWeight: 600,
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.125rem' }
          }}>
            Oracle Chat Configuration
          </Typography>
          
          {/* Desktop Save Button */}
          <Box sx={{ 
            display: { xs: 'none', sm: 'flex' }, 
            gap: 2, 
            width: 'auto' 
          }}>
            <Button
              variant="contained"
              size="large"
              startIcon={saveSuccessful ? <CheckCircleIcon /> : <SaveIcon />}
              onClick={saveSettings}
              disabled={loading || !verifyToken() || !selectedCourseId}
              title={!verifyToken() ? "Authentication required to save settings" : !selectedCourseId ? "Select a course first" : ""}
              color={saveSuccessful ? "success" : "primary"}
              sx={{
                transition: 'all 0.3s ease-in-out',
                ...(saveSuccessful && {
                  bgcolor: 'success.main',
                  '&:hover': {
                    bgcolor: 'success.dark',
                  }
                })
              }}
            >
              {loading ? <CircularProgress size={24} /> : (
                saveSuccessful ? 'Saved!' : (!verifyToken() ? 'Login to Save' : 'Save Settings')
              )}
            </Button>
          </Box>
        </Box>
        
        {/* Content wrapper */}
        <Box>
          {/* Add Course Selection Dropdown */}
          <Paper sx={{ 
            p: { xs: 2, sm: 3 }, 
            mb: 3, 
            borderRadius: 2 
          }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Select Oracle
            </Typography>
            
            {loadingCourses ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <CircularProgress size={24} />
                <Typography>Loading courses...</Typography>
              </Box>
            ) : courses.length === 0 ? (
              <Alert severity="info">
                No Oracle courses found. Create an Oracle course first.
              </Alert>
            ) : (
              <FormControl fullWidth>
                <InputLabel>Oracles</InputLabel>
                <Select
                  value={selectedCourseId}
                  onChange={(e) => setSelectedCourseId(e.target.value)}
                  label="Course"
                  sx={{ borderRadius: 2 }}
                >
                  {courses.map((course) => (
                    <MenuItem key={course.id} value={course.id}>
                      {course.course_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Paper>

          {/* Show configuration only when a course is selected */}
          {!selectedCourseId && !loadingCourses && (
            <Alert severity="info" sx={{ mb: 3 }}>
              Select an Oracle course to customize its chat settings
            </Alert>
          )}

          {selectedCourseId && (
            <Paper 
              sx={{ 
                borderRadius: 3, 
                overflow: 'hidden', 
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                mb: { xs: 3, sm: 4 }
              }}
            >
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{ 
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  bgcolor: 'background.paper',
                  px: { xs: 1, sm: 3 },
                  '& .MuiTab-root': {
                    py: 2,
                    fontWeight: 500,
                    textTransform: 'none',
                    fontSize: { xs: '0.8rem', sm: '0.875rem' },
                    minWidth: { xs: 'auto', sm: 0 },
                  }
                }}
              >
                <Tab label="Theme Settings" />
                <Tab label="Chat Content" />
                <Tab label="Reset to Default" />
                <Tab label="Embed Code" />
                <Tab label="API Key" />
              </Tabs>
              
              <Box sx={{ p: { xs: 2, sm: 3 } }}>
                {activeTab === 0 && (
                  <Grid container spacing={{ xs: 2, md: 4 }}>
                    <Grid item xs={12} md={6}>
                      <Paper 
                        sx={{ 
                          p: { xs: 2, sm: 3 }, 
                          borderRadius: 2, 
                          border: '1px solid',
                          borderColor: 'divider',
                          height: '100%' 
                        }}
                      >
                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                          Customize Colors
                        </Typography>
                        
                        <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 500 }}>
                          Primary Color Variants
                        </Typography>
                        
                        <ColorPicker 
                          label="Primary Color" 
                          description="Main brand color used for buttons, links, and interactive elements"
                          colorPath="palette.primary.main" 
                          defaultColor={defaultTheme.palette.primary.main}
                          currentSettings={themeSettings}
                          defaultSettings={defaultTheme}
                          onColorChange={updateColor}
                        />
                        
                        <ColorPicker 
                          label="Primary Dark" 
                          description="Used for hover states and assistant name in chat messages"
                          colorPath="palette.primary.dark" 
                          defaultColor={defaultTheme.palette.primary.dark}
                          currentSettings={themeSettings}
                          defaultSettings={defaultTheme}
                          onColorChange={updateColor}
                        />
                        
                        <ColorPicker 
                          label="Primary Light" 
                          description="Used for button outlines and secondary interactive elements"
                          colorPath="palette.primary.light" 
                          defaultColor={defaultTheme.palette.primary.light}
                          currentSettings={themeSettings}
                          defaultSettings={defaultTheme}
                          onColorChange={updateColor}
                        />
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Paper 
                        sx={{ 
                          p: { xs: 2, sm: 3 }, 
                          borderRadius: 2, 
                          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                          height: '100%' 
                        }}
                      >
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: { xs: 'column', sm: 'row' },
                          justifyContent: 'space-between', 
                          alignItems: { xs: 'flex-start', sm: 'center' },
                          gap: { xs: 2, sm: 0 },
                          mb: 3 
                        }}>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            Chat Preview
                          </Typography>
                          <Button 
                            size="small" 
                            variant="outlined"
                            onClick={() => setShowDefaultTheme(!showDefaultTheme)}
                            sx={{ 
                              borderRadius: 2,
                              width: { xs: '100%', sm: 'auto' }
                            }}
                          >
                            {showDefaultTheme ? "Show Custom Theme" : "Compare with Default"}
                          </Button>
                        </Box>
                        
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                          {showDefaultTheme 
                            ? "Viewing with default application theme." 
                            : "This is how your chat will appear with the current theme settings."}
                        </Typography>
                        
                        <Box sx={{ 
                          borderRadius: 2, 
                          overflow: 'hidden', 
                          border: '1px solid', 
                          borderColor: 'divider',
                        }}>
                          <PreviewBox sx={{ height: { xs: '700px', sm: '800px' } }}>
                            <ThemeProvider theme={showDefaultTheme ? defaultTheme : currentTheme}>
                              <EmbeddedChat 
                                courseId={courseId || "demo"}
                                clientId={getClientId() || "demo"}
                                courseName={courses.find(course => course.id === selectedCourseId)?.course_name || "Demo Course"}
                                accessToken={getToken() || "demo-token"}
                                clientCustomizations={chatCustomizations}
                                onError={(msg) => console.log('Demo error:', msg)}
                              />
                            </ThemeProvider>
                          </PreviewBox>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                
                {activeTab === 1 && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        {/* Add LogoUploader before the Prefilled Buttons section */}
                        <LogoUploader 
                          logoUrl={chatCustomizations.logo_url}
                          onLogoChange={(url) => {
                            setChatCustomizations(prev => ({
                              ...prev,
                              logo_url: url
                            }));
                          }}
                        />

                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                          Prefilled Buttons
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                          Add quick response buttons that appear at the top of the chat. These help users start conversations with common questions.
                        </Typography>
                        
                        <Box 
                          component="form" 
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (newButtonText.trim()) addPrefilledButton();
                          }}
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'flex-start', 
                            mb: 3,
                            gap: 1
                          }}
                        >
                          <TextField 
                            label="Button Text"
                            variant="outlined"
                            fullWidth
                            value={newButtonText}
                            onChange={(e) => setNewButtonText(e.target.value)}
                            placeholder="e.g., How does this course work?"
                            size="medium"
                            InputProps={{
                              sx: { borderRadius: 2 }
                            }}
                          />
                          <Button 
                            variant="contained" 
                            startIcon={<AddIcon />}
                            onClick={addPrefilledButton}
                            disabled={!newButtonText.trim()}
                            sx={{ 
                              height: 56, 
                              minWidth: 100,
                              borderRadius: 2
                            }}
                            type="submit"
                          >
                            Add
                          </Button>
                        </Box>
                        
                        <Divider sx={{ my: 3 }} />
                        
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                          Current Prefilled Buttons
                        </Typography>
                        
                        {chatCustomizations.prefilled_buttons && chatCustomizations.prefilled_buttons.length > 0 ? (
                          <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 500 }}>
                              Manage Buttons:
                            </Typography>
                            
                            <Box sx={{ mt: 2 }}>
                              {chatCustomizations.prefilled_buttons.map((button) => (
                                <Paper 
                                  key={button.id} 
                                  elevation={0}
                                  sx={{ 
                                    p: 2, 
                                    mb: 1.5, 
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    borderRadius: 2,
                                    transition: 'all 0.2s',
                                    '&:hover': {
                                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                      borderColor: 'primary.light',
                                    }
                                  }}
                                >
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      fontWeight: 500,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis' 
                                    }}
                                  >
                                    {button.text}
                                  </Typography>
                                  <IconButton 
                                    size="small" 
                                    color="error"
                                    onClick={() => removePrefilledButton(button.id)}
                                    sx={{
                                      transition: 'all 0.2s',
                                      '&:hover': {
                                        backgroundColor: 'error.lighter',
                                      }
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Paper>
                              ))}
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ 
                            py: 4, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            borderRadius: 2,
                            border: '1px dashed',
                            borderColor: 'divider',
                            bgcolor: 'action.hover',
                            mb: 2
                          }}>
                            <Typography variant="body1" sx={{ mb: 1, fontWeight: 500 }}>
                              No prefilled buttons configured yet
                            </Typography>
                            <Typography variant="body2" sx={{ textAlign: 'center', maxWidth: '80%' }}>
                              Add a button above to help users start conversations with common questions.
                            </Typography>
                          </Box>
                        )}
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            Chat Preview
                          </Typography>
                          <Button 
                            size="small" 
                            variant="outlined"
                            onClick={() => setShowDefaultTheme(!showDefaultTheme)}
                            sx={{ borderRadius: 2 }}
                          >
                            {showDefaultTheme ? "Show Custom Theme" : "Compare with Default"}
                          </Button>
                        </Box>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                          {showDefaultTheme 
                            ? "Viewing with default application theme." 
                            : "Preview how your chat will look with the current content settings."}
                        </Typography>
                        
                        <Box sx={{ borderRadius: 2, overflow: 'hidden', border: '1px solid', borderColor: 'divider' }}>
                          <PreviewBox sx={{ height: { xs: '700px', sm: '800px' } }}>
                            <ThemeProvider theme={showDefaultTheme ? defaultTheme : currentTheme}>
                              <EmbeddedChat 
                                courseId={courseId || "demo"}
                                clientId={getClientId() || "demo"}
                                courseName={courses.find(course => course.id === selectedCourseId)?.course_name || "Demo Course"}
                                accessToken={getToken() || "demo-token"}
                                clientCustomizations={chatCustomizations}
                                onError={(msg) => console.log('Demo error:', msg)}
                              />
                            </ThemeProvider>
                          </PreviewBox>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                
                {activeTab === 2 && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 4, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        <Box sx={{ textAlign: 'center', mb: 4 }}>
                          <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                            Reset Configuration
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                            This will reset all color settings and remove all prefilled buttons.
                            Your configuration will revert to the default application theme.
                          </Typography>
                          
                          <Box sx={{ 
                            p: 3, 
                            borderRadius: 2, 
                            bgcolor: 'error.lighter', 
                            mb: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2
                          }}>
                            <Typography variant="subtitle2" color="error.main" sx={{ fontWeight: 600 }}>
                              This action cannot be undone
                            </Typography>
                            
                            <Button 
                              variant="contained" 
                              color="error" 
                              onClick={() => {
                                setThemeSettings({
                                  palette: {
                                    primary: { 
                                      dark: defaultTheme.palette.primary.dark,
                                      main: defaultTheme.palette.primary.main,
                                      light: defaultTheme.palette.primary.light
                                    }
                                  }
                                });
                                setChatCustomizations({
                                  prefilled_buttons: []
                                });
                                setUsingDefaultSettings(true);
                                setActiveTab(0);
                                setSuccess('Settings have been reset to default values');
                              }}
                              sx={{ borderRadius: 2, px: 3 }}
                            >
                              Reset to Default
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                            Current Configuration
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            This is the JSON configuration that will be saved.
                          </Typography>
                        </Box>
                        
                        <Box
                          sx={{ 
                            p: 2, 
                            bgcolor: 'background.default', 
                            maxHeight: '300px', 
                            overflow: 'auto',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider',
                            '& pre': {
                              margin: 0,
                              fontSize: '0.75rem',
                              overflow: 'auto',
                              fontFamily: 'monospace'
                            }
                          }}
                        >
                          <pre>
                            {JSON.stringify(
                              {
                                theme_settings: themeSettings,
                                chat_customizations: chatCustomizations
                              }, 
                              null, 
                              2
                            )}
                          </pre>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                
                {activeTab === 3 && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 4, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        {!apiKey ? (
                          <Alert severity="warning" sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                              No API Key Available
                            </Typography>
                            <Typography variant="body2">
                              Please generate an API key in the API Key tab before accessing the embed code.
                            </Typography>
                          </Alert>
                        ) : (
                          <>
                            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                              Embed Instructions
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                              Follow these steps to embed the chat in your website:
                            </Typography>
                            
                            <Box sx={{ mb: 4 }}>
                              <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                                1. Add the iframe to your HTML
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Copy and paste this code where you want the chat to appear:
                              </Typography>
                              <SecureCodeBlock 
                                code={`<iframe
  src="https://lms.backboard.io/public-chat?courseId=${selectedCourseId}&clientId=${getClientId()}&apiKey=${apiKey}"
  style="width: 100%; height: 600px; border: none;"
></iframe>`}
                                tooltip="Copy iframe code"
                                hidePattern={new RegExp(`apiKey=${apiKey}`, 'g')}
                              />
                            </Box>
                            
                            <Box sx={{ mb: 4 }}>
                              <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                                2. Add CSS styles (optional)
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Add these styles to make the chat container responsive:
                              </Typography>
                              <SecureCodeBlock 
                                code={`.chat-container {
  height: 600px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.chat-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}`}
                                tooltip="Copy CSS styles"
                                hidePattern={/(?!)/} // Nothing to hide in CSS
                              />
                            </Box>
                            
                            <Alert severity="info" sx={{ mb: 3 }}>
                              <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                                Important Notes:
                              </Typography>
                              <ul style={{ margin: 0, paddingLeft: 20 }}>
                                <li>The API key is automatically included in the code</li>
                                <li>Click the eye icon to show/hide the API key</li>
                                <li>The chat inherits your saved theme and content settings</li>
                                <li>Adjust the iframe height and width as needed</li>
                              </ul>
                            </Alert>
                          </>
                        )}
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 4, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        {!apiKey ? (
                          <Alert severity="info">
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                              Example Code Not Available
                            </Typography>
                            <Typography variant="body2">
                              Generate an API key to see the complete example implementation.
                            </Typography>
                          </Alert>
                        ) : (
                          <>
                            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                              Example Implementation
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                              Here's a complete example of how to implement the chat in your website:
                            </Typography>
                            
                            <SecureCodeBlock 
                              code={`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Course Chat</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 20px;
            background-color: #f5f5f5;
        }
        
        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
        }
        
        .chat-container {
            height: 600px;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        }
        
        .chat-container iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="chat-container">
            <iframe 
                src="https://lms.backboard.io/public-chat?courseId=${selectedCourseId}&clientId=${getClientId()}&apiKey=${apiKey}"
            ></iframe>
        </div>
    </div>
</body>
</html>`}
                              tooltip="Copy complete example"
                              hidePattern={new RegExp(`apiKey=${apiKey}`, 'g')}
                            />
                            
                            <Alert severity="success" sx={{ mt: 3 }}>
                              <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                                Ready to use!
                              </Typography>
                              <Typography variant="body2">
                                Copy the complete example above and you're ready to go. The API key is automatically included in the code when copying.
                              </Typography>
                            </Alert>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                
                {activeTab === 4 && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 4, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                          API Key Management
                        </Typography>
                        
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                          Your API key is used to authenticate requests to the chat API. Keep it secure and don't share it publicly.
                        </Typography>
                        
                        {!apiKey ? (
                          <Box sx={{ mb: 4 }}>
                            <Alert severity="info" sx={{ mb: 3 }}>
                              <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                                No API Key Generated
                              </Typography>
                              <Typography variant="body2">
                                You haven't generated an API key yet. Generate one to start using the chat integration.
                              </Typography>
                            </Alert>
                          </Box>
                        ) : (
                          <Box sx={{ mb: 4 }}>
                            <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                              Current API Key
                            </Typography>
                            <SecureCodeBlock 
                              code={apiKey}
                              tooltip="Copy API Key"
                              hidePattern={/.+/}
                            />
                          </Box>
                        )}
                        
                        <Box sx={{ 
                          p: 3, 
                          borderRadius: 2, 
                          bgcolor: !apiKey ? 'primary.lighter' : 'warning.lighter', 
                          mb: 4
                        }}>
                          <Typography variant="subtitle2" color={!apiKey ? 'primary.dark' : 'warning.dark'} sx={{ mb: 2, fontWeight: 600 }}>
                            {!apiKey ? 'Generate API Key' : 'Generate New API Key'}
                          </Typography>
                          <Typography variant="body2" color={!apiKey ? 'primary.dark' : 'warning.dark'} sx={{ mb: 3 }}>
                            {!apiKey 
                              ? 'Generate an API key to enable chat integration with your website.'
                              : 'Warning: Generating a new API key will invalidate the current key. All existing chat integrations will need to be updated.'}
                          </Typography>
                          <Button 
                            variant="contained" 
                            color={!apiKey ? 'primary' : 'warning'}
                            onClick={async () => {
                              const confirmMessage = !apiKey 
                                ? 'Do you want to generate an API key?' 
                                : 'Are you sure you want to generate a new API key? This will invalidate the current key.';
                              
                              if (window.confirm(confirmMessage)) {
                                setLoading(true);
                                try {
                                  const response = await fetch(`${BASE_URL}/api/chat/settings/${selectedCourseId}/regenerate-key`, {
                                    method: 'POST',
                                    headers: {
                                      'Authorization': `Bearer ${getToken()}`
                                    }
                                  });
                                  
                                  if (!response.ok) {
                                    throw new Error('Failed to generate new API key');
                                  }
                                  
                                  const data = await response.json();
                                  setApiKey(data.api_key);
                                  setSuccess('New API key generated successfully');
                                } catch (error) {
                                  setError(error.message);
                                } finally {
                                  setLoading(false);
                                }
                              }
                            }}
                            disabled={loading}
                            sx={{ borderRadius: 2 }}
                          >
                            {loading ? <CircularProgress size={24} /> : (!apiKey ? 'Generate Key' : 'Generate New Key')}
                          </Button>
                        </Box>
                        
                        <Alert severity="info">
                          <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                            Security Best Practices:
                          </Typography>
                          <ul style={{ margin: 0, paddingLeft: 20 }}>
                            <li>Store your API key securely</li>
                            <li>Never share your API key in public repositories</li>
                            <li>Rotate your API key periodically</li>
                            <li>Update all integrations when changing the key</li>
                          </ul>
                        </Alert>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Paper sx={{ p: 4, borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.1)', height: '100%' }}>
                        {!apiKey ? (
                          <Alert severity="info">
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                              API Usage Instructions Not Available
                            </Typography>
                            <Typography variant="body2">
                              Generate an API key first to see usage instructions and examples.
                            </Typography>
                          </Alert>
                        ) : (
                          <>
                            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                              API Key Usage
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                              Your API key is required when embedding the chat in your website. Here's how to use it:
                            </Typography>
                            
                            <Box sx={{ mb: 4 }}>
                              <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                                1. Include in the iframe URL
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Add your API key as a query parameter:
                              </Typography>
                              <SecureCodeBlock 
                                code={`https://lms.backboard.io/public-chat?courseId=${selectedCourseId}&clientId=${getClientId()}&apiKey=${apiKey}`}
                                tooltip="Copy URL"
                                hidePattern={new RegExp(`apiKey=${apiKey}`, 'g')}
                              />
                            </Box>
                            
                            <Alert severity="warning" sx={{ mb: 3 }}>
                              <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                                Important:
                              </Typography>
                              <Typography variant="body2">
                                If you generate a new API key, you'll need to update the key in all places where you've embedded the chat.
                              </Typography>
                            </Alert>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Paper>
          )}
        </Box>

        {/* Mobile Save Button - Normal position at bottom */}
        <Box sx={{ display: { xs: 'block', sm: 'none' }, mb: 2 }}>
          <Button
            variant="contained"
            startIcon={saveSuccessful ? <CheckCircleIcon /> : <SaveIcon />}
            onClick={saveSettings}
            disabled={loading || !verifyToken() || !selectedCourseId}
            title={!verifyToken() ? "Authentication required to save settings" : !selectedCourseId ? "Select a course first" : ""}
            color={saveSuccessful ? "success" : "primary"}
            sx={{ width: '100%', borderRadius: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : (
              saveSuccessful ? 'Saved!' : (!verifyToken() ? 'Login to Save' : 'Save Settings')
            )}
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default OracleChatConfig; 