import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, createTheme, ThemeProvider, Button, Alert } from '@mui/material';
import EmbeddedChat from '../components/EmbeddedChat';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../Constants';
import { theme as defaultTheme } from '../theme.js';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function PublicChat() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('courseId');
    const clientId = queryParams.get('clientId');
    const apiKey = queryParams.get('apiKey'); // Get API key from URL
    const defaultName = queryParams.get('courseName') || `Course ${courseId}`;
    
    const [courseInfo, setCourseInfo] = useState({ name: defaultName });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [clientTheme, setClientTheme] = useState(defaultTheme);
    const [clientCustomizations, setClientCustomizations] = useState(null);
    const [assistantId, setAssistantId] = useState(null);

    // Helper function to deep merge objects
    function deepMerge(target, source) {
        const output = { ...target };
        
        if (isObject(target) && isObject(source)) {
            Object.keys(source).forEach(key => {
                if (isObject(source[key])) {
                    if (!(key in target)) {
                        Object.assign(output, { [key]: source[key] });
                    } else {
                        output[key] = deepMerge(target[key], source[key]);
                    }
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }
        
        return output;
    }
    
    function isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    // Fetch course info
    useEffect(() => {
        const fetchCourseInfo = async () => {
            if (courseId && clientId && apiKey) {
                setLoading(true);
                try {
                    const response = await fetch(`${BASE_URL}/api/public/course/${courseId}?clientId=${clientId}`, {
                        headers: {
                            'X-API-Key': apiKey
                        }
                    });
                    
                    if (response.ok) {
                        const data = await response.json();
                        setCourseInfo({ 
                            name: data.name || defaultName,
                            assistantId: data.assistant_id
                        });
                        setAssistantId(data.assistant_id);
                        
                        // Set theme and customizations
                        if (data.theme) {
                            try {
                                const newTheme = createTheme(deepMerge(defaultTheme, data.theme));
                                setClientTheme(newTheme);
                            } catch (themeError) {
                                console.error('Error parsing theme:', themeError);
                                setClientTheme(defaultTheme);
                            }
                        }
                        
                        if (data.chatCustomizations) {
                            setClientCustomizations(data.chatCustomizations);
                        }
                        
                        setError(null);
                    } else {
                        // Handle error responses
                        try {
                            const errorData = await response.json();
                            console.error('Error fetching course:', errorData.error);
                            
                            // Set a user-friendly error message
                            if (response.status === 403) {
                                setError({
                                    title: 'Access Denied',
                                    message: errorData.error || 'You do not have permission to access this course.',
                                    status: response.status
                                });
                            } else if (response.status === 404) {
                                setError({
                                    title: 'Course Not Found',
                                    message: 'The requested course could not be found.',
                                    status: response.status
                                });
                            } else if (response.status === 410) {
                                setError({
                                    title: 'Course Unavailable',
                                    message: errorData.error || 'This course has been deleted and is no longer available.',
                                    status: response.status
                                });
                            } else {
                                setError({
                                    title: 'Error',
                                    message: errorData.error || 'An error occurred while loading the course.',
                                    status: response.status
                                });
                            }
                        } catch (parseError) {
                            setError({
                                title: 'Error',
                                message: 'An unexpected error occurred.',
                                status: response.status
                            });
                        }
                    }
                } catch (error) {
                    console.error('Error fetching course info:', error);
                    setError({
                        title: 'Connection Error',
                        message: 'Could not connect to the server. Please try again later.',
                        status: 0
                    });
                } finally {
                    setLoading(false);
                }
            }
        };
        
        fetchCourseInfo();
    }, [courseId, clientId, apiKey, defaultName]);

    // Validate required parameters
    if (!courseId || !clientId || !apiKey) {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
                    <Typography variant="h5" color="error" gutterBottom>
                        Missing Required Parameters
                    </Typography>
                    <Typography variant="body1">
                        This chat requires courseId, clientId, and apiKey to function properly.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                        Example URL: /public-chat?courseId=123&clientId=1&apiKey=your-api-key
                    </Typography>
                </Container>
            </ThemeProvider>
        );
    }

    // Display error message if access is denied or other errors
    if (error) {
        return (
            <ThemeProvider theme={clientTheme}>
                <Container maxWidth="md" sx={{ 
                    py: 6, 
                    textAlign: 'center',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                }}>
                    <Box sx={{
                        p: 4,
                        borderRadius: 2,
                        bgcolor: 'background.paper',
                        maxWidth: '500px',
                        width: '100%',
                        boxShadow: 3,
                    }}>
                        <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
                        
                        <Typography variant="h5" color="error" gutterBottom>
                            {error.title}
                        </Typography>
                        
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            {error.message}
                        </Typography>
                        
                        {error.status === 403 && (
                            <Alert severity="warning" sx={{ mb: 3, textAlign: 'left' }}>
                                This course belongs to a different client or chat is not enabled.
                            </Alert>
                        )}
                        
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => window.location.reload()}
                        >
                            Try Again
                        </Button>
                    </Box>
                </Container>
            </ThemeProvider>
        );
    }

    // Display loading state
    if (loading) {
        return (
            <ThemeProvider theme={clientTheme}>
                <Container maxWidth="md" sx={{ 
                    py: 6, 
                    textAlign: 'center',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                }}>
                    <Typography variant="h6">
                        Loading course...
                    </Typography>
                </Container>
            </ThemeProvider>
        );
    }

    // Main chat interface
    return (
        <ThemeProvider theme={clientTheme}>
            <Box sx={{ 
                height: '100vh', 
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <EmbeddedChat 
                        courseId={courseId}
                        clientId={clientId}
                        courseName={courseInfo.name}
                        assistantId={assistantId}
                        apiKey={apiKey}
                        clientCustomizations={clientCustomizations}
                        onError={(errorMsg) => setError({
                            title: 'Chat Error',
                            message: errorMsg || 'An error occurred with the chat service.',
                            status: 500
                        })}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default PublicChat; 