import React, { useState, useEffect, useRef, useContext } from 'react';
import { 
    Box, Typography, TextField, Button, 
    CircularProgress, IconButton,
    Paper, useTheme, Avatar,
    Drawer, List, ListItem, ListItemText,
    ListItemButton, Tooltip, LoadingButton
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StorageIcon from '@mui/icons-material/Storage';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getToken, getUserId, isSuperAdmin } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { NavBarContext } from '../components/navigation/NavBarContext';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DownloadIcon from '@mui/icons-material/Download';
import { unstable_batchedUpdates } from 'react-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AssistantIcon from '@mui/icons-material/Assistant';
import CreateAssistant from './CreateAssistant';

const DRAWER_WIDTH = 320;

function ChatDB() {
    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [debouncedInputMessage, setDebouncedInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const messagesEndRef = useRef(null);
    const token = getToken();
    const chatContainerRef = useRef(null);
    const inputRef = useRef(null);
    const [currentChatTitle, setCurrentChatTitle] = useState('New Chat');
    const [isTitleLoading, setIsTitleLoading] = useState(false);
    const [localThreads, setLocalThreads] = useState([]);
    const [userCache, setUserCache] = useState({}); // Add cache for user details
    const [noAssistantError, setNoAssistantError] = useState(false);
    const [isAssistantDialogOpen, setIsAssistantDialogOpen] = useState(false);
    const [isHistoryLoading, setIsHistoryLoading] = useState(false);
    const [loadingState, setLoadingState] = useState(null); // Can be: 'creating-assistant', 'initializing-chat', null

    // Get the left navbar state
    const { openNavBar } = useContext(NavBarContext);
    const LEFT_NAV_WIDTH = 240; // This should match your NavBar drawer width
    
    // Replace isInitialized state with a ref
    const isInitializedRef = useRef(false);

    useEffect(() => {
        const initializeComponent = async () => {
            try {
                // First fetch chat history
                await fetchChatHistory();
                // Then create a new thread
                await initializeChat(true);
            } catch (error) {
                console.error('Error initializing component:', error);
            }
        };

        // Reset the initialization flag on every mount
        isInitializedRef.current = false;
        initializeComponent();
    }, []); // Empty dependency array means this runs once on mount

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedInputMessage(inputMessage);
        }, 10);
        return () => clearTimeout(timer);
    }, [inputMessage]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const initializeChat = async (forceNew = false) => {
        // Add a flag to prevent duplicate requests
        if (initializeChat.isLoading) return;
        initializeChat.isLoading = true;
        
        try {
            setLoadingState('initializing-chat');
            
            // Always create a new thread
            const response = await fetch(`${BASE_URL}/api/thread`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            
            // Check if we got an assistant not found error
            if (response.status === 400) {
                const errorData = await response.json();
                if (errorData.code === 'ASSISTANT_NOT_FOUND') {
                    setNoAssistantError(true);
                    setLoadingState(null);
                    return;
                }
            }
            
            if (!response.ok) {
                throw new Error('Failed to create thread');
            }
            
            const data = await response.json();
            const newThreadId = data.threadId;
            
            // Update states with the new thread using batched updates
            unstable_batchedUpdates(() => {
                setThreadId(newThreadId);
                setMessages([]); // Clear any existing messages
                setCurrentChatTitle('New Chat');
                
                // Create new thread object
                const newThread = {
                    threadId: newThreadId,
                    title: 'New Chat',
                    createdAt: new Date().toISOString(),
                    isNew: true
                };
                
                // Add the new thread to the beginning of both lists
                setLocalThreads(prev => [newThread, ...prev]);
                setChatHistory(prev => [newThread, ...prev]);
                setLoadingState(null);
            });
            
        } catch (error) {
            console.error('Error initializing chat:', error);
            setLoadingState(null);
        } finally {
            initializeChat.isLoading = false;
        }
    };

    const fetchChatHistory = async () => {
        setIsHistoryLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/api/history`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }

            const data = await response.json();
            
            // Update both states
            unstable_batchedUpdates(() => {
                setChatHistory(data);
                setLocalThreads(data);
            });
            
            return data;
        } catch (error) {
            console.error('Error fetching chat history:', error);
            setChatHistory([]);
            setLocalThreads([]);
            throw error;
        } finally {
            setIsHistoryLoading(false);
        }
    };

    const selectThread = async (selectedThreadId) => {
        if (selectedThreadId === threadId) return;
        
        setIsLoading(true);
        setMessages([]); // Clear current messages
        
        try {
            const response = await fetch(`${BASE_URL}/api/thread/${selectedThreadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (!response.ok) throw new Error('Failed to fetch thread messages');
            
            const data = await response.json();
            setThreadId(selectedThreadId);
            
            // Find the thread title from history
            const selectedThread = chatHistory.find(t => t.threadId === selectedThreadId);
            if (selectedThread) {
                setCurrentChatTitle(selectedThread.title || new Date(selectedThread.createdAt).toLocaleString());
            }
            
            setMessages(data.messages);
        } catch (error) {
            console.error('Error fetching thread messages:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = async () => {
        setMessages([]);
        await initializeChat();
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputMessage(value);
    };

    const sendMessage = async (messageToSend, retryCount = 0) => {
        if (!messageToSend.trim() || isLoading) return;
        
        const initialPlaceholderId = Date.now().toString();
        let currentMessageId = initialPlaceholderId + '_assistant';
        let accumulatedResponse = '';
        let sqlResult = '';
        let initialResponse = '';
        let errorMessageContent = '';
        
        // **Clear any previous error status for the current assistant message**
        setMessages(prev => prev.map(msg => {
            if (msg.role === 'assistant' && msg.status === 'Error') {
                return { ...msg, status: null, errorContent: null }; // Clear error status and content
            }
            return msg;
        }));
        
        // Check if this is the first message in a new thread
        const isFirstMessage = messages.length === 0;
        
        // Use batched updates to prevent multiple re-renders
        unstable_batchedUpdates(() => {
            setIsLoading(true);
            
            // If this is the first message, set title to loading state
            if (isFirstMessage) {
                setIsTitleLoading(true);
                setCurrentChatTitle('New Chat');
            }
            
            // Add user message to UI immediately
            setMessages(prev => [...prev, {
                role: 'user',
                content: messageToSend,
                id: initialPlaceholderId,
                userId: getUserId() // Get userId from the token
            }, {
                role: 'assistant',
                content: '',
                id: currentMessageId,
                status: 'Thinking...'
            }]);
        });
        
        try {
            const response = await fetch(`${BASE_URL}/api/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    message: messageToSend,
                    threadId: threadId,
                    isFirstMessage: isFirstMessage
                })
            });

            if (!response.ok) {
                throw new Error(`Server responded with status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = ''; // Add buffer for incomplete chunks

            while (true) {
                try {
                    const { done, value } = await reader.read();

                    if (done) {
                        console.log('SSE stream completed normally.');
                        break;
                    }

                    // Decode the stream chunk and add to buffer
                    const chunk = decoder.decode(value, { stream: true });
                    buffer += chunk;

                    // Split buffer into lines and process complete ones
                    const lines = buffer.split('\n');
                    // Keep the last line in buffer if it's incomplete
                    buffer = lines.pop() || '';

                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            try {
                                const data = JSON.parse(line.slice(6));
                                console.log('SSE Data received:', data);

                                // Handle title update event
                                if (data.type === 'title_update' && data.title) {
                                    unstable_batchedUpdates(() => {
                                        setCurrentChatTitle(data.title);
                                        setIsTitleLoading(false);
                                        
                                        // Update both chatHistory and localThreads with the new title
                                        setChatHistory(prev => prev.map(chat => 
                                            chat.threadId === threadId 
                                                ? { ...chat, title: data.title } 
                                                : chat
                                        ));
                                        
                                        setLocalThreads(prev => prev.map(thread => 
                                            thread.threadId === threadId 
                                                ? { ...thread, title: data.title, isNew: false } 
                                                : thread
                                        ));
                                    });
                                } else {
                                    // Handle other event types as before
                                    switch (data.type) {
                                        case 'start':
                                            setMessages(prev => prev.map(msg =>
                                                msg.id === currentMessageId
                                                    ? { ...msg, status: 'Generating response...' }
                                                    : msg
                                            ));
                                            break;

                                        case 'chunk':
                                            accumulatedResponse += data.content;
                                            // Show accumulated response with initial response and SQL result
                                            setMessages(prev => prev.map(msg =>
                                                msg.id === currentMessageId
                                                    ? { ...msg, content: initialResponse + sqlResult + accumulatedResponse }
                                                    : msg
                                            ));
                                            break;

                                        case 'tool_output':
                                            // Store initial response and SQL result separately
                                            initialResponse = accumulatedResponse;  // Capture response before tool output

                                            let parsedToolOutput;
                                            try {
                                                // Attempt to parse tool output as JSON
                                                parsedToolOutput = JSON.parse(data.content);
                                                sqlResult = '\n\n```json\n' + JSON.stringify(parsedToolOutput, null, 2) + '\n```\n\n';
                                                console.log('SQL result set successfully parsed:', sqlResult.length);
                                            } catch (jsonError) {
                                                console.error('JSON parsing error in tool_output:', jsonError, 'Raw data:', data.content);
                                                // If JSON parsing fails, treat content as raw string
                                                sqlResult = '\n\n```\n' + data.content + '\n```\n\n';
                                                console.log('SQL result set rendered as raw string due to parsing error.');
                                            }

                                            accumulatedResponse = '';  // Reset for post-tool response
                                            setMessages(prev => prev.map(msg =>
                                                msg.id === currentMessageId
                                                    ? {
                                                        ...msg,
                                                        content: initialResponse + sqlResult,
                                                        status: 'Processing results...'
                                                    }
                                                    : msg
                                            ));
                                            break;

                                        case 'status':
                                            if (data.status === 'completed') {
                                                setMessages(prev => prev.map(msg =>
                                                    msg.id === currentMessageId
                                                        ? { ...msg, status: null }
                                                        : msg
                                                ));
                                                setIsLoading(false);
                                            } else {
                                                setMessages(prev => prev.map(msg =>
                                                    msg.id === currentMessageId
                                                        ? { ...msg, status: data.status }
                                                        : msg
                                                ));
                                            }
                                            break;

                                        case 'end':
                                            setMessages(prev => prev.map(msg =>
                                                msg.id === currentMessageId
                                                    ? {
                                                        ...msg,
                                                        content: initialResponse + sqlResult + (data.content || accumulatedResponse),
                                                        status: null
                                                    }
                                                    : msg
                                            ));
                                            setIsLoading(false);
                                            break;

                                        case 'error':
                                            console.error('SSE Error:', data.error);
                                            errorMessageContent = data.error;
                                            break;

                                        default:
                                            console.warn('Unknown SSE data type:', data.type);
                                    }
                                }
                            } catch (error) {
                                console.warn('Error parsing SSE data:', error, 'Raw line:', line);
                                // Continue processing other lines even if one fails
                                continue;
                            }
                        }
                    }
                } catch (streamError) {
                    console.error('Stream reading error:', streamError);
                    
                    // If it's a network error and we haven't retried too many times
                    if (streamError.message.includes('network error') && retryCount < 3) {
                        console.log(`Attempting to reconnect (attempt ${retryCount + 1}/3)...`);
                        
                        // Clean up the current reader
                        try {
                            await reader.cancel();
                        } catch (cancelError) {
                            console.warn('Error canceling reader:', cancelError);
                        }
                        
                        // Wait a bit before retrying
                        await new Promise(resolve => setTimeout(resolve, 1000 * (retryCount + 1)));
                        
                        // Retry the message send
                        return sendMessage(messageToSend, retryCount + 1);
                    }
                    
                    throw streamError; // Re-throw if we can't handle it
                }
            }

            // If this was the first message, refresh chat history to get the new thread
            if (isFirstMessage) {
                // We'll fetch the history after a short delay to give the server time to process
                setTimeout(() => {
                    fetchChatHistory();
                }, 500);
            }

        } catch (error) {
            console.error('Error sending message:', error);
            
            // Show appropriate error message to user
            const errorMessage = {
                id: Date.now(),
                role: 'error',
                content: error.message.includes('network error') 
                    ? 'Connection lost. Please try again.'
                    : 'An error occurred. Please try again.'
            };
            
            setMessages(prev => [...prev.filter(msg => !msg.isLoading), errorMessage]);
            setIsLoading(false);
        }
    };

    const handleNewChat = async () => {
        try {
            setIsLoading(true);
            await initializeChat(true); // Always create a new thread
        } catch (error) {
            console.error('Error creating new chat:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const MarkdownContent = ({ content }) => {
        const [downloadUrl, setDownloadUrl] = React.useState('');

        // Extract download URL from content when it changes
        React.useEffect(() => {
            const csvMatch = content.match(/\[Download CSV file\]\((.*?)\)/);
            setDownloadUrl(csvMatch ? csvMatch[1] : '');
        }, [content]);

        const handleCopy = async (text) => {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error('Failed to copy text:', err);
            }
        };

        const renderJsonTable = React.useCallback((jsonString) => {
            try {
                const parsedData = JSON.parse(jsonString);
                
                // Check if the data has the expected structure for query results
                if (parsedData && typeof parsedData === 'object' && 'rows' in parsedData && 'total_rows' in parsedData) {
                    const displayData = parsedData.rows;
                    const totalRows = parsedData.total_rows;
                    const isTruncated = parsedData.truncated;
                    const headers = displayData.length > 0 ? Object.keys(displayData[0]) : [];

                    return (
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: theme.palette.background.paper,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                px: 2,
                                py: 1,
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                    <Box>
                                        <Typography variant="subtitle2">
                                            Query Results ({totalRows} rows)
                                        </Typography>
                                        {isTruncated && (
                                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                Showing first 20 rows
                                            </Typography>
                                        )}
                                    </Box>
                                    {downloadUrl && (
                                        <Tooltip title="Download full dataset as CSV">
                                            <IconButton
                                                component="a"
                                                href={downloadUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                size="small"
                                                sx={{ 
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(147, 51, 234, 0.1)',
                                                    }
                                                }}
                                            >
                                                <DownloadIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                                {isTruncated && (
                                    <Box sx={{ 
                                        mt: 1,
                                        p: 1,
                                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                        borderRadius: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}>
                                        <DownloadIcon sx={{ fontSize: '0.875rem', color: 'info.main' }} />
                                        <Typography variant="caption" sx={{ color: 'info.main' }}>
                                            Click the download icon above to view all {totalRows} rows in CSV format
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <TableContainer 
                                component={Paper}
                                sx={{
                                    maxHeight: 400,
                                    backgroundColor: theme.palette.background.paper,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    '& .MuiTableCell-root': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                        height: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: theme.palette.action.hover,
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: theme.palette.action.selected,
                                        borderRadius: '4px',
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.focus,
                                        },
                                    },
                                }}
                            >
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header, index) => (
                                                <TableCell 
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        fontWeight: 'bold',
                                                        whiteSpace: 'nowrap',
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    {header}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {displayData.map((row, rowIndex) => (
                                            <TableRow 
                                                key={rowIndex}
                                                sx={{
                                                    '&:nth-of-type(odd)': {
                                                        backgroundColor: theme.palette.action.hover,
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.action.selected,
                                                    },
                                                }}
                                            >
                                                {headers.map((header, cellIndex) => {
                                                    const cellValue = row[header];
                                                    const isObject = typeof cellValue === 'object' && cellValue !== null;
                                                    
                                                    // Create a simplified display value for the table cell
                                                    let displayValue = '';
                                                    if (isObject) {
                                                        // For objects, show a truncated JSON string
                                                        const jsonString = JSON.stringify(cellValue);
                                                        displayValue = jsonString.length > 30 
                                                            ? jsonString.substring(0, 30) + '...' 
                                                            : jsonString;
                                                    } else {
                                                        // For non-objects, show the string value
                                                        displayValue = cellValue?.toString() || '';
                                                    }
                                                    
                                                    // Create a detailed tooltip content for objects
                                                    const tooltipContent = isObject 
                                                        ? JSON.stringify(cellValue, null, 2)
                                                        : '';
                                                    
                                                    return (
                                                        <TableCell 
                                                            key={cellIndex}
                                                            sx={{
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '200px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                cursor: isObject ? 'pointer' : 'default'
                                                            }}
                                                        >
                                                            {isObject ? (
                                                                <Tooltip 
                                                                    title={
                                                                        <Box 
                                                                            component="pre" 
                                                                            sx={{ 
                                                                                whiteSpace: 'pre-wrap',
                                                                                maxWidth: '400px',
                                                                                maxHeight: '300px',
                                                                                overflow: 'auto',
                                                                                fontSize: '0.75rem',
                                                                                p: 1
                                                                            }}
                                                                        >
                                                                            {tooltipContent}
                                                                        </Box>
                                                                    }
                                                                    arrow
                                                                    placement="top-start"
                                                                    PopperProps={{
                                                                        sx: {
                                                                            '& .MuiTooltip-tooltip': {
                                                                                backgroundColor: theme.palette.background.paper,
                                                                                color: theme.palette.text.primary,
                                                                                border: `1px solid ${theme.palette.divider}`,
                                                                                boxShadow: theme.shadows[3]
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    <Box 
                                                                        sx={{ 
                                                                            textDecoration: 'underline',
                                                                            textDecorationStyle: 'dotted'
                                                                        }}
                                                                    >
                                                                        {displayValue}
                                                                    </Box>
                                                                </Tooltip>
                                                            ) : (
                                                                displayValue
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    );
                }

                // Fall back to existing table rendering logic for other JSON formats
                // ... rest of the existing renderJsonTable code ...

            } catch (parseError) {
                console.error('Error parsing JSON:', parseError);
            }
        }, [theme, downloadUrl]);

        const formatText = (text) => {
            // Split text into blocks based on code blocks
            const blocks = text.split(/(```[\s\S]*?```)/);
            
            return (
                <>
                    {blocks.map((block, index) => {
                        // Handle code blocks
                        if (block.startsWith('```')) {
                            const codeContent = block.slice(3, -3);
                            const firstLine = codeContent.split('\n')[0];
                            const language = firstLine.trim();
                            const code = codeContent.slice(firstLine.length + 1);

                            // Handle JSON specifically
                            if (language === 'json') {
                                try {
                                    return renderJsonTable(code);
                                } catch (err) {
                                    console.error('Error rendering JSON table:', err);
                                }
                            }

                            return (
                                <div key={index} style={{ position: 'relative' }}>
                                    <IconButton
                                        className="copy-button"
                                        onClick={() => handleCopy(code)}
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                            }
                                        }}
                                    >
                                        <ContentCopyIcon sx={{ fontSize: 16 }} />
                                    </IconButton>
                                    <SyntaxHighlighter
                                        style={atomDark}
                                        language={language}
                                        PreTag="div"
                                    >
                                        {code}
                                    </SyntaxHighlighter>
                                </div>
                            );
                        }

                        // Handle regular text with better formatting
                        const lines = block.split('\n');
                        return (
                            <Box key={index} sx={{ mb: 2 }}>
                                {lines.map((line, lineIndex) => {
                                    // Skip download links as they're handled by useEffect
                                    if (line.includes('[Download CSV file]')) {
                                        return null;
                                    }
                                    
                                    // Handle numbered lists (e.g., "1. Item")
                                    if (/^\d+\.\s/.test(line)) {
                                        return (
                                            <Typography
                                                key={lineIndex}
                                                sx={{
                                                    pl: 2,
                                                    mb: 1,
                                                    fontWeight: line.includes('**') ? 'bold' : 'normal'
                                                }}
                                            >
                                                {line.replace(/\*\*/g, '')}
                                            </Typography>
                                        );
                                    }
                                    
                                    // Handle bullet points/sub-items
                                    if (line.trim().startsWith('-')) {
                                        return (
                                            <Typography
                                                key={lineIndex}
                                                sx={{
                                                    pl: 4,
                                                    mb: 0.5,
                                                    fontWeight: line.includes('**') ? 'bold' : 'normal'
                                                }}
                                            >
                                                {line.replace(/\*\*/g, '')}
                                            </Typography>
                                        );
                                    }

                                    // Handle regular text
                                    return line.trim() ? (
                                        <Typography
                                            key={lineIndex}
                                            sx={{
                                                mb: 1,
                                                fontWeight: line.includes('**') ? 'bold' : 'normal'
                                            }}
                                        >
                                            {line.replace(/\*\*/g, '')}
                                        </Typography>
                                    ) : null;
                                })}
                            </Box>
                        );
                    })}
                </>
            );
        };

        return (
            <Box sx={{ 
                '& pre': {
                    m: 0,
                    maxHeight: '300px',
                    overflow: 'auto',
                    position: 'relative',
                },
                '& p': { 
                    m: 0,
                    lineHeight: 1.6 
                },
                '& code': {
                    fontFamily: 'monospace',
                    p: 0.5,
                    borderRadius: 1,
                },
                '& ul, & ol': {
                    m: 0,
                    pl: 0,
                    listStylePosition: 'outside',
                    paddingLeft: '20px',
                },
                '& li': {
                    m: 0,
                    mb: 0,
                    p: 0,
                    lineHeight: 1.2,
                    display: 'list-item',
                    '& strong': {
                        display: 'inline',
                        marginRight: '0.5rem'
                    }
                },
                '& h1, & h2, & h3, & h4, & h5, & h6': { m: 0 }
            }}>
                {formatText(content)}
            </Box>
        );
    };

    // Add function to fetch user details
    const fetchUserDetails = async (userId) => {
        if (userCache[userId]) {
            return userCache[userId];
        }

        try {
            const response = await fetch(`${BASE_URL}/api/readuser/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch user details');
            const userData = await response.json();
            const userInfo = {
                username: `${userData.first_name} ${userData.last_name}`,
                userId: userData.user_id
            };
            setUserCache(prev => ({ ...prev, [userId]: userInfo }));
            return userInfo;
        } catch (error) {
            console.error('Error fetching user details:', error);
            return null;
        }
    };

    // Modify the renderMessage function to use actual usernames
    const renderMessage = (msg, index) => {
        // If the message has a userId but no username, fetch the user details
        if (msg.role === 'user' && msg.userId && !msg.username) {
            fetchUserDetails(msg.userId).then(userDetails => {
                if (userDetails) {
                    // Update the message in the messages array
                    setMessages(prev => prev.map((m, i) => 
                        i === index ? { ...m, username: userDetails.username } : m
                    ));
                }
            });
        }

        return (
            <Box 
                key={index} 
                sx={{ 
                    display: 'flex',
                    gap: 2,
                    p: 3,
                    backgroundColor: msg.role === 'assistant' 
                        ? 'rgba(255, 255, 255, 0.03)'
                        : 'transparent',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <Avatar
                    sx={{
                        bgcolor: msg.role === 'user' ? 'primary.main' : 'secondary.main',
                        width: 32,
                        height: 32
                    }}
                >
                    {msg.role === 'user' ? (msg.username ? msg.username[0].toUpperCase() : 'U') : <StorageIcon sx={{ fontSize: 20 }} />}
                </Avatar>

                <Box sx={{ 
                    flex: 1,
                    overflow: 'hidden',
                    minWidth: 0
                }}>
                    <Typography 
                        variant="subtitle2" 
                        sx={{ 
                            mb: 1,
                            color: msg.role === 'user' ? 'primary.main' : 'secondary.main',
                            fontWeight: 600
                        }}
                    >
                        {msg.role === 'user' ? msg.username || 'Anonymous' : 'ChatDB'}
                    </Typography>

                    <Box sx={{ 
                        typography: 'body1',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        maxWidth: '100%',
                        '& pre': {
                            overflowX: 'auto',
                            maxWidth: '100%',
                        }
                    }}>
                        {msg.content && <MarkdownContent content={msg.content} />}
                    </Box>

                    {msg.errorContent && (
                        <Typography
                            variant="body2"
                            color="error"
                            sx={{ mt: 1, fontStyle: 'italic' }}
                        >
                            Error: {msg.errorContent}
                        </Typography>
                    )}

                    {msg.citations && msg.citations.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                Sources:
                            </Typography>
                            {msg.citations.map((citation, idx) => (
                                <Typography 
                                    key={idx} 
                                    variant="caption" 
                                    sx={{ 
                                        display: 'block',
                                        color: 'text.secondary',
                                        ml: 1
                                    }}
                                >
                                    • {citation}
                                </Typography>
                            ))}
                        </Box>
                    )}

                    {msg.status && (
                        <Typography 
                            variant="caption" 
                            sx={{ 
                                display: 'block',
                                mt: 1,
                                color: 'text.secondary',
                                fontStyle: 'italic'
                            }}
                        >
                            {msg.status}
                        </Typography>
                    )}
                </Box>
            </Box>
        );
    };

    const renderChatHistory = () => {
        return (
            <Drawer
                variant="persistent"
                anchor="right"
                open={isDrawerOpen}
                sx={{
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                        backgroundColor: 'rgba(32, 33, 35, 0.95)',
                        border: 'none',
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%',
                    position: 'relative'
                }}>
                    <Box sx={{ 
                        p: 2, 
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                            Chat History
                        </Typography>
                        <IconButton
                            onClick={() => setIsDrawerOpen(false)}
                            sx={{ color: 'text.primary' }}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </Box>
                    
                    <List sx={{ flex: 1, overflow: 'auto', pt: 1 }}>
                        <ListItem>
                            <ListItemButton
                                onClick={handleNewChat}
                                sx={{
                                    borderRadius: '8px',
                                    mb: 1,
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    },
                                }}
                            >
                                <AddIcon sx={{ mr: 1 }} />
                                <ListItemText primary="New Chat" />
                            </ListItemButton>
                        </ListItem>

                        {isHistoryLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : chatHistory.length === 0 ? (
                            <ListItem>
                                <ListItemText 
                                    primary="No chat history yet"
                                    sx={{ color: 'text.secondary' }}
                                />
                            </ListItem>
                        ) : (
                            chatHistory.map((thread) => (
                                <ListItem key={thread.threadId}>
                                    <ListItemButton
                                        onClick={() => selectThread(thread.threadId)}
                                        selected={thread.threadId === threadId}
                                        disabled={isLoading}
                                        sx={{
                                            borderRadius: '8px',
                                            position: 'relative',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                            },
                                        }}
                                    >
                                        <StorageIcon sx={{ 
                                            mr: 1,
                                            color: thread.threadId === threadId ? 'secondary.main' : 'inherit'
                                        }} />
                                        <ListItemText 
                                            primary={
                                                <Typography 
                                                    noWrap 
                                                    sx={{ 
                                                        color: thread.threadId === threadId ? 'text.primary' : 'text.secondary'
                                                    }}
                                                >
                                                    {thread.title || new Date(thread.createdAt).toLocaleString()}
                                                </Typography>
                                            }
                                            secondary={
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {new Date(thread.createdAt).toLocaleString()}
                                                    </Typography>
                                                    {thread.username && (
                                                        <Typography 
                                                            variant="caption" 
                                                            display="block"
                                                            sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                                                        >
                                                            by {thread.username}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        )}
                    </List>
                </Box>
            </Drawer>
        );
    };

    // Modify the ChatInput component to accept and handle noAssistantError prop
    const ChatInput = React.memo(({ onSendMessage, isLoading, noAssistantError }) => {
        const [localInputValue, setLocalInputValue] = useState('');
        const inputRef = useRef(null);
        
        const handleLocalInputChange = (e) => {
            setLocalInputValue(e.target.value);
        };
        
        const handleSendMessage = () => {
            if (!localInputValue.trim() || isLoading || noAssistantError) return;
            onSendMessage(localInputValue);
            setLocalInputValue('');
        };
        
        return (
            <Box sx={{
                display: 'flex',
                maxWidth: '800px',
                margin: '0 auto',
                position: 'relative'
            }}>
                <TextField
                    fullWidth
                    multiline
                    maxRows={4}
                    variant="outlined"
                    placeholder={noAssistantError ? "Please create an assistant first" : "Ask about your data..."}
                    value={localInputValue}
                    onChange={handleLocalInputChange}
                    inputRef={inputRef}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                    disabled={isLoading || noAssistantError}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: '12px',
                            pr: '60px',
                            '&:hover': {
                                backgroundColor: noAssistantError ? 'rgba(255, 255, 255, 0.02)' : 'rgba(255, 255, 255, 0.08)',
                            },
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(255, 255, 255, 0.02)',
                                cursor: 'not-allowed'
                            }
                        }
                    }}
                />
                <IconButton
                    onClick={handleSendMessage}
                    disabled={isLoading || noAssistantError}
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: noAssistantError ? 'text.disabled' : 'primary.main',
                        '&:hover': {
                            backgroundColor: noAssistantError ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
                        }
                    }}
                >
                    {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        <SendIcon />
                    )}
                </IconButton>
            </Box>
        );
    });

    // Add this handler
    const handleAssistantCreated = async () => {
        setNoAssistantError(false);
        await initializeChat();
    };

    // Update renderMainContent to use the new component
    const renderMainContent = () => {
        if (loadingState) {
            return (
                <Box 
                    sx={{ 
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: 2,
                        p: 4,
                        color: 'text.secondary',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                >
                    <CircularProgress color="secondary" size={48} />
                    <Typography variant="h6">
                        {loadingState === 'creating-assistant' 
                            ? 'Creating your assistant...'
                            : loadingState === 'initializing-chat'
                            ? 'Initializing chat...'
                            : 'Loading...'}
                    </Typography>
                </Box>
            );
        }

        if (noAssistantError) {
            return <CreateAssistant onAssistantCreated={handleAssistantCreated} />;
        }

        if (messages.length === 0) {
            return (
                <Box 
                    sx={{ 
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: 2,
                        p: 4,
                        color: 'text.secondary',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                >
                    <StorageIcon sx={{ fontSize: 48, opacity: 0.5 }} />
                    <Typography variant="h6">
                        Welcome to ChatDB
                    </Typography>
                    <Typography variant="body2" textAlign="center" sx={{ maxWidth: 600 }}>
                        I'm your AI database assistant. Ask me anything about your data and I'll help you analyze it.
                    </Typography>
                </Box>
            );
        }

        return (
            <>
                {messages.map((msg, index) => renderMessage(msg, index))}
                <div ref={messagesEndRef} />
            </>
        );
    };

    return (
        <Box sx={{
            height: '100vh',
            display: 'flex',
            backgroundColor: theme.palette.background.default,
            position: 'relative',
            overflow: 'hidden'
        }}>
            <Box sx={{
                position: 'fixed',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                left: openNavBar ? `${LEFT_NAV_WIDTH}px` : '72px',
                right: isDrawerOpen ? `${DRAWER_WIDTH}px` : 0,
                transition: theme.transitions.create(['left', 'right'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                paddingRight: 2,
            }}>
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    maxWidth: '800px',
                    width: '100%',
                    margin: '0 auto',
                    paddingX: 2,
                }}>
                    {/* Chat title header - simplified without loading indicator */}
                    {messages.length > 0 && (
                        <Box sx={{
                            py: 2,
                            px: 1,
                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                        }}>
                            <StorageIcon sx={{ color: 'secondary.main' }} />
                            <Typography 
                                variant="h6" 
                                sx={{ 
                                    flex: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontStyle: isTitleLoading ? 'italic' : 'normal',
                                    color: isTitleLoading ? 'text.secondary' : 'text.primary'
                                }}
                            >
                                {currentChatTitle}
                            </Typography>
                        </Box>
                    )}
                    
                    <Box
                        ref={chatContainerRef}
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            pt: 2,
                            pb: 2,
                            '&::-webkit-scrollbar': { display: 'none' },
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                        }}
                    >
                        {renderMainContent()}
                    </Box>

                    <Box
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            p: 2,
                            backgroundColor: theme.palette.background.default,
                            width: '100%',
                            maxWidth: '800px',
                            margin: '0 auto',
                        }}
                    >
                        <ChatInput 
                            onSendMessage={sendMessage} 
                            isLoading={loadingState !== null}
                            noAssistantError={noAssistantError}
                        />
                    </Box>
                </Box>
            </Box>

            {!isDrawerOpen && (
                <IconButton
                    onClick={() => setIsDrawerOpen(true)}
                    sx={{
                        position: 'fixed',
                        right: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'rgba(32, 33, 35, 0.95)',
                        color: 'text.primary',
                        width: '32px',
                        height: '32px',
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: 'rgba(52, 53, 55, 0.95)',
                        },
                        zIndex: 1200,
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
            )}

            {renderChatHistory()}
        </Box>
    );
}

export default React.memo(ChatDB); 