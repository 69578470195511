import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { getUserId, getRoleId, isSuperAdmin, isCompanyAdmin } from '../../auth/auth.js'; 
import { getToken } from '../../auth/auth.js'; 
import { BASE_URL } from '../../Constants'; 
import { toast } from 'react-toastify'; 
import { useNavigate } from 'react-router-dom'; 
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {extraListItems, LmsListItems, ManageListItems } from './mainListItems.js';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 240;

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'sticky',
      top: 0,
      whiteSpace: 'nowrap',
      width: drawerWidth,
      height: '100vh',
      backgroundColor: '#121212',
      color: '#fff',
      transition: theme.transitions.create(['width', 'margin', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      opacity: 1,
      '& .MuiListItemButton-root': {
        padding: '8px 16px',
        margin: '4px 8px',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
      },
      '& .MuiListSubheader-root': {
        backgroundColor: 'transparent',
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '0.875rem',
        lineHeight: '1.5',
        padding: '16px',
      },
      '& .MuiDivider-root': {
        borderColor: 'rgba(255, 255, 255, 0.12)',
        margin: 0,
      },
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create(['width', 'margin', 'opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        opacity: 0,
        marginLeft: '-240px',
        visibility: 'hidden',
      }),
    },
  }),
);

const MobileDrawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#121212',
    color: '#fff',
    zIndex: theme.zIndex.drawer + 2,
  },
}));

export default function NavBar({open, handleDrawerClose}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasChatAccess, setHasChatAccess] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const ALLOWED_CHAT_USER_IDS = [1, 2, 72];
    const userId = getUserId();
    setHasChatAccess(ALLOWED_CHAT_USER_IDS.includes(Number(userId)));
    console.log(getRoleId());
    console.log(isSuperAdmin(getRoleId()));
    console.log(isCompanyAdmin(getRoleId()));
    if(isSuperAdmin(getRoleId()) || isCompanyAdmin(getRoleId())) {
      setIsAdmin(true);
    }
  }, []);

  const handleLogout = async () => {
    const token = getToken();

    const response = await fetch(`${BASE_URL}/api/logout`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    const responseBody = await response.json();
    console.log(responseBody);
    
    //for user
    localStorage.removeItem("token");
    localStorage.removeItem("userId");

    //for session
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");

    navigate('/login');

    // toast.success('You have been logged out', {
    //   autoClose: 2000,
    //   position: toast.POSITION.TOP_CENTER,
    // });
  };

  const handleClick = (menu) => {
    switch(menu) {
      case 'Manage':
        setOpenManage(!openManage);
        break;
      default:
        break;
    }
  };

  // Handle click for mobile navigation items
  const handleMobileItemClick = () => {
    if (isMobile) {
      handleDrawerClose();
    }
  };

  const DrawerComponent = isMobile ? MobileDrawer : DesktopDrawer;

  return (
    <DrawerComponent
      variant={isMobile ? 'temporary' : 'permanent'}
      open={open}
      onClose={handleDrawerClose}
      anchor={isMobile ? 'top' : 'left'}
    >
      <Toolbar 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
          minHeight: '48px !important',
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav" sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        p: 0,
      }}>
        <Box>
          <LmsListItems navBarOpen={open} position="top" onMobileItemClick={handleMobileItemClick} />
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Box>
          <LmsListItems navBarOpen={open} position="bottom" onMobileItemClick={handleMobileItemClick} />
          {isAdmin && openManage && <Divider />}
          {isAdmin && (
            <ManageListItems 
              handleClick={handleClick} 
              openManage={openManage} 
              navBarOpen={open} 
              onMobileItemClick={handleMobileItemClick}
            />
          )}
          <Divider />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            p: 1
          }}>
            {isAdmin && (
              <IconButton 
                onClick={() => handleClick('Manage')}
                sx={{ 
                  color: 'white',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' }
                }}
              >
                <SettingsIcon />
              </IconButton>
            )}
            <IconButton 
              onClick={handleLogout}
              sx={{ 
                color: 'white',
                marginLeft: 'auto',
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' }
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Box>
        </Box>
      </List>
      <Divider />
    </DrawerComponent>
  );
} 
