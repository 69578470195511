import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
    Box, Container, Typography, Paper, List, ListItem, 
    ListItemText, IconButton, Divider, LinearProgress,
    CssBaseline, ListItemIcon, Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { getToken, getUserId, getRoleId, isSuperAdmin, getClientId, isCompanyAdmin } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import AIChat from '../components/AIChat';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ChatContext } from '../App';
import { NavBarContext } from '../components/navigation/NavBarContext';

const EnrolledUsersSection = ({ 
    enrolledUsers, 
    selectedUser, 
    handleUserClick, 
    videos, 
    selectedUserProgress, 
    usersListRef,
    showProgress,
    userProgress
}) => {
    const calculateCourseProgress = (userId) => {
        if (!videos || videos.length === 0) return 0;
        return userProgress[userId] || 0;
    };

    return (
        <Paper 
            sx={{ 
                bgcolor: '#1e1e1e',
                borderRadius: 1,
                p: 3,
                height: '500px',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ color: '#fff', mb: 3 }}>
                Enrolled Users
            </Typography>
            <List ref={usersListRef} sx={{ 
                overflowY: 'auto',
                flex: 1,
                pr: 2,
                mr: -2,
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'rgba(0,0,0,0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(255,255,255,0.1)',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: 'rgba(255,255,255,0.2)',
                }
            }}>
                {enrolledUsers.map(user => (
                    <ListItem
                        key={user.user_id}
                        sx={{
                            cursor: 'pointer',
                            bgcolor: selectedUser?.user_id === user.user_id ? '#242424' : '#1e1e1e',
                            '&:hover': { 
                                bgcolor: selectedUser?.user_id === user.user_id ? '#242424' : 'action.hover'
                            },
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            borderRadius: 1,
                            mb: 1,
                            py: 1,
                            px: 2,
                            transition: 'background-color 0.2s ease',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                height: '100%',
                                width: selectedUser?.user_id === user.user_id ? '0%' : `${calculateCourseProgress(user.user_id)}%`,
                                backgroundColor: 'rgba(200, 200, 200, 0.15)',
                                transition: selectedUser?.user_id === user.user_id ? 'none' : 'width 0.3s ease-in-out'
                            }
                        }}
                        onClick={() => handleUserClick(user)}
                    >
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            width: '100%',
                            position: 'relative',
                            zIndex: 1
                        }}>
                            <ListItemText
                                primary={
                                    <Typography 
                                        variant="subtitle1" 
                                        sx={{ 
                                            color: '#fff',
                                            fontSize: '0.95rem'
                                        }}
                                    >
                                        {`${user.first_name} ${user.last_name}`}
                                    </Typography>
                                }
                                secondary={
                                    <Typography 
                                        variant="body2" 
                                        sx={{ 
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            fontSize: '0.85rem'
                                        }}
                                    >
                                        {user.email}
                                    </Typography>
                                }
                                sx={{ my: 0 }}
                            />
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: '#8b5cf6',
                                    fontWeight: 500
                                }}
                            >
                                {`${calculateCourseProgress(user.user_id)}%`}
                            </Typography>
                        </Box>

                        {showProgress && selectedUser?.user_id === user.user_id && (
                            <List dense sx={{ 
                                mt: 2,
                                position: 'relative',
                                zIndex: 1
                            }}>
                                {videos.map((video, index) => (
                                    <ListItem 
                                        key={video.id}
                                        sx={{
                                            py: 1,
                                            px: 2,
                                            borderRadius: 1,
                                            mb: 0.5,
                                            backgroundColor: '#1e1e1e',
                                        }}
                                    >
                                        <Typography 
                                            variant="caption" 
                                            sx={{ 
                                                color: '#8b5cf6',
                                                minWidth: '24px'
                                            }}
                                        >
                                            {index + 1}.
                                        </Typography>
                                        <Typography 
                                            variant="body2"
                                            sx={{
                                                color: '#fff',
                                                flex: 1,
                                                ml: 1,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {video.title}
                                        </Typography>
                                        <Box sx={{ 
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            ml: 2
                                        }}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={selectedUserProgress[video.id] || 0}
                                                sx={{ 
                                                    width: 60,
                                                    height: 6,
                                                    borderRadius: 3,
                                                    backgroundColor: 'rgba(139, 92, 246, 0.2)',
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: '#8b5cf6',
                                                    }
                                                }}
                                            />
                                            <Typography 
                                                variant="caption"
                                                sx={{ 
                                                    minWidth: '40px', 
                                                    textAlign: 'right',
                                                    color: 'rgba(255, 255, 255, 0.7)'
                                                }}
                                            >
                                                {selectedUserProgress[video.id] || 0}%
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};

function CourseDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { course, courseId, courseName } = location.state || {};
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [videoProgress, setVideoProgress] = useState({});
    const [userProgress, setUserProgress] = useState({});
    const [enrolledUsers, setEnrolledUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserProgress, setSelectedUserProgress] = useState({});
    const token = getToken();
    const theme = useTheme();
    const usersListRef = React.useRef(null);
    const isAdmin = isSuperAdmin() || isCompanyAdmin();
    const [courseDocuments, setCourseDocuments] = useState([]);
    const [isOracleCourse, setIsOracleCourse] = useState(false);
    const { isChatOpen, setIsChatOpen } = useContext(ChatContext);
    const { openNavBar, toggleNavBar } = useContext(NavBarContext);

    useEffect(() => {
        if (!courseId) {
            navigate('/');
            return;
        }
        fetchVideos();
    }, [courseId]);

    useEffect(() => {
        if (videos?.length) {
            const fetchAllProgress = async () => {
                try {
                    const userId = getUserId();
                    
                    // First, get all video durations from the videos
                    const videoDurations = {};
                    videos.forEach(video => {
                        if (video.duration) {
                            videoDurations[video.id] = parseFloat(video.duration);
                        }
                    });

                    const response = await fetch(`${BASE_URL}/api/course-progress/${courseId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const results = await response.json();
                    
                    // Create a map of video progress
                    const progressMap = {};
                    results.forEach(result => {
                        const watched = parseFloat(result.watched_duration) || 0;
                        const duration = parseFloat(result.total_duration) || 0;
                        
                        if (duration > 0) {
                            progressMap[result.video_id] = Math.min(Math.round((watched / duration) * 100), 100);
                        }
                    });
                    
                    console.log('Progress Map:', progressMap);
                    setVideoProgress(progressMap);
                } catch (error) {
                    console.error('Error fetching video progress:', error);
                }
            };

            fetchAllProgress();
        }
    }, [videos, courseId, token]);

    useEffect(() => {
        if (courseId) {
            console.log('Fetching enrolled users for course:', courseId);
            fetchEnrolledUsers();
        }
    }, [courseId]);

    useEffect(() => {
        if (course) {
            setIsOracleCourse(course.course_type === 'oracle');
            if (course.course_documents) {
                setCourseDocuments(course.course_documents);
            }
        }
    }, [course]);

    const fetchVideos = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getlmsvideos?course_id=${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) throw new Error('Failed to fetch videos');
            const data = await response.json();
            setVideos(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching videos:', error);
            setIsLoading(false);
        }
    };

    const handleVideoClick = (video) => {
        navigate(`/video-player`, {
            state: { 
                video,
                courseId,
                courseName,
                courseDescription: course?.course_description,
                courseType: course?.course_type,
                courseThumbnail: course?.image_path,
                courseDocuments: courseDocuments,
                videos,
                startTime: null
            }
        });
    };

    const fetchEnrolledUsers = async () => {
        try {
            // Get enrolled users for this course
            const response = await fetch(`${BASE_URL}/api/course-enrolled-users/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const users = await response.json();

            // Get progress for all enrolled users
            const progressResponse = await fetch(`${BASE_URL}/api/course-client-progress/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const progressData = await progressResponse.json();

            // Create a progress map
            const progressMap = {};
            progressData.forEach(user => {
                progressMap[user.user_id] = user.progress;
            });

            // Combine user data with progress and sort
            const enrichedUsers = users.map(user => ({
                ...user,
                progress: progressMap[user.user_id] || 0
            }));

            const sortedUsers = enrichedUsers.sort((a, b) => {
                // Sort by progress first, then by name
                if (b.progress !== a.progress) {
                    return b.progress - a.progress;
                }
                return `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`);
            });

            setEnrolledUsers(sortedUsers);
            setUserProgress(progressMap);

        } catch (error) {
            console.error('Error fetching enrolled users:', error);
        }
    };

    const fetchUserVideoProgress = async (userId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/course-progress/${courseId}?user_id=${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const results = await response.json();
            
            // Create a map of video progress
            const progressMap = {};
            results.forEach(result => {
                const watched = parseFloat(result.watched_duration) || 0;
                const duration = parseFloat(result.total_duration) || 0;
                
                if (duration > 0) {
                    progressMap[result.video_id] = Math.min(Math.round((watched / duration) * 100), 100);
                }
            });
            
            setSelectedUserProgress(progressMap);
        } catch (error) {
            console.error('Error fetching user video progress:', error);
        }
    };

    const handleUserClick = async (user) => {
        // Save the current scroll position
        const scrollPosition = usersListRef.current?.scrollTop;
        
        if (selectedUser?.user_id === user.user_id) {
            setSelectedUser(null);
            setSelectedUserProgress({});
        } else {
            setSelectedUser(user);
            await fetchUserVideoProgress(user.user_id);
        }

        // Restore the scroll position after the state update
        if (scrollPosition !== undefined) {
            setTimeout(() => {
                if (usersListRef.current) {
                    usersListRef.current.scrollTop = scrollPosition;
                }
            }, 0);
        }
    };

    // Add useEffect to handle body scroll
    useEffect(() => {
        if (isChatOpen) {
            // Prevent background scroll when chat is open
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            // Store current scroll position
            document.body.style.top = `-${window.scrollY}px`;
        } else {
            // Restore scroll when chat is closed
            const scrollY = document.body.style.top;
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.top = '';
            // Restore scroll position
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }

        // Cleanup
        return () => {
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.top = '';
        };
    }, [isChatOpen]);

    const handleChatOpen = () => {
        setIsChatOpen(true);
        if (openNavBar) {
            toggleNavBar();
        }
    };

    const handleChatClose = () => {
        setIsChatOpen(false);
    };

    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <Box sx={{ 
            backgroundColor: 'background.default', 
            minHeight: '100vh',
            display: 'flex',
            position: 'relative',
            width: '100%',
            pt: { xs: '0px', md: '24px' },
        }}>
            <CssBaseline />
            <Container 
                maxWidth="lg"
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    py: { xs: 2, md: 3 },
                    gap: 2,
                    px: { xs: 2, sm: 3 }
                }}
            >
                <IconButton 
                    onClick={() => navigate('/')}
                    sx={{ 
                        alignSelf: { xs: 'center', md: 'flex-start' },
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        transition: 'all 0.2s ease',
                        borderRadius: '8px',
                        padding: '4px 12px',
                        mb: 2,
                        zIndex: 1,
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        }
                    }}
                >
                    <ArrowBackIcon />
                    <Typography 
                        component="span"
                        sx={{ 
                            fontWeight: 500,
                            color: 'text.primary'
                        }}
                    >
                        {isOracleCourse ? 'Back to Oracles' : 'Back to Courses'}
                    </Typography>
                </IconButton>

                <Box sx={{ 
                    display: 'flex', 
                    gap: 2,
                    width: '100%',
                    position: 'relative',
                }}>
                    <Box sx={{ 
                        width: '100%',
                        maxWidth: { xs: '100%', md: '65%' },
                        mr: { xs: 0, md: 2 },
                    }}>
                        <Paper sx={{ p: 0, mb: 3, overflow: 'hidden' }}>
                            {course?.image_path && (
                                <Box
                                    component="img"
                                    src={`${BASE_URL}/uploads${course.image_path}`}
                                    alt={course.course_name}
                                    onError={(e) => {
                                        e.target.style.display = 'none';
                                    }}
                                    sx={{
                                        width: '100%',
                                        height: 200,
                                        objectFit: 'cover'
                                    }}
                                />
                            )}
                            <Box sx={{ p: 3 }}>
                                <Typography variant="h4" gutterBottom>
                                    {course?.course_name}
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3 }}>
                                    {course?.course_description}
                                </Typography>
                            </Box>
                        </Paper>

                        {/* Documents Section for Oracle Courses */}
                        {isOracleCourse && courseDocuments.length > 0 && (
                            <Paper sx={{ p: 3, mb: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Documents
                                </Typography>
                                <List>
                                    {courseDocuments.map((doc, index) => (
                                        <ListItem
                                            key={doc.id}
                                            sx={{
                                                borderBottom: index < courseDocuments.length - 1 ? 1 : 0,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <ListItemIcon>
                                                <InsertDriveFileIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={doc.document_name}
                                                secondary={new Date(doc.date_created).toLocaleDateString()}
                                            />
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                startIcon={<VisibilityIcon />}
                                                onClick={() => window.open(`${BASE_URL}/uploads${doc.document_path}`, '_blank')}
                                            >
                                                View
                                            </Button>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        )}

                        {/* Videos Section - Show only if videos exist */}
                        {videos.length > 0 && (
                            <Paper sx={{ p: 3, mb: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Course Content
                                </Typography>
                                <List sx={{ 
                                    maxHeight: '300px',
                                    overflow: 'auto',
                                    msOverflowStyle: 'none',
                                    scrollbarWidth: 'none',
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    '& .MuiListItem-root': {
                                        mb: 1.5
                                    }
                                }}>
                                    {videos.map((video, index) => (
                                        <ListItem
                                            key={video.id}
                                            onClick={() => handleVideoClick(video)}
                                            sx={{
                                                borderRadius: 1,
                                                py: 1,
                                                px: 2,
                                                position: 'relative',
                                                cursor: 'pointer',
                                                backgroundColor: 'background.paper',
                                                '&:hover': {
                                                    backgroundColor: 'action.hover',
                                                },
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                overflow: 'hidden',
                                                '&::before': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    left: 0,
                                                    top: 0,
                                                    height: '100%',
                                                    width: `${videoProgress[video.id] || 0}%`,
                                                    backgroundColor: 'rgba(200, 200, 200, 0.15)',
                                                    transition: 'width 0.3s ease-in-out'
                                                }
                                            }}
                                        >
                                            <Box sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center',
                                                flex: 1,
                                                minWidth: 0,
                                                position: 'relative',
                                                zIndex: 1
                                            }}>
                                                <Typography 
                                                    variant="body2"
                                                    sx={{ 
                                                        color: 'primary.main',
                                                        fontWeight: 500,
                                                        minWidth: '24px'
                                                    }}
                                                >
                                                    {`${index + 1}.`}
                                                </Typography>
                                                <Typography 
                                                    variant="body2"
                                                    sx={{ 
                                                        ml: 1,
                                                        fontWeight: 400,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {video.title}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ 
                                                display: 'flex',
                                                alignItems: 'center',
                                                ml: 2,
                                                mr: 1,
                                                position: 'relative',
                                                zIndex: 1
                                            }}>
                                                <Typography 
                                                    variant="caption"
                                                    sx={{ 
                                                        color: theme => videoProgress[video.id] > 0 ? 'primary.main' : 'text.secondary',
                                                        opacity: 0.8,
                                                        minWidth: '40px',
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {`${videoProgress[video.id] || 0}%`}
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        )}

                        {/* Enrolled Users Section - Modified to hide progress for no-video Oracle courses */}
                        {isAdmin && (
                            <EnrolledUsersSection 
                                enrolledUsers={enrolledUsers}
                                selectedUser={selectedUser}
                                handleUserClick={handleUserClick}
                                videos={videos}
                                selectedUserProgress={videos.length > 0 ? selectedUserProgress : null}
                                usersListRef={usersListRef}
                                showProgress={videos.length > 0}
                                userProgress={userProgress}
                            />
                        )}
                    </Box>

                    {/* Desktop Chat */}
                    <Box sx={{ 
                        display: { xs: 'none', md: 'block' },
                        width: '35%',
                        maxWidth: '400px',
                        ml: { xs: 0, md: 2 },
                        position: 'sticky',
                        top: 24,
                        height: 'fit-content',
                        maxHeight: 'calc(100vh - 48px)',
                    }}>
                        <AIChat 
                            courseId={courseId}
                            courseName={courseName}
                            currentVideo={null}
                            videos={videos}
                            isOracleCourse={isOracleCourse}
                            courseDescription={course?.course_description}
                            courseType={course?.course_type}
                            courseThumbnail={course?.image_path}
                            courseDocuments={courseDocuments}
                            isMobile={false}
                            isOpen={true}
                            onClose={handleChatClose}
                        />
                    </Box>

                    {/* Mobile Chat Bubble and Modal */}
                    <Box sx={{ 
                        display: { xs: 'block', md: 'none' },
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                        zIndex: 1000,
                    }}>
                        {!isChatOpen && (
                            <IconButton
                                onClick={handleChatOpen}
                                sx={{
                                    width: 56,
                                    height: 56,
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    boxShadow: 3,
                                    '&:hover': {
                                        backgroundColor: 'primary.dark',
                                    },
                                }}
                            >
                                <SmartToyIcon />
                            </IconButton>
                        )}

                        {isChatOpen && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'background.default',
                                    zIndex: 1200,
                                }}
                            >
                                <AIChat 
                                    courseId={courseId}
                                    courseName={courseName}
                                    currentVideo={null}
                                    videos={videos}
                                    isOracleCourse={isOracleCourse}
                                    courseDescription={course?.course_description}
                                    courseType={course?.course_type}
                                    courseThumbnail={course?.image_path}
                                    courseDocuments={courseDocuments}
                                    isMobile={true}
                                    isOpen={isChatOpen}
                                    onClose={handleChatClose}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default CourseDetails; 