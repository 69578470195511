import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
    Box, Container, Typography, Paper, TextField, Button, 
    List, ListItem, ListItemText, Divider, CircularProgress,
    LinearProgress, Snackbar, Alert, IconButton, Tooltip, MenuItem, Select, Menu, CssBaseline
} from '@mui/material';
import ReactPlayer from 'react-player';
import { getToken, getUserId } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

function ChatHistoryNavbar({ chatHistory, onThreadSelect, currentThreadId }) {
    const nonEmptyThreads = chatHistory.filter(thread => thread.messageCount > 0);

    return (
        <Box sx={{ 
            maxHeight: '200px',
            overflowY: 'auto', 
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            borderRadius: '8px',
            p: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        }}>
            {nonEmptyThreads.length > 0 ? (
                <List sx={{ 
                    width: '100%', 
                    bgcolor: 'transparent',
                    py: 0,
                    px: 0,
                    '& .MuiListItem-root': {
                        borderRadius: '8px',
                        mb: 1,
                        transition: 'background-color 0.2s ease',
                    }
                }}>
                    {nonEmptyThreads.map((thread) => (
                        <ListItem 
                            key={thread.threadId}
                            button 
                            onClick={() => onThreadSelect(thread.threadId)}
                            selected={thread.threadId === currentThreadId}
                            sx={{
                                '&.Mui-selected': {
                                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                    borderLeft: '3px solid',
                                    borderColor: 'primary.main',
                                    '&:hover': {
                                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                    }
                                },
                                width: '100%',
                                py: 1.5,
                                pl: 2,
                                pr: 2,
                                backgroundColor: 'background.paper',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                }
                            }}
                        >
                            <ListItemText 
                                primary={
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ fontWeight: thread.threadId === currentThreadId ? 600 : 400 }}>
                                            {new Date(thread.createdAt).toLocaleDateString(undefined, { 
                                                month: 'short', 
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </Typography>
                                        <Typography variant="caption" sx={{ 
                                            color: 'text.secondary', 
                                            bgcolor: 'action.hover',
                                            px: 1,
                                            py: 0.2,
                                            borderRadius: '12px',
                                            fontSize: '0.65rem'
                                        }}>
                                            {thread.messageCount}
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <Typography 
                                        variant="body2" 
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            color: 'text.secondary',
                                            mt: 0.5,
                                            fontSize: '0.8rem'
                                        }}
                                    >
                                        {thread.lastMessage}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    height: '100%',
                    p: 2,
                    textAlign: 'center'
                }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        No chat history yet
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Start a new conversation to create chat history
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

function SupportingDocuments({ documents }) {
    if (!documents || documents.length === 0) return null;

    return (
        <Box sx={{ mt: { xs: 1, sm: 3 } }}>
            <Paper sx={{ 
                p: { xs: 1, sm: 2 },
                backgroundColor: 'background.paper'
            }}>
                <Typography variant="h6" gutterBottom>
                    Supporting Documents
                </Typography>
                <List 
                    dense 
                    sx={{
                        // Calculate height based on number of documents
                        height: documents.length <= 2 ? 'auto' : '106px', // 58px per item (48px height + 10px margin)
                        overflowY: documents.length > 2 ? 'auto' : 'visible',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }}
                >
                    {documents.map((doc, index) => (
                        <ListItem 
                            key={index}
                            component="a"
                            href={`${BASE_URL}/${doc.path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ 
                                textDecoration: 'none',
                                color: 'primary.main',
                                borderRadius: 1,
                                transition: 'all 0.2s ease',
                                cursor: 'pointer',
                                mb: 1,
                                height: '38px', // Fixed height for each item
                                backgroundColor: 'action.hover',
                                '&:hover': { 
                                    backgroundColor: 'action.selected',
                                }
                            }}
                        >
                            <ListItemText
                                primary={doc.name}
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        fontWeight: 500,
                                        color: 'text.primary'
                                    }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Box>
    );
}

function VideoPlayer() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const { 
        video, 
        courseId, 
        courseName, 
        videos, 
        startTime,
        chatState
    } = location.state || {};
    console.log('Video being passed to player:', video); // Debug log
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [lmsThreadId, setLMSThreadId] = useState(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [showChatHistory, setShowChatHistory] = useState(false);
    const [player, setPlayer] = useState(null);
    const lastSentDurationRef = useRef({});
    const messagesEndRef = useRef(null);
    const progressInterval = useRef(null);
    const lastUpdateTime = useRef({});
    const [videoMenuAnchor, setVideoMenuAnchor] = useState(null);
    const [videoProgress, setVideoProgress] = useState({});
    const [lastWatchedPosition, setLastWatchedPosition] = useState(0);
    const [isRecording, setIsRecording] = useState(false);
    const [stopRecording, setStopRecording] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [audioContext, setAudioContext] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [isTTSEnabled, setIsTTSEnabled] = useState(false);
    const [audioQueue, setAudioQueue] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio());
    const audioChunks = useRef({});
    const [ttsVoice, setTTSVoice] = useState('alloy');
    const [voiceMenuAnchor, setVoiceMenuAnchor] = useState(null);
    const [isAudioProcessing, setIsAudioProcessing] = useState(false);
    
    // OpenAI TTS voices
    const ttsVoices = [
        { id: 'alloy', name: 'Alloy' },
        { id: 'echo', name: 'Echo' },
        { id: 'fable', name: 'Fable' },
        { id: 'onyx', name: 'Onyx' },
        { id: 'nova', name: 'Nova' },
        { id: 'shimmer', name: 'Shimmer' },
        { id: 'coral', name: 'Coral' },
        { id: 'sage', name: 'Sage' },
        { id: 'ash', name: 'Ash' },
        { id: 'ballad', name: 'Ballad' }
    ];
    
    // Handle voice menu
    const handleVoiceMenuOpen = (event) => {
        setVoiceMenuAnchor(event.currentTarget);
    };
    
    const handleVoiceMenuClose = () => {
        setVoiceMenuAnchor(null);
    };
    
    const handleVoiceSelect = (voiceId) => {
        setTTSVoice(voiceId);
        handleVoiceMenuClose();
    };
    
    const playNextAudio = () => {
        if (audioQueue.length === 0) return;
        
        setIsPlaying(true);
        const currentAudioItem = audioQueue[0];
        const currentAudio = typeof currentAudioItem === 'string' 
            ? currentAudioItem 
            : currentAudioItem.url;
        
        console.log(`Playing audio segment ${audioQueue[0].index + 1}/${audioQueue[0].total}`);
        
        audioRef.current.src = currentAudio;
        audioRef.current.play().catch(e => {
            console.error('Error playing audio:', e);
            setIsPlaying(false);
            setAudioQueue(prev => prev.slice(1));
        });
    };

    const stopAudioPlayback = (shouldClearQueue = true) => {
        console.log('Stopping audio playback');
        
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.src = '';
        }
        
        if (shouldClearQueue) {
            audioQueue.forEach(item => {
                const url = typeof item === 'string' ? item : item?.url;
                if (url) URL.revokeObjectURL(url);
            });
            
            setAudioQueue([]);
        }
        
        setIsPlaying(false);
        
        Object.keys(audioChunks.current).forEach(key => {
            delete audioChunks.current[key];
        });
    };

    const playAudio = (base64Audio, metadata = {}) => {
        if (isPlaying) {
            stopAudioPlayback(false);
        }
        
        // Clear audio processing state when audio starts playing
        setIsAudioProcessing(false);
        
        // Remove audio processing indicator from all messages
        setMessages(prev => prev.map(msg => 
            msg.isAudioProcessing ? { ...msg, isAudioProcessing: false } : msg
        ));
        
        if (metadata.isChunk) {
            handleStreamingAudioChunk(base64Audio, metadata);
        } else {
            const audioBlob = new Blob(
                [Uint8Array.from(atob(base64Audio), c => c.charCodeAt(0))], 
                { type: 'audio/mp3' }
            );
            const audioUrl = URL.createObjectURL(audioBlob);
            
            setAudioQueue(prev => [...prev, { 
                url: audioUrl, 
                ...metadata 
            }]);
        }
    };
    
    const handleStreamingAudioChunk = (base64Audio, metadata) => {
        const { index, total, isLastChunk } = metadata;
        const chunkId = `chunk-${index}`;
        
        // Convert base64 to array buffer
        const binaryString = atob(base64Audio);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        
        // Store the chunk
        if (!audioChunks.current[chunkId]) {
            audioChunks.current[chunkId] = {
                chunks: [],
                complete: false,
                index,
                total
            };
        }
        
        audioChunks.current[chunkId].chunks.push(bytes);
        
        // If this is the last chunk, mark it as complete
        if (isLastChunk) {
            audioChunks.current[chunkId].complete = true;
        }
        
        // Start playback immediately if not already playing
        if (!isPlaying) {
            startStreamingPlayback(chunkId);
        }
    };

    // Function to start playback of streaming audio
    const startStreamingPlayback = (chunkId) => {
        if (!audioChunks.current[chunkId]) return;
        
        const { chunks } = audioChunks.current[chunkId];
        if (chunks.length === 0) return;
        
        // Stop any current playback
        if (isPlaying) {
            audioRef.current.pause();
        }
        
        setIsPlaying(true);
        
        // Combine chunks we have so far
        const combinedChunks = new Blob(chunks, { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(combinedChunks);
        
        // Play what we have
        audioRef.current.src = audioUrl;
        audioRef.current.play().catch(e => {
            console.error('Error playing streaming audio:', e);
            setIsPlaying(false);
            
            // Clean up
            URL.revokeObjectURL(audioUrl);
            delete audioChunks.current[chunkId];
        });
        
        console.log(`Started playing streaming audio segment ${chunkId}`);
    };

    const toggleTTS = () => {
        const newTTSState = !isTTSEnabled;
        setIsTTSEnabled(newTTSState);
        
        // If turning off TTS, stop any current playback
        if (!newTTSState) {
            stopAudioPlayback(true);
        }
        
        // Note: We intentionally don't modify isRecording state here
        // to avoid automatic toggling of voice recording when TTS is toggled
    };

    const token = getToken();
    const userId = getUserId();

    useEffect(() => {
        if (!video || !courseId) {
            navigate('/courses');
            return;
        }
        
        // Reset player when video changes
        setPlayer(null);
        
        // Initialize chat state if coming from AIChat
        if (location.state?.preserveChat && chatState) {
            setMessages(chatState.messages);
            setLMSThreadId(chatState.lmsThreadId);
            setChatHistory(chatState.chatHistory);
            setIsLoadingMessages(false); // Important: prevent loading state
        } else {
            loadThreadAndMessages();
            fetchChatHistory();
        }
    }, [courseId, video?.id, location.state?.preserveChat, chatState]);

    useEffect(() => {
        const fetchAllProgress = async () => {
            try {
                // First, get all video durations from the videos prop
                const videoDurations = {};
                videos?.forEach(video => {
                    if (video.duration) {
                        videoDurations[video.id] = parseFloat(video.duration);
                    }
                });

                const response = await fetch(`${BASE_URL}/api/course-progress/${courseId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const results = await response.json();
                
                // Create a map of video progress
                const progressMap = {};
                results.forEach(result => {
                    const watched = parseFloat(result.watched_duration) || 0;
                    const duration = parseFloat(result.total_duration) || 0;
                    
                    if (duration > 0) {
                        progressMap[result.video_id] = Math.min(Math.round((watched / duration) * 100), 100);
                    }
                });
                
                setVideoProgress(progressMap);
            } catch (error) {
                console.error('Error fetching video progress:', error);
            }
        };

        if (videos?.length) {
            fetchAllProgress();
        }
    }, [videos, courseId, token]);

    useEffect(() => {
        if (startTime && player) {
           // console.log('Seeking to startTime:', startTime);
            // Add a small delay to ensure player is ready
            const timeoutId = setTimeout(() => {
                player.seekTo(parseFloat(startTime));
                const internalPlayer = player.getInternalPlayer();
                if (video?.videoType === 'youtube') {
                    internalPlayer.playVideo();
                } else {
                    internalPlayer.play().catch(error => {
                        console.error('Error playing video:', error);
                    });
                }
            }, 500);
            
            return () => clearTimeout(timeoutId);
        }
    }, [video?.id, player, startTime]); // Add video.id to dependencies

    useEffect(() => {
        const fetchLastPosition = async () => {
            if (!video?.id) return;
            
            try {
                const response = await fetch(`${BASE_URL}/api/getvideoprogress/${video.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                
                if (data.watched_duration) {
                    setLastWatchedPosition(data.watched_duration);
                }
            } catch (error) {
                console.error('Error fetching last watched position:', error);
            }
        };

        fetchLastPosition();
    }, [video?.id, token]);

    const loadThreadAndMessages = async () => {
        try {
            const threadId = await createThread(courseName);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchMessages(threadId);
            }
        } catch (error) {
            console.error('Error loading thread and messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const createThread = async (course_name) => {
        try {
            const response = await fetch(`${BASE_URL}/api/createLMSthread`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ course_name })
            });
            
            if (!response.ok) throw new Error('Failed to create thread');
            const data = await response.json();
            return data.threadId;
        } catch (error) {
            console.error('Error creating thread:', error);
            return null;
        }
    };

    const fetchMessages = async (threadId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSmessages/${threadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            
            if (!response.ok) throw new Error('Failed to fetch messages');
            const data = await response.json();
            setMessages(data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const fetchChatHistory = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSChatHistory?course_name=${courseName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) throw new Error('Failed to fetch chat history');
            const data = await response.json();
            setChatHistory(data.chatHistory);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const handleThreadSelect = async (threadId) => {
        // Stop any current audio playback
        stopAudioPlayback(true);
        
        // Reset audio processing state
        setIsAudioProcessing(false);
        
        // Safely stop recording if active but don't start new recording
        if (isRecording && stopRecording) {
            stopRecording();
            setStopRecording(null);
            setIsRecording(false);
            
            // Safely clean up audio stream
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
                setAudioStream(null);
            }
        }
        
        setLMSThreadId(threadId);
        setIsLoadingMessages(true);
        try {
            await fetchMessages(threadId);
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const sendMessage = async () => {
        if (!inputMessage.trim() || !lmsThreadId) return;

        // Stop any current audio playback
        stopAudioPlayback(true);

        const newMessage = { role: 'user', content: inputMessage };
        setMessages(prev => [...prev, newMessage]);
        setInputMessage('');
        setIsLoading(true);

        const placeholderId = `placeholder-${Date.now()}`;
        let isStreaming = false;
        
        try {
            // Add loading message immediately
            setMessages(prev => [...prev, { 
                role: 'assistant', 
                content: '', 
                id: placeholderId,
                isLoading: true // Add this flag
            }]);

            const response = await fetch(`${BASE_URL}/api/sendLMSchatmessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    threadId: lmsThreadId,
                    message: inputMessage,
                    course_name: courseName,
                    course_id: courseId,
                    video_id: video?.id,
                    enableTTS: isTTSEnabled,
                    ttsVoice: ttsVoice
                }),
            });

            const reader = response.body.getReader();
            let accumulatedContent = '';
            let incompleteChunk = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = incompleteChunk + new TextDecoder().decode(value);
                incompleteChunk = '';
                
                const eventDelimiter = '\n\n';
                let chunkParts = chunk.split(eventDelimiter);
                
                if (!chunk.endsWith(eventDelimiter) && chunkParts.length > 0) {
                    incompleteChunk = chunkParts.pop() || '';
                }

                for (const part of chunkParts) {
                    if (part.trim() === '') continue;
                    
                    const dataMatch = part.match(/^data: (.+)$/m);
                    if (!dataMatch) continue;
                    
                    const dataString = dataMatch[1];
                    
                    try {
                        const data = JSON.parse(dataString);
                        
                        switch (data.type) {
                            case 'chunk':
                                if (!isStreaming) {
                                    isStreaming = true;
                                    setIsLoading(false);
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, content: '', isLoading: false }
                                            : msg
                                    ));
                                }
                                accumulatedContent += data.content;
                                setMessages(prev => prev.map(msg => 
                                    msg.id === placeholderId 
                                        ? { ...msg, content: accumulatedContent }
                                        : msg
                                ));
                                break;

                            case 'audio_processing':
                                setIsAudioProcessing(data.processing);
                                if (data.processing) {
                                    // Update the assistant message to show audio processing
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, isAudioProcessing: true }
                                            : msg
                                    ));
                                } else {
                                    // Audio processing is complete
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, isAudioProcessing: false }
                                            : msg
                                    ));
                                }
                                break;

                            case 'audio':
                                if (isTTSEnabled && data.audio) {
                                    playAudio(data.audio, {
                                        index: data.index !== undefined ? data.index : 0,
                                        total: data.total || 1
                                    });
                                }
                                break;

                            case 'error':
                                console.error('Stream error:', data.error);
                                setSnackbarMessage(data.error);
                                setSnackbarSeverity('error');
                                setOpenSnackbar(true);
                                throw new Error(data.error);

                            case 'end':
                                const finalContent = data.content || accumulatedContent;
                                setMessages(prev => prev.map(msg => 
                                    msg.id === placeholderId 
                                        ? { role: 'assistant', content: finalContent }
                                        : msg
                                ));
                                // Only scroll to bottom after stream ends
                                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
                                // Fetch updated chat history
                                await fetchChatHistory();
                                return;

                            case 'tool_start':
                            case 'tool_input':
                            case 'tool_output':
                                // Handle tool-related events if needed
                                break;
                        }
                    } catch (e) {
                        console.error('Error parsing SSE data:', e, '\nData causing error:\n', dataString.substring(0, 200) + '...');
                    }
                }
            }

            await fetchChatHistory();
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => prev.filter(msg => msg.id !== placeholderId));
        } finally {
            setIsLoading(false);
        }
    };

    const onReady = (player) => {
        setPlayer(player);
        let duration;
        let hasInitiallySeeked = false;  // Flag to track if we've already seeked
        
        // Handle duration differently for MP4 and YouTube
        if (video?.videoType === 'youtube') {
            duration = player.getDuration();
        } else {
            duration = player.getInternalPlayer().duration;
        }
        
        // Only seek to startTime if provided (for navigation between videos)
        if (startTime) {
           // console.log('Seeking to startTime:', startTime);
            player.seekTo(parseFloat(startTime));
            
            // Auto-play after seeking for navigation
            const internalPlayer = player.getInternalPlayer();
            if (video?.videoType === 'youtube') {
                internalPlayer.playVideo();
            } else {
                internalPlayer.play().catch(error => {
                    console.error('Error playing video:', error);
                });
            }
        }
        
        // Add event listeners for play
        const internalPlayer = player.getInternalPlayer();
        if (video?.videoType === 'youtube') {
            internalPlayer.addEventListener('onStateChange', (event) => {
                // YouTube state 1 means playing
                if (event.data === 1 && !startTime && lastWatchedPosition > 0 && !hasInitiallySeeked) {
                    hasInitiallySeeked = true;
                    player.seekTo(lastWatchedPosition);
                    internalPlayer.playVideo();
                }
            });
        } else {
            internalPlayer.addEventListener('play', () => {
                // For MP4, check if we should seek to last position
                if (!startTime && lastWatchedPosition > 0 && !hasInitiallySeeked && internalPlayer.currentTime < 1) {
                    hasInitiallySeeked = true;
                    player.seekTo(lastWatchedPosition);
                    internalPlayer.play();
                }
            });
        }
        
        // console.log('Video loaded:', {
        //     type: video?.videoType,
        //     duration,
        //     videoId: video.id,
        //     startTime,
        //     lastWatchedPosition
        // });
        
        if (progressInterval.current) {
            clearInterval(progressInterval.current);
        }

        progressInterval.current = setInterval(() => {
            let currentTime;
            
            // Get current time based on video type
            if (video?.videoType === 'youtube') {
                currentTime = player.getCurrentTime();
            } else {
                currentTime = player.getInternalPlayer().currentTime;
            }
            
            const videoId = video.id;
            
            const lastUpdate = lastUpdateTime.current[videoId] || 0;
            if (currentTime - lastUpdate >= 2) {
                updateVideoProgress(currentTime, videoId, duration);
                lastUpdateTime.current[videoId] = currentTime;
            }
        }, 3000);
    };

    // Add event listeners for MP4 videos
    const onDuration = (duration) => {
       // console.log('Duration updated:', duration);
        if (!video?.videoType || video.videoType !== 'youtube') {
            updateVideoProgress(0, video.id, duration);
        }
    };

    const onProgress = ({ played, playedSeconds }) => {
        if (!video?.videoType || video.videoType !== 'youtube') {
            const videoId = video.id;
            const lastUpdate = lastUpdateTime.current[videoId] || 0;
            
            if (playedSeconds - lastUpdate >= 2) {
                console.log('MP4 progress:', {
                    videoId,
                    playedSeconds,
                    played: played * 100
                });
                updateVideoProgress(playedSeconds, videoId, player?.getInternalPlayer()?.duration);
                lastUpdateTime.current[videoId] = playedSeconds;
            }
        }
    };

    useEffect(() => {
        return () => {
            if (progressInterval.current) {
                clearInterval(progressInterval.current);
            }
        };
    }, []);

    const updateVideoProgress = async (currentTime, videoId, totalDuration) => {
        try {
            const watchedTime = Math.round(currentTime * 100) / 100;
            const duration = Math.round(totalDuration * 100) / 100;

           // console.log('Sending progress update:', {
           //     videoId,
           //     watchedTime,
           //     duration,
           //     userId
           // });

            const response = await fetch(`${BASE_URL}/api/videoprogress`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    userId,
                    videoId,
                    watchedDuration: watchedTime,
                    totalDuration: duration
                }),
            });
            
            const data = await response.json();
            if (data.success) {
                lastSentDurationRef.current[videoId] = watchedTime;
                // console.log('Progress updated successfully:', {
                //     videoId,
                //     watchedTime,
                //     totalProgress: (watchedTime / duration) * 100
                // });
            }
        } catch (error) {
            console.error('Error updating video progress:', error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    // Audio queue management effect
    useEffect(() => {
        if (audioQueue.length > 0 && !isPlaying) {
            playNextAudio();
        }
    }, [audioQueue, isPlaying]);

    useEffect(() => {
        const handleEnded = () => {
            if (audioQueue.length > 0) {
                const currentItem = audioQueue[0];
                const url = typeof currentItem === 'string' ? currentItem : currentItem?.url;
                if (url) {
                    URL.revokeObjectURL(url);
                }
                
                setAudioQueue(prev => prev.slice(1));
                setIsPlaying(false);
            } else {
                setIsPlaying(false);
            }
        };

        audioRef.current.addEventListener('ended', handleEnded);
        
        return () => {
            audioRef.current.removeEventListener('ended', handleEnded);
        };
    }, [audioQueue]);

    // Cleanup audio resources on component unmount
    useEffect(() => {
        return () => {
            // Cleanup audio on component unmount
            if (audioRef.current) {
                audioRef.current.pause();
                if (audioRef.current.src) {
                    URL.revokeObjectURL(audioRef.current.src);
                }
            }
            
            // Cleanup all URLs in the queue
            audioQueue.forEach(item => {
                const url = typeof item === 'string' ? item : item?.url;
                if (url) URL.revokeObjectURL(url);
            });
            
            // Stop any active recording
            if (stopRecording) {
                stopRecording();
            }
            
            // Ensure all media streams are stopped
            if (audioStream) {
                audioStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            
            // Close audio context if open
            if (audioContext && audioContext.state !== 'closed') {
                audioContext.close().catch(err => {
                    console.error('Error closing audio context on unmount:', err);
                });
            }
        };
    }, [audioQueue, stopRecording, audioStream, audioContext]);

    // const scrollToBottom = () => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // };

    // useEffect(() => {
    //     scrollToBottom();
    // }, [messages]);

    const renderMessage = (msg, index) => {
        const handleTimestampClick = (timestamp, videoTitle) => {
            const convertToSeconds = (timestamp) => {
                const parts = timestamp.split(':');
                let seconds = 0;
                
                if (parts.length === 3) {
                    seconds = parseInt(parts[0], 10) * 3600 + 
                            parseInt(parts[1], 10) * 60 + 
                            parseFloat(parts[2]);
                } else if (parts.length === 2) {
                    seconds = parseInt(parts[0], 10) * 60 + 
                            parseFloat(parts[1]);
                } else {
                    seconds = parseFloat(parts[0]);
                }
                
                return seconds;
            };
            
            const timeInSeconds = convertToSeconds(timestamp);
            
            if (videoTitle) {
                // First, check if it's in "Video XX" format
                const videoNumberMatch = videoTitle.match(/^Video (\d+)$/);
                if (videoNumberMatch) {
                    const videoId = parseInt(videoNumberMatch[1]);
                    const targetVideo = videos?.find(v => v.id === videoId);
                    if (targetVideo && targetVideo.id !== video.id) {
                        // Reset player before navigation
                        setPlayer(null);
                        navigate(`/video-player`, {
                            state: { 
                                video: targetVideo,
                                courseId,
                                courseName,
                                videos,
                                startTime: timeInSeconds,
                                preserveChat: true,
                                chatState: {
                                    messages,
                                    lmsThreadId,
                                    chatHistory
                                }
                            }
                        });
                        return;
                    }
                }

                // If not "Video XX" format, try to find by title
                const titleMatch = videoTitle.replace(/^.*? - /, '').trim();
                const bestMatch = findBestMatchingVideo(titleMatch);
                
                if (bestMatch && bestMatch.id !== video.id) {
                    // Reset player before navigation
                    setPlayer(null);
                    navigate(`/video-player`, {
                        state: { 
                            video: bestMatch,
                            courseId,
                            courseName,
                            videos,
                            startTime: timeInSeconds,
                            preserveChat: true,
                            chatState: {
                                messages,
                                lmsThreadId,
                                chatHistory
                            }
                        }
                    });
                    return;
                }
            }
            
            // If no video match or same video, seek in current video
            if (player) {
                player.seekTo(timeInSeconds);
                const internalPlayer = player.getInternalPlayer();
                if (video?.videoType === 'youtube') {
                    internalPlayer.playVideo();
                } else {
                    internalPlayer.play();
                }
            }
        };

        // Update the formatTimestamps function to handle course document references
        const formatTimestamps = (text) => {
            // First handle course document references
            const courseDocRegex = /\[([^\]]+?) - Course Document\]/g;
            let processedText = text.replace(courseDocRegex, (match, filename) => {
                return `[${filename}](${BASE_URL}/uploads/course-docs/${filename})`;
            });

            // Then handle existing file references (video documents)
            const fileReferenceRegex = /\[([^\]]+?) - Video (\d+)\]/g;
            let lastFileRef = null;
            
            processedText = processedText.replace(fileReferenceRegex, (match, filename, videoId) => {
                const currentFileRef = `${filename}-${videoId}`;
                
                if (currentFileRef === lastFileRef) {
                    return '';
                }
                
                lastFileRef = currentFileRef;
                
                const targetVideo = videos?.find(v => v.id === parseInt(videoId));
                if (targetVideo?.supportingDocuments) {
                    const document = targetVideo.supportingDocuments.find(doc => 
                        doc.name.includes(filename) || doc.path.includes(filename)
                    );
                    if (document) {
                        return `[${document.name}](${BASE_URL}/${document.path})`;
                    }
                }
                return match;
            });

            // Remove any trailing dots after file references
            processedText = processedText.replace(/\s*\.\s*(?=\n|$)/g, '');

            // Then handle timestamps
            const timestampRegex = /[\[\(]((?:\d{1,2}:)?\d{1,2}:\d{1,2}(?:\.\d{1,3})?)(?: - ([^)\]]+))?[\]\)]/g;
            const parts = processedText.split(timestampRegex);
            
            return parts.map((part, i) => {
                if (i % 3 === 1) { // Timestamp
                    const timestamp = part;
                    const videoTitle = parts[i + 1];
                    
                    // If it's a direct video title reference (not "Video XX" format)
                    let displayTitle = videoTitle;
                    if (videoTitle) {
                        if (videoTitle.match(/^Video \d+$/)) {
                            // Handle "Video XX" format
                            const videoNum = parseInt(videoTitle.replace('Video ', ''));
                            const targetVideo = videos?.find(v => v.id === videoNum);
                            displayTitle = targetVideo?.title || videoTitle;
                        } else {
                            // For direct title references, try to find the matching video
                            const bestMatch = findBestMatchingVideo(videoTitle);
                            displayTitle = bestMatch?.title || videoTitle;
                        }
                    }

                    return (
                        <Button
                            key={i}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleTimestampClick(timestamp, videoTitle);
                            }}
                            sx={{
                                color: 'primary.main',
                                textTransform: 'none',
                                p: '0 4px',
                                minWidth: 'auto',
                                fontWeight: 'inherit',
                                fontSize: 'inherit',
                                '&:hover': {
                                    backgroundColor: 'action.hover'
                                }
                            }}
                        >
                            {displayTitle ? `(${timestamp} - ${displayTitle})` : `(${timestamp})`}
                        </Button>
                    );
                } else if (i % 3 === 2) { // Video ID/Title - skip it as it's handled above
                    return null;
                }
                
                // Handle any markdown-style links in the text
                const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
                const linkParts = part.split(linkRegex);
                
                return linkParts.map((linkPart, j) => {
                    if (j % 3 === 1) { // Link text
                        return (
                            <Button
                                key={`link-${j}`}
                                component="a"
                                href={linkParts[j + 1]}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: 'primary.main',
                                    textTransform: 'none',
                                    p: '0 4px',
                                    minWidth: 'auto',
                                    fontWeight: 'inherit',
                                    fontSize: 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                {linkPart}
                            </Button>
                        );
                    } else if (j % 3 === 2) { // Link URL - skip it
                        return null;
                    }
                    return <span key={`text-${j}`}>{formatBoldText(linkPart)}</span>;
                });
            });
        };

        // Helper function to format text with bold sections
        const formatBoldText = (text) => {
            const parts = text.split(/(\*\*.*?\*\*)/g);
            return parts.map((part, i) => {
                if (part.startsWith('**') && part.endsWith('**')) {
                    return (
                        <Typography
                            key={i}
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {part.slice(2, -2)}
                        </Typography>
                    );
                }
                return <span key={i}>{part}</span>;
            });
        };

        // Helper function to format numbered lists and paragraphs
        const formatContent = (content) => {
            return content.split('\n').map((paragraph, i) => {
                const numberMatch = paragraph.match(/^(\d+\.\s*)(.*)/);
                
                if (numberMatch) {
                    return (
                        <Box key={i} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                            <Typography component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                {numberMatch[1]}
                            </Typography>
                            <Typography component="span">
                                {formatTimestamps(numberMatch[2])}
                            </Typography>
                        </Box>
                    );
                }
                
                return (
                    <Typography 
                        key={i} 
                        variant="body1" 
                        paragraph={true}
                        sx={{ mb: 1 }}
                    >
                        {formatTimestamps(paragraph)}
                    </Typography>
                );
            });
        };

        return (
            <Box 
                key={index} 
                sx={{ 
                    mb: 2, 
                    p: 2.5, 
                    backgroundColor: msg.role === 'user' 
                        ? theme.palette.action.selected
                        : theme.palette.background.paper,
                    borderRadius: 2,
                    maxWidth: '85%',
                    width: 'fit-content',
                    alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                }}
            >
                <Typography 
                    variant="subtitle2" 
                    sx={{ 
                        mb: 0.5,
                        color: msg.role === 'user' ? 'primary.main' : 'secondary.main',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    {msg.role === 'user' ? 'You' : 'AI Assistant'}
                    {isTTSEnabled && msg.role === 'assistant' && msg.isAudioProcessing && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AudiotrackIcon sx={{ fontSize: 18, color: 'primary.main', mr: 0.5 }} />
                            <CircularProgress size={16} sx={{ color: 'primary.main' }} />
                        </Box>
                    )}
                </Typography>
                {msg.isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size={20} />
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Thinking...
                        </Typography>
                    </Box>
                ) : (
                    formatContent(msg.content)
                )}
            </Box>
        );
    };

    const formatYouTubeUrl = (url) => {
        if (!url) return '';
        
        // Only try to parse if it's a YouTube URL
        if (url && url.includes('youtube.com')) {
            try {
                const urlObj = new URL(url);
                const searchParams = new URLSearchParams(urlObj.search);
                
                // If it's a playlist URL and has a video index
                if (searchParams.has('list') && searchParams.has('v')) {
                    const videoId = searchParams.get('v');
                    const playlistId = searchParams.get('list');
                    const index = searchParams.get('index');
                    
                    // Include both video ID and playlist ID, and maintain the index
                    return `https://www.youtube.com/watch?v=${videoId}&list=${playlistId}&index=${index}`;
                }
                
                return url;
            } catch (error) {
                console.error('Error formatting YouTube URL:', error);
                return url;
            }
        }
        return url;
    };

    // Add this helper function at the component level
    const findBestMatchingVideo = (searchTitle) => {
        if (!searchTitle || !videos) return null;

        const calculateSimilarity = (str1, str2) => {
            const s1 = str1.toLowerCase().replace(/[^a-z0-9]/g, '');
            const s2 = str2.toLowerCase().replace(/[^a-z0-9]/g, '');
            
            let matches = 0;
            const minLen = Math.min(s1.length, s2.length);
            
            for (let i = 0; i < minLen; i++) {
                if (s1[i] === s2[i]) matches++;
            }
            
            return matches / Math.max(s1.length, s2.length);
        };

        let bestMatch = null;
        let bestSimilarity = 0;

        // Clean the search title (remove any timestamp prefix if present)
        const cleanSearchTitle = searchTitle.replace(/^.*? - /, '').trim();

        videos.forEach(v => {
            const similarity = calculateSimilarity(v.title, cleanSearchTitle);
            if (similarity > bestSimilarity && similarity > 0.5) {
                bestSimilarity = similarity;
                bestMatch = v;
            }
        });

        return bestMatch;
    };

    // Update or add this useEffect to handle scrolling
    useEffect(() => {
        // Only scroll on user messages
        const lastMessage = messages[messages.length - 1];
        if (lastMessage?.role === 'user') {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handlePreviousVideo = () => {
        const currentIndex = videos.findIndex(v => v.id === video.id);
        if (currentIndex > 0) {
            navigate(`/video-player`, {
                state: { 
                    video: videos[currentIndex - 1],
                    courseId,
                    courseName,
                    videos,
                    preserveChat: true,
                    chatState: {
                        messages,
                        lmsThreadId,
                        chatHistory
                    },
                    startTime: null
                }
            });
        }
    };

    const handleNextVideo = () => {
        const currentIndex = videos.findIndex(v => v.id === video.id);
        if (currentIndex < videos.length - 1) {
            navigate(`/video-player`, {
                state: { 
                    video: videos[currentIndex + 1],
                    courseId,
                    courseName,
                    videos,
                    preserveChat: true,
                    chatState: {
                        messages,
                        lmsThreadId,
                        chatHistory
                    },
                    startTime: null
                }
            });
        }
    };

    // Also update the handleVideoSelect function if you have one
    const handleVideoSelect = (selectedVideo) => {
        navigate(`/video-player`, {
            state: { 
                video: selectedVideo,
                courseId,
                courseName,
                videos,
                preserveChat: true,
                startTime: null  // Reset startTime when selecting a video
            }
        });
    };

    const startNewChat = async () => {
        // Stop any current audio playback
        stopAudioPlayback(true);
        
        // Reset audio processing state
        setIsAudioProcessing(false);
        
        // Safely stop recording if active but don't start new recording
        if (isRecording && stopRecording) {
            stopRecording();
            setStopRecording(null);
            setIsRecording(false);
            
            // Safely clean up audio stream
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
                setAudioStream(null);
            }
        }
        
        setIsLoadingMessages(true);
        setMessages([]);
        try {
            const threadId = await createThread(courseName);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchChatHistory();
            }
        } catch (error) {
            console.error('Error starting new chat:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const startVoiceRecognition = async () => {
        try {
            // If already recording, stop it
            if (isRecording) {
                if (stopRecording) {
                    stopRecording();
                    setStopRecording(null);
                }

                if (audioStream) {
                    audioStream.getTracks().forEach(track => {
                        track.stop();
                        console.log('Stopping existing track:', track.kind);
                    });
                    setAudioStream(null);
                }
                
                setIsRecording(false);
                setIsProcessing(false);
                setAudioContext(null);
                return;
            }

            // Starting new recording
            setIsProcessing(false);
            setAudioContext(null);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setAudioStream(stream);
            setIsRecording(true);

            const wsUrl = `${BASE_URL.replace('http', 'ws')}/ws`;
            const socket = new WebSocket(wsUrl);
            
            const newAudioContext = new AudioContext({
                sampleRate: 44100
            });
            setAudioContext(newAudioContext);
            
            const source = newAudioContext.createMediaStreamSource(stream);
            const processor = newAudioContext.createScriptProcessor(4096, 1, 1);

            source.connect(processor);
            processor.connect(newAudioContext.destination);

            let isSocketReady = false;

            socket.onopen = () => {
                console.log('WebSocket connection established');
                socket.send(JSON.stringify({
                    type: 'identify',
                    userId: token
                }));
            };

            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('Received WebSocket message:', data);
                
                if (data.type === 'connected') {
                    socket.send(JSON.stringify({ type: 'start_speech' }));
                    isSocketReady = true;
                } else if (data.type === 'transcript') {
                    console.log('Received transcript:', data.transcript);
                    setIsProcessing(true);
                    if (data.transcript && data.transcript.trim()) {
                        if (data.isFinal) {
                            setInputMessage(prevMessage => prevMessage + ' ' + data.transcript.trim());
                            setIsProcessing(false);
                        }
                    }
                } else if (data.type === 'speech_error') {
                    console.error('Speech recognition error:', data.error);
                    setIsProcessing(false);
                    cleanup();
                } else if (data.type === 'speech_end') {
                    console.log('Speech recognition ended');
                    setIsProcessing(false);
                }
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                cleanup();
            };

            processor.onaudioprocess = (e) => {
                if (isSocketReady && socket.readyState === WebSocket.OPEN) {
                    const inputData = e.inputBuffer.getChannelData(0);
                    const pcmData = new Int16Array(inputData.length);
                    for (let i = 0; i < inputData.length; i++) {
                        const s = Math.max(-1, Math.min(1, inputData[i]));
                        pcmData[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
                    }
                    socket.send(pcmData.buffer);
                }
            };

            const cleanup = async () => {
                console.log('Cleaning up recording resources...');
                
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ type: 'stop_speech' }));
                    socket.close();
                }
                
                if (processor) {
                    try {
                        processor.disconnect();
                        console.log('Processor disconnected');
                    } catch (err) {
                        console.error('Error disconnecting processor:', err);
                    }
                }
                
                if (source) {
                    try {
                        source.disconnect();
                        console.log('Source disconnected');
                    } catch (err) {
                        console.error('Error disconnecting source:', err);
                    }
                }
                
                if (audioStream) {
                    audioStream.getTracks().forEach(track => {
                        track.stop();
                        console.log('Audio track stopped:', track.kind);
                    });
                    setAudioStream(null);
                }
                
                if (newAudioContext && newAudioContext.state !== 'closed') {
                    try {
                        await newAudioContext.close();
                        console.log('AudioContext closed');
                    } catch (err) {
                        console.error('Error closing AudioContext:', err);
                    }
                }
                
                setAudioContext(null);
                setIsRecording(false);
                setIsProcessing(false);
                setStopRecording(null);
            };

            setStopRecording(() => cleanup);

        } catch (error) {
            console.error('Error starting voice recognition:', error);
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
            }
            setIsRecording(false);
            setIsProcessing(false);
            setAudioContext(null);
            setAudioStream(null);
            setStopRecording(null);
            alert('Error accessing microphone. Please check your permissions and try again.');
        }
    };

    return (
        <Box sx={{ 
            backgroundColor: 'background.default', 
            minHeight: '100vh',
            display: 'flex',
            position: 'relative',
            width: '100%',
            pt: { xs: '0px', md: '24px' }, // Responsive top padding
        }}>
            <CssBaseline />
            <Container 
                maxWidth="lg" 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    py: { xs: 2, sm: 3 }, // Reduced top/bottom padding on mobile
                    gap: 2,
                    px: { xs: 2, sm: 3 },
                    width: '95%',
                    maxWidth: '1600px',
                }}
            >
                <IconButton 
                    onClick={() => {
                        if (courseId) {
                            navigate(`/course-details/${courseId}`, {
                                state: { 
                                    courseId,
                                    courseName,
                                    course: {
                                        id: courseId,
                                        course_name: courseName,
                                        course_description: location.state?.courseDescription,
                                        course_type: location.state?.courseType,
                                        image_path: location.state?.courseThumbnail,
                                        course_documents: location.state?.courseDocuments
                                    }
                                }
                            });
                        } else {
                            alert('No courseId available');
                            navigate('/');
                        }
                    }}
                    sx={{ 
                        alignSelf: { xs: 'center', md: 'flex-start' },
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        transition: 'all 0.2s ease',
                        borderRadius: '8px',
                        padding: '4px 12px',
                        mb: { xs: 0, sm: 2 }, // Reduced bottom margin on mobile
                        zIndex: 1,
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        }
                    }}
                >
                    <ArrowBackIcon />
                    <Typography 
                        component="span"
                        sx={{ 
                            fontWeight: 500,
                            color: 'text.primary'
                        }}
                    >
                        Back to Course Details
                    </Typography>
                </IconButton>

                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2, 
                    height: { xs: 'auto', md: 'calc(100vh - 140px)' }, // Adjust for top padding and margins
                    width: '100%'
                }}>
                    {/* Video Section */}
                    <Box sx={{ 
                        width: { xs: '100%', md: '65%' },
                        display: 'flex',
                        flexDirection: 'column',
                        height: { 
                            xs: 'auto',
                            md: !video?.supportingDocuments?.length ? '95%' : '100%' 
                        },
                    }}>
                        <Paper sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            p: { xs: 1, sm: 2 },
                            height: { 
                                xs: 'auto',
                                md: !video?.supportingDocuments?.length ? '100%' : '100%' 
                            },
                        }}>
                            <Box sx={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 2,
                                backgroundColor: 'background.paper',
                                borderRadius: 1,
                                py: 0.5,
                                px: 1,
                                width: '100%',
                            }}>
                                <IconButton 
                                    onClick={handlePreviousVideo}
                                    disabled={videos?.findIndex(v => v.id === video?.id) === 0}
                                    size="small"
                                    sx={{
                                        '&.Mui-disabled': {
                                            opacity: 0.3,
                                        },
                                        flexShrink: 0,
                                    }}
                                >
                                    <NavigateBeforeIcon fontSize="small" />
                                </IconButton>

                                <Box 
                                    sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        flex: 1,
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        mx: 1,
                                        overflow: 'hidden',
                                        '&:hover': {
                                            '& .video-title': {
                                                color: 'primary.main',
                                            }
                                        }
                                    }}
                                    onClick={(event) => setVideoMenuAnchor(event.currentTarget)}
                                >
                                    <Typography 
                                        variant="body2"
                                        sx={{ 
                                            color: 'primary.main',
                                            fontWeight: 500,
                                            flexShrink: 0,
                                        }}
                                    >
                                        {`${videos?.findIndex(v => v.id === video?.id) + 1} / ${videos?.length}`}
                                    </Typography>
                                    <Typography 
                                        variant="body2"
                                        className="video-title"
                                        sx={{ 
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            textAlign: 'center',
                                            transition: 'color 0.2s',
                                            flex: 1,
                                            minWidth: 0,
                                        }}
                                    >
                                        {video?.title}
                                    </Typography>
                                </Box>

                                <IconButton 
                                    onClick={handleNextVideo}
                                    disabled={videos?.findIndex(v => v.id === video?.id) === videos?.length - 1}
                                    size="small"
                                    sx={{
                                        '&.Mui-disabled': {
                                            opacity: 0.3,
                                        },
                                        flexShrink: 0,
                                    }}
                                >
                                    <NavigateNextIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            
                            <Menu
                                anchorEl={videoMenuAnchor}
                                open={Boolean(videoMenuAnchor)}
                                onClose={() => setVideoMenuAnchor(null)}
                                PaperProps={{
                                    sx: {
                                        mt: 1,
                                        backgroundColor: 'background.default',
                                        borderRadius: 2,
                                        width: { 
                                            xs: '80vw',
                                            sm: '50vw',
                                            md: '45vw'
                                        },
                                        maxWidth: '90vw',
                                        maxHeight: { 
                                            xs: '60vh',
                                            sm: '400px'
                                        },
                                        overflowY: 'auto',
                                        '&::-webkit-scrollbar': {
                                            display: 'none'
                                        },
                                        scrollbarWidth: 'none',
                                        msOverflowStyle: 'none',
                                        '& .MuiList-root': {
                                            p: 1
                                        },
                                        zIndex: theme => ({
                                            xs: theme.zIndex.drawer - 1,  // Below drawer on mobile
                                            sm: theme.zIndex.modal  // Default modal z-index on desktop
                                        })
                                    }
                                }}
                                style={{
                                    zIndex: theme.zIndex.drawer - 1  // Below drawer
                                }}
                                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                                marginThreshold={16}
                            >
                                {videos?.map((v, index) => (
                                    <MenuItem 
                                        key={v.id}
                                        selected={v.id === video?.id}
                                        onClick={() => {
                                            setVideoMenuAnchor(null);
                                            handleVideoSelect(v);
                                        }}
                                        sx={{
                                            borderRadius: 1,
                                            mb: 0.5,
                                            py: 0.75,
                                            px: 2,
                                            position: 'relative',
                                            '&:last-child': {
                                                mb: 0,
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: 'background.paper',
                                                '&:hover': {
                                                    backgroundColor: 'background.paper',
                                                }
                                            },
                                            '&:hover': {
                                                backgroundColor: 'action.hover',
                                            },
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            gap: 1,
                                            overflow: 'hidden',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                height: '100%',
                                                width: `${videoProgress[v.id] || 0}%`,
                                                backgroundColor: 'grey.500',
                                                opacity: 0.1,
                                                transition: 'width 0.3s ease'
                                            }
                                        }}
                                    >
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            flex: 1,
                                            minWidth: 0,
                                            position: 'relative',
                                            zIndex: 1
                                        }}>
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    color: 'primary.main',
                                                    fontWeight: 500,
                                                    minWidth: '24px',
                                                    flexShrink: 0,
                                                }}
                                            >
                                                {index + 1}.
                                            </Typography>
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    ml: 1,
                                                    fontWeight: v.id === video?.id ? 500 : 400,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    flex: 1,
                                                }}
                                            >
                                                {v.title}
                                            </Typography>
                                        </Box>
                                        <Typography 
                                            variant="caption"
                                            sx={{ 
                                                color: theme => videoProgress[v.id] > 0 ? 'primary.main' : 'text.secondary',
                                                opacity: 0.8,
                                                minWidth: '40px',
                                                textAlign: 'right',
                                                flexShrink: 0,
                                            }}
                                        >
                                            {`${videoProgress[v.id] || 0}%`}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>

                            <Box sx={{ 
                                position: 'relative',
                                width: '100%',
                                flex: { 
                                    xs: 'none',
                                    md: !video?.supportingDocuments?.length ? 1 : 'none'
                                },
                                // Maintain 16:9 aspect ratio on mobile
                                pt: { xs: '56.25%', md: 0 },
                                minHeight: { 
                                    xs: 'auto',
                                    md: !video?.supportingDocuments?.length ? 'calc(100% - 48px)' : '86.25%'
                                },
                                backgroundColor: 'background.paper',
                                borderRadius: 1,
                                overflow: 'hidden',
                            }}>
                                <ReactPlayer
                                    url={video?.videoType === 'youtube' 
                                        ? formatYouTubeUrl(video.url) 
                                        : video?.mp4_url 
                                            ? `${BASE_URL}${video.mp4_url}` 
                                            : ''}
                                    controls
                                    width="100%"
                                    height="100%"
                                    playing={Boolean(startTime)}
                                    style={{ 
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onReady={onReady}
                                    onDuration={onDuration}
                                    onProgress={onProgress}
                                    progressInterval={1000}
                                    config={{
                                        youtube: {
                                            playerVars: { 
                                                modestbranding: 1, 
                                                rel: 0,
                                                index: video?.url ? new URLSearchParams(new URL(video.url).search).get('index') || 1 : 1,
                                                listType: 'playlist',
                                                list: video?.url ? new URLSearchParams(new URL(video.url).search).get('list') : null,
                                                autoplay: 0
                                            }
                                        },
                                        file: {
                                            attributes: { 
                                                controlsList: 'nodownload',
                                                autoPlay: false
                                            },
                                            forceVideo: true,
                                            forceLoad: true,
                                        }
                                    }}
                                />
                            </Box>
                        </Paper>

                        {video?.supportingDocuments?.length > 0 && (
                            <Box sx={{ mt: 2 }}>
                                <SupportingDocuments documents={video?.supportingDocuments} />
                            </Box>
                        )}
                    </Box>

                    {/* Chat Section */}
                    <Paper sx={{ 
                        width: { xs: '100%', md: '35%' },
                        display: 'flex', 
                        flexDirection: 'column',
                        overflow: 'hidden',
                        p: { xs: 1, sm: 2 },
                        height: { 
                            xs: '500px', // Fixed height on mobile
                            md: !video?.supportingDocuments?.length 
                                ? '95%'
                                : video?.supportingDocuments?.length === 1
                                    ? 'calc(56.25vw * 0.65 + 110px)'
                                    : 'calc(56.25vw * 0.65 + 132px)'
                        },
                    }}> 
                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            mb: 2
                        }}>
                            <Button 
                                onClick={() => setShowChatHistory(!showChatHistory)}
                                variant="outlined"
                                sx={{ 
                                    flex: 1,
                                    borderColor: 'rgba(255, 255, 255, 0.23)',
                                    color: '#FFFFFF',
                                    '&:hover': {
                                        
                                        backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                    },
                                    textTransform: 'none',
                                    padding: '8px 16px',
                                    '&.Mui-disabled': {
                                        borderColor: 'rgba(255, 255, 255, 0.12)',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                    },
                                }}
                            >
                                {showChatHistory ? 'Hide Chat History' : 'Show Chat History'}
                            </Button>
                            <IconButton 
                                onClick={startNewChat}
                                sx={{ 
                                    borderRadius: '8px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                    color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                    },
                                    width: '40px',
                                    height: '40px',
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                            <IconButton
                                onClick={toggleTTS}
                                sx={{ 
                                    borderRadius: '8px',
                                    backgroundColor: isTTSEnabled ? 'primary.main' : 'rgba(255, 255, 255, 0.04)',
                                    color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: isTTSEnabled ? 'primary.dark' : 'rgba(255, 255, 255, 0.08)',
                                    },
                                    width: '40px',
                                    height: '40px',
                                    position: 'relative'
                                }}
                            >
                                {isTTSEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
                                {isPlaying && isTTSEnabled && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: 'success.main',
                                            bottom: '2px',
                                            right: '2px',
                                            animation: 'pulse 1.5s infinite',
                                            '@keyframes pulse': {
                                                '0%': { opacity: 0.6, transform: 'scale(1)' },
                                                '50%': { opacity: 1, transform: 'scale(1.2)' },
                                                '100%': { opacity: 0.6, transform: 'scale(1)' }
                                            }
                                        }}
                                    />
                                )}
                            </IconButton>
                            {isTTSEnabled && (
                                <IconButton
                                    onClick={handleVoiceMenuOpen}
                                    sx={{ 
                                        borderRadius: '8px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                        },
                                        width: '40px',
                                        height: '40px',
                                    }}
                                >
                                    <SettingsVoiceIcon />
                                </IconButton>
                            )}
                        </Box>

                        <Menu
                            anchorEl={voiceMenuAnchor}
                            open={Boolean(voiceMenuAnchor)}
                            onClose={handleVoiceMenuClose}
                            PaperProps={{
                                sx: {
                                    maxHeight: 300,
                                    width: '200px'
                                }
                            }}
                        >
                            {ttsVoices.map((voice) => (
                                <MenuItem 
                                    key={voice.id} 
                                    onClick={() => handleVoiceSelect(voice.id)}
                                    selected={ttsVoice === voice.id}
                                >
                                    {voice.name}
                                </MenuItem>
                            ))}
                        </Menu>

                        {showChatHistory && (
                            <Box sx={{ 
                                height: '200px',
                                mb: 2,
                                backgroundColor: 'background.paper',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}>
                                <ChatHistoryNavbar 
                                    chatHistory={chatHistory}
                                    onThreadSelect={handleThreadSelect}
                                    currentThreadId={lmsThreadId}
                                />
                            </Box>
                        )}

                        <Box sx={{ 
                            flexGrow: 1, 
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2, // Increased gap between messages
                            mb: 2,
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            p: 2, // Added padding to prevent messages from touching edges
                        }}>
                            {isLoadingMessages ? (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <CircularProgress />
                                </Box>
                            ) : messages.length === 0 && !showChatHistory ? (
                                <Box sx={{ 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    gap: 2,
                                    color: 'text.secondary',
                                    textAlign: 'center',
                                    p: 3
                                }}>
                                    <ChatIcon sx={{ fontSize: 40, opacity: 0.7 }} />
                                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                        Start a Conversation
                                    </Typography>
                                    <Typography variant="body2" sx={{ maxWidth: '280px' }}>
                                        Ask questions about the video content, request clarification, or discuss specific topics.
                                    </Typography>
                                </Box>
                            ) : (
                                messages.map((msg, index) => renderMessage(msg, index))
                            )}
                            <div ref={messagesEndRef} />
                        </Box>

                        <Box 
                            component="form" 
                            onSubmit={(e) => { e.preventDefault(); sendMessage(); }}
                            sx={{ 
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                p: 1,
                                borderRadius: 2,
                                backgroundColor: 'background.paper',
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="standard"
                                placeholder={isProcessing ? "Processing speech..." : "Type your message..."}
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                sx={{
                                    '& .MuiInput-root': {
                                        padding: '8px 12px',
                                        '&:before, &:after': {
                                            display: 'none',
                                        }
                                    },
                                    ...(isProcessing && {
                                        '& .MuiInput-input': {
                                            color: 'text.secondary',
                                            fontStyle: 'italic'
                                        }
                                    })
                                }}
                            />
                            <IconButton 
                                onClick={isRecording ? () => {
                                    if (stopRecording) {
                                        stopRecording();
                                        setStopRecording(null);
                                    }
                                    
                                    // Force additional cleanup to ensure recording stops
                                    if (audioStream) {
                                        audioStream.getTracks().forEach(track => {
                                            track.stop();
                                        });
                                        setAudioStream(null);
                                    }
                                    
                                    setIsRecording(false);
                                    setIsProcessing(false);
                                } : startVoiceRecognition}
                                disabled={isLoading && !isRecording}
                                sx={{
                                    backgroundColor: isRecording ? 'error.main' : 'primary.main',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: isRecording ? 'error.dark' : 'primary.dark'
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: 'action.disabledBackground',
                                        color: 'action.disabled',
                                    },
                                    width: 40,
                                    height: 40,
                                    animation: isProcessing ? 'pulse 1.5s infinite' : 'none',
                                    '@keyframes pulse': {
                                        '0%': {
                                            boxShadow: '0 0 0 0 rgba(255, 0, 0, 0.4)'
                                        },
                                        '70%': {
                                            boxShadow: '0 0 0 10px rgba(255, 0, 0, 0)'
                                        },
                                        '100%': {
                                            boxShadow: '0 0 0 0 rgba(255, 0, 0, 0)'
                                        }
                                    }
                                }}
                            >
                                {isRecording ? <StopIcon /> : <MicIcon />}
                            </IconButton>
                            <IconButton 
                                type="submit"
                                disabled={isLoading || !inputMessage.trim()}
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'primary.dark',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: 'action.disabledBackground',
                                        color: 'action.disabled',
                                    },
                                    width: 40,
                                    height: 40,
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </Box>
                    </Paper>
                </Box>
            </Container>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleSnackbarClose} 
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default VideoPlayer; 